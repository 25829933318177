<template>
    <Form class="p-3 mb-3 rounded mx-2" @submit="onSubmit">
        <div class="row">
            <div class="col-md-6">
                <Input
                    v-model="id_no"
                    name="id_no"
                    maska="############"
                    :label="$t('recipients.form.id_no')"
                    :placeholder="$t('recipients.form.id_no-placeholder')"
                    :validations="['required', 'minLength(12)', 'maxLength(12)']"
                    @change="onChangeIdNo"
                />
                <span class="text-danger" v-show="errorMsg">{{ errorMsg }}</span>
            </div>
            <div class="col-md-6" v-if="!isCompany">
                <Input
                    v-model="lastName"
                    name="lastName"
                    maska="A"
                    maska-tokens="A:[а-яА-ЯёЁa-zA-Z\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BB\- ]:multiple"
                    :label="$t('recipients.form.lastName')"
                    :placeholder="$t('recipients.form.lastName-placeholder')"
                    :validations="['required', 'minLength(3)', 'maxLength(50)']"
                />
            </div>
            <div class="col-md-6" v-if="!isCompany">
                <Input
                    v-model="firstName"
                    name="firstName"
                    maska="A"
                    maska-tokens="A:[а-яА-ЯёЁa-zA-Z\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BB\- ]:multiple"
                    :label="$t('recipients.form.firstName')"
                    :placeholder="$t('recipients.form.firstName-placeholder')"
                    :validations="['required', 'minLength(3)', 'maxLength(50)']"
                />
            </div>
            <div class="col-md-6" v-if="!isCompany">
                <Input
                    v-model="patronymic"
                    name="patronymic"
                    maska="A"
                    maska-tokens="A:[а-яА-ЯёЁa-zA-Z\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BB\- ]:multiple"
                    :label="$t('recipients.form.patronymic')"
                    :placeholder="$t('recipients.form.patronymic-placeholder')"
                    :validations="['maxLength(50)']"
                />
            </div>
            <div class="col-md-6" v-if="isCompany">
                <Input
                    v-model="companyName"
                    name="companyName"
                    :label="$t('recipients.form.companyName')"
                    :placeholder="$t('recipients.form.companyName-placeholder')"
                    :validations="['maxLength(255)']"
                />
            </div>
            <div class="col-md-6">
                <Input
                    v-model="email"
                    name="email"
                    maska="A"
                    maska-tokens="A:[a-zA-Z0-9@_.-]:multiple"
                    :label="$t('recipients.form.email')"
                    :placeholder="$t('recipients.form.email-placeholder')"
                    :validations="['email']"
                />
            </div>
            <div class="col-md-6">
                <Input
                    v-model="phone"
                    name="phone"
                    maska="+7 (###) ###-##-##"
                    :label="$t('recipients.form.phone')"
                    :placeholder="$t('recipients.form.phone-placeholder')"
                    :validations="isFound ? ['maxLength(20)'] : ['required', 'minLength(12)', 'maxLength(20)']"
                />
            </div>
            <Location ref="location" @changed="addressOnChange" />
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="policyAgreementCheck" v-model="policyAgreement">
            <label class="form-check-label" for="policyAgreementCheck">
                {{ $t('recipients.form.policy-agreement') }}
            </label>
        </div>
        <button type="submit" class="btn btn-primary mt-3" v-if="!id" :disabled="!policyAgreement">{{ $t('recipients.form.create-recipient') }}</button>
        <button type="submit" class="btn btn-primary" v-if="id && edit" :disabled="!policyAgreement">{{ $t('recipient.btn-update') }}</button>
        <button type="submit" class="btn btn-primary" v-if="!edit && isFound" :disabled="!policyAgreement">{{ $t('recipient.btn-choice') }}</button>
        <button type="button" class="btn btn-danger" v-if="!edit && showResetBtn" @click="reset" :disabled="!policyAgreement">{{ $t('recipient.btn-reset') }}</button>
        <button type="button" class="btn btn-danger" v-if="id && edit && showDeleteBtn" @click="showDeleteModal" :disabled="!policyAgreement">{{ $t('recipient.btn-delete') }}</button>
    </Form>
    <button type="button" class="d-none" data-bs-dismiss="modal" ref="recipientModalClose"></button>
</template>

<script>

    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'
    import Location from '../cabinet/Location.vue'

    export default {
        name: 'recipientForm',
        data: () => ({
            testInput: '',
            id: '',
            firstName: '',
            lastName: '',
            patronymic: '',
            id_no: '',
            bin: '',
            email: '',
            phone: '',
            address: '',
            isCompany: false,
            companyName: '',
            isFound: false,
            errorMsg: '',
            edit: false,
            showResetBtn: false,
            showDeleteBtn: false,
            policyAgreement: false
        }),
        props: {
            redirect: {
                type: String,
                default: ''
            },
            modalId: {
                type: String,
                default: ''
            }
        },
        components: {
            Form,
            Input,
            Location
        },
        watch: {
            id_no: {
                handler(val) {
                    if(val && val.length == 12) {
                        if(this.$libs.user.extractFromIdNum(val, 'type') === 'entity') this.isCompany = true
                        if(this.$libs.user.extractFromIdNum(val, 'type') === 'person') this.isCompany = false
                    }
                }
            }
        },
        methods: {
            async load(data, edit = false) {
                let {
                    id = '',
                    firstName = '',
                    lastName = '',
                    patronymic = '',
                    id_no = '',
                    company_id_num,
                    company_name = '',
                    email = '',
                    phone = '',
                    address = {},
                    address_obj = {},
                    canDelete = false
                } = data

                if(this.$libs.user.extractFromIdNum(id_no, 'type') === 'entity' || company_id_num) this.isCompany = true
                if(this.$libs.user.extractFromIdNum(id_no, 'type') === 'person') this.isCompany = false

                this.id = id
                this.firstName = firstName
                this.lastName = lastName
                this.patronymic = patronymic
                this.id_no = id_no
                if(this.isCompany) this.companyName = company_name
                this.email = (!this.isFound || edit) ? email : ''
                this.phone = (!this.isFound || edit) ? phone : ''
                this.address = (!this.isFound || edit) ? address : {}
                this.edit = edit
                this.showDeleteBtn = canDelete

                if(this.id) {
                    this.$refs.location.load(await this.getAddress())
                }

            },
            async getBy(field, value) {
                let url = process.env.VUE_APP_API_URL + '/recipients/by/' + field + '/' + value

                let q = await axios.get(url, {
                    params: { currentUserId: this.$store.state.user.id },
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }

                })

                return q
            },
            async getAddress() {
                let r = await this.$api.getRecipentAddress(this.id)

                return r.data ?? {}
            },
            async onChangeIdNo(val) {
                if(!val) return
                if(val.length == 12) {
                    if(this.$store.state.user.settings.iinBin == val || this.$store.state.user.settings.bin == val) { // check if own id
                        this.errorMsg = this.$t('recipients.form.own-id-num')
                    } else if(this.$libs.user.checkIdNum(val)) { // check id for validity
                        this.errorMsg = ''
                    } else {
                        this.errorMsg = this.$t('recipients.form.error')
                        this.errorMsg = 'Неправильный БИН'
                    }
                }
            },
            addressOnChange(address) {
                if(address.completed) {
                    this.address = address
                } else {
                    this.address = {}
                }
            },
            async onSubmit() {
                if(this.id && this.edit) { // updating recipient
                    this.update(this.id)
                } else if(this.isFound) { // adding recipient that is already in the system
                    let relation = await this.addRelation(this.$store.state.user.id, this.id)

                    if(relation) {
                        this.$refs.recipientModalClose.click()
                        this.$emit('recipientAdded', this.id)
                        this.clear()
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipients.form.notify.relation-add-error'),
                            type: 'error'
                        })
                    }
                } else { // adding new recipient
                    let relation = await this.addRelation(this.$store.state.user.id, this.id)

                    this.create()
                }
                
                await this.$refs.location.addStreet()

                this.$refs.recipientModalClose.click()
                this.reset()
            },
            create() {
                let recipient = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    patronymic: this.patronymic ?? '',
                    id_no: this.id_no,
                    email: this.email ?? '',
                    phone: this.phone,
                    address: this.address,
                    creator: this.$store.state.user.id
                }

                if(this.isCompany) {
                    recipient.id_no = ''
                    recipient.companyName = this.companyName
                    recipient.companyIdNum = this.id_no
                }

                let url = process.env.VUE_APP_API_URL + '/recipients/add'

                axios.post(url, recipient, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipients.form.notify.success'),
                            type: 'success'
                        })

                        this.$emit('recipientAdded', r.data)

                        this.clear()

                        if(this.redirect) {
                            this.$router.push(this.redirect)
                        }
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message_translated ?? this.$t('recipients.form.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            update() {
                let recipient = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    patronymic: this.patronymic ?? '',
                    id_num: this.id_no,
                    email: this.email ?? '',
                    phone: this.phone,
                    address: this.address,
                    creator: this.$store.state.user.id
                }

                if(this.isCompany) {
                    recipient.id_no = ''
                    recipient.companyName = this.companyName
                    recipient.companyIdNum = this.id_no
                }

                let url = process.env.VUE_APP_API_URL + '/recipients/update'

                axios.post(url, recipient, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipient.notify.success'),
                            type: 'success'
                        })

                        this.$emit('recipientAdded', r.recipientId)
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            remove() {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + this.id + '/delete'

                axios.get(url, {
                    params: { currentUserId: this.$store.state.user.id },
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$emit('recipientAdded', r.data)
                        this.$refs.recipientModalClose.click()
                        
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipient.notify.success-deleted'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error-deleted'),
                            type: 'error'
                        })
                    }
                })
            },
            async addRelation(userId, recipientId) {
                let data = {
                    userId: userId,
                    recipientId: recipientId
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/relations'

                let result = await axios.post(url, data, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            },
            async addNewEmail() {
                let r = await this.addNewContacts(this.id, 'email', this.email)

                if(r) {
                    this.email = ''
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('recipients.form.contact-add-email-success'),
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.contact-add-email-error'),
                        type: 'error'
                    })
                }
            },
            async addNewPhone() {
                let r = await this.addNewContacts(this.id, 'phone', this.phone)

                if(r) {
                    this.phone = ''
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('recipients.form.contact-add-phone-success'),
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.contact-add-phone-error'),
                        type: 'error'
                    })
                }
            },
            async addNewAddress() {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('notify.in-developing'),
                    type: 'error'
                })

                return

                await this.addNewContacts(this.id, 'address', this.address)
            },
            async addNewContacts(recipientId, key, value) {
                let data = {
                    userId: this.$store.state.user.id,
                    recipientId: recipientId,
                    key: key,
                    value: value
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/contact/add'

                let result = await axios.post(url, data, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            },
            showDeleteModal() {
                this.$refs.recipientModalClose.click()
                
                this.$emit('showConfirmModal', this.id)
            },
            close() {
                this.$refs.recipientModalClose.click()
            },
            clear() {
                this.id = ''
                this.firstName = ''
                this.lastName = ''
                this.patronymic = ''
                this.id_no = ''
                this.companyName = ''
                this.isCompany = false
                this.email = ''
                this.phone = ''
                this.address = ''
                this.$refs.location.reset()
            },
            clearWithoutIdNo() {
                this.id = ''
                this.firstName = ''
                this.lastName = ''
                this.patronymic = ''
                this.companyName = ''
                this.email = ''
                this.phone = ''
                this.address = ''
                this.$refs.location.reset()
            },
            reset() {
                this.clear()
                this.isFound = false
            }
        }
    }

</script>

<style scoped>

    .vr {
        background-color: #8f82d8;
    }

</style>
