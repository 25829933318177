<template>
    <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h3 class="mb-0">{{ $t('user-agreement.modal-header') }}</h3>
                </div>
                <div class="modal-body m-3">
                    <p>{{ $t('user-agreement.modal-body-1') }}</p>
                    <div class="checkboxes mb-3">
                        <div class="form-check pb-3 d-flex align-items-center">
                            <input type="checkbox" class="form-check-input me-2 flex-shrink-0" value="publicOffer" v-model="checked.publicOffer" id="publicOfferCheckbox">
                            <label for="publicOfferCheckbox" class="form-check-label">{{ $t('footer.agree-public-offer') }}</label>
                            <a href="/policies/ru/public-offer.pdf" target="_blank" class="nav-link"><i class="bi bi-file-earmark-pdf-fill ms-2 fs-4"></i></a>
                        </div>
                        <div class="form-check pb-3 d-flex align-items-center">
                            <input type="checkbox" class="form-check-input me-2 flex-shrink-0" value="policy" v-model="checked.policy" id="policyCheckbox">
                            <label for="policyCheckbox" class="form-check-label">{{ $t('footer.agree-policy') }}</label>
                            <a href="/policies/ru/privacy-policy.pdf" target="_blank" class="nav-link"><i class="bi bi-file-earmark-pdf-fill ms-2 fs-4"></i></a>
                        </div>
                        <div class="form-check pb-3 d-flex align-items-center">
                            <input type="checkbox" class="form-check-input me-2 flex-shrink-0" value="terms" v-model="checked.terms" id="termsCheckbox">
                            <label for="termsCheckbox" class="form-check-label">{{ $t('footer.agree-terms') }}</label>
                            <a href="/policies/ru/terms-of-use.pdf" target="_blank" class="nav-link"><i class="bi bi-file-earmark-pdf-fill ms-2 fs-4"></i></a>
                        </div>
                        <div class="form-check pb-3 d-flex align-items-center">
                            <input type="checkbox" class="form-check-input me-2 flex-shrink-0" value="dataCollection" v-model="checked.dataCollection" id="dataCollectionCheckbox">
                            <label for="dataCollectionCheckbox" class="form-check-label">{{ $t('footer.agree-data-collection') }}</label>
                            <a href="/policies/ru/data-collection.pdf" target="_blank" class="nav-link"><i class="bi bi-file-earmark-pdf-fill ms-2 fs-4"></i></a>
                        </div>
                        <div class="form-check pb-3 d-flex">
                            <input type="checkbox" class="form-check-input me-2 flex-shrink-0" value="liability" v-model="checked.liability" id="liabilityCheckbox">
                            <label for="liabilityCheckbox" class="form-check-label">{{ $t('footer.agree-liability') }}</label>
                        </div>
                    </div>
                    <p>{{ $t('user-agreement.modal-body-2') }}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mt-3" data-bs-dismiss="modal" @click="agreeToPolicy" :disabled="acceptDisabled">{{ $t('user-agreement.accept') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'policyAgreementModal',
        checked: {},
        acceptDisabled: true
    }),
    computed: {
        allChecked() {
            return Object.values(this.checked).every((value) => value)
        }
    },
    watch: {
        checked: {
            handler(value) {
                const trueCount = Object.values(value).filter(value => value === true).length
                if(trueCount == 5) this.acceptDisabled = false
                if(trueCount != 5) this.acceptDisabled = true
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async agreeToPolicy() {
            let url = process.env.VUE_APP_API_URL + '/users/agreement'

            await axios.post(url, {}, {
                withCredentials: true
            }).then((response) => {
                let r = response.data
                
                if(r.result === 'SUCCESS') {
                    this.$emit('agreed')
                    // this.$refs.policyModalClose.click()
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Ошибка соглашения',
                        type: 'error'
                    })
                }
            })
        },
        openLearnMore() {
            window.open('/about', '_blank', 'noreferrer')
        },
        listenToBackDrop() {
            let self = this
            document.querySelector('.modal').addEventListener('click', function(e) {
            // Check if the modal is clicked, not an element inside the modal:
                if (e.target === e.currentTarget) {
                    this.goToDashboard()
                }
            }.bind(this))
        }
    }
}

</script>

<style scoped>

.nav-link,
.nav-link.router-link-active {
    color: #9082d9;
    text-decoration: none;
    padding-left: 0;
}

.nav-link:hover,
.nav-link:focus,
.nav-link.router-link-active:hover {
    color: #7b6ce0;
}

</style>