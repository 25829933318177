<template>
    <div class="select-group mb-3" :class="wrapClass">
        <label class="form-label" v-if="label">{{ label }}</label>
        <Select2
            v-model="value"
            :name="name"
            :options="options"
            :settings="settings"
            @select="onChange($event)"
        />
        <Field
            v-model="value"
            class="d-none"
            type="text"
            :name="'select-'+name"
            :rules="validate"
        />
        <div
            class="tip"
            :class="`text-${validateClass ? validateClass : 'muted'}`"
        >
            <small class="tip-text">
                <ErrorMessage :name="'select-'+name" />
                {{ tip }}
            </small>
            <div class="tip-icon" v-if="tipType == 'danger'">
                <i class="bi bi-exclamation-triangle-fill"></i>
            </div>
        </div>
    </div>
</template>

<script>

    import { Field, ErrorMessage, handleChange } from 'vee-validate'
    import Select2 from 'vue3-select2-component'

    export default {
        data: () => ({
            validateClass: 'regular'
        }),
        components: {
            Select2,
            Field,
            ErrorMessage
        },
        props: {
            allowClear: {
                type: Boolean,
                default: false
            },
            wrapClass: {
                type: String,
                default: ''
            },
            modelValue: [String, Number],
            name: {
                type: String
            },
            id: {
                type: String,
                default: ''
            },
            label: {
                type: String
            },
            placeholder: {
                type: String,
                default: 'Выберите'
            },
            tip: {
                type: String,
                default: ''
            },
            optionId: {
                type: String,
                default: ''
            },
            optionText: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                required: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            search: {
                type: Boolean,
                default: true
            },
            validations: {
                type: Array,
                default: []
            },
            parent: {
                type: String,
                default: ''
            }
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                    this.$emit('selectChange', this.name)
                }
            },
            settings: {
                get() {
                    let s = {
                        allowClear: this.allowClear,
                        language: {
                            inputTooShort: () => 'Нужно ввести больше символов...',
                            noResults: () => 'Нет результатов',
                            maximumSelected: (args) => 'Максимальное кол-во элементов - ' + args.maximum,
                            searching: () => 'Поиск...'
                        },
                        minimumResultsForSearch: this.search ? 1 : 'Infinity',
                        multiple: this.multiple,
                        placeholder: this.placeholder,
                        // matcher: (params, data) => {
                            // if(process.env.VUE_APP_DEBUG) console.log('params', params)
                            // If there are no search terms, return all of the data
                            // if($.trim(params.term) === '') {
                            //     return data
                            // }

                            // Do not display the item if there is no 'text' property
                            // if(typeof data.text === 'undefined') {
                            //     return null
                            // }

                            // `params.term` should be the term that is used for searching
                            // `data.text` is the text that is displayed for the data object
                            // if(data.text.indexOf(params.term) > -1) {
                            //     var modifiedData = $.extend({}, data, true)
                            //     modifiedData.text += ' (matched)'

                            //     // You can return modified objects from here
                            //     // This includes matching the `children` how you want in nested data sets
                            //     return modifiedData;
                            // }

                            // Return `null` if the term should not be displayed
                            // return null;
                        // }
                    }

                    if(this.parent) s.dropdownParent = `${this.parent}`

                    return s
                }
            }
        },
        methods: {
            onChange(data) {
                this.$emit('change', data.id, data.text, { type: 'select', name: this.name, item: data })
            },
            validate(value) {
                if(process.env.VUE_APP_DEBUG) console.log('select validate', value)
                if(this.disabled) return true

                let types = {
                    required: () => {
                        if(value === '') return this.$t('form.validate.required-field')

                        return true
                    },
                    test: () => {
                        if(process.env.VUE_APP_DEBUG) console.log('test validate', value)

                        return true
                    }
                }

                if(this.validations.length > 0) {
                    this.validateClass = 'danger'
                    
                    let r = true

                    for(let i in this.validations) {
                        // if exists validation
                        if(!(this.validations[i] in types)) continue

                        let v = this.validations[i],
                            regexp = /\(([^)]+)\)/,
                            matches = regexp.exec(v),
                            arg = matches ? matches[1] : null
                        
                        if(arg) v = v.replace(matches[0], '')

                        r = types[v](arg)
                        if(r !== true) return r
                    }

                    this.validateClass = 'regular'

                    return r
                }

                return true
            }
        },
    }

</script>

<style scoped>

.select-group-sm  :deep(.select2-selection.select2-selection--single) {
    padding: .175em;
}

.select-group-sm  :deep(.select2-container--default .select2-selection--single .select2-selection__arrow) {
    height: 30px;
}

.tip {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: .125rem 0;
    font-size: .75rem;
}

</style>

<style>

.select2 {
    /* display: block; */
    width: 100% !important;
}

.select2-container--default .select2-selection {
    border: 1px solid #bdbdbd;
    border-radius: 0;
}

.select2-selection.select2-selection--single {
    height: auto;
    padding: .375em;
    border-radius: .5em;
}

.select2-results {
    border-radius: 0 0 .5em .5em;
}

.select2-container--default .select2-results>.select2-results__options {
    border-radius: 0 0 .5em .5em;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 6px;
    height: 40px;
}

.select2-container--default.select2-container--focus .select2-selection {
    border: 1px solid #a6a0fb;
    outline: 1px solid #a6a0fb;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #a6a0fb;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #c7c3e5;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: #ffffff;
    background-color: #a6a0fb;
}

.select2-selection__choice__remove {
    margin-right: 5px;
    color: #ffffff;
}

.select2-dropdown.select2-dropdown--below {
    border-radius: 0 0 .5em .5em;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 12px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #ffffff;
}

.select2-search__field::placeholder {
    color: #cfcfcf;
}

</style>