<template>
    <div class="editor" ref="editorContainer"></div>
</template>

<script>

import EditorJS from '@editorjs/editorjs'

export default {
    data: () => ({
        savedData: '',
        editor: '',
        isInit: false
    }),
    props: {
        editorConfig: {
            type: Object,
            default: {}
        }
    },
    mounted() {
        this.editor = new EditorJS({
            holder: this.$refs.editorContainer,
            ...this.editorConfig,

            onReady: () => {
                this.isInit = true
                this.$emit('ready')
            }
        })

        this.$refs.editorContainer.addEventListener('keydown', this.handleBackspace)
    },
    methods: {
        renderData(data) {
            let content = data.blocks.map(block => {
                switch (block.type) {
                    case 'header':
                        return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
                    case 'paragraph':
                        return `<p>${block.data.text}</p>`
                    default:
                        return ''
                }
            }).join('')

            return content
        },
        catchShortcodes(content) {
            let shortcodes = document.querySelectorAll('.shortcode')

            shortcodes.forEach(element => {
            let text = element.textContent,
                slug = element.getAttribute('data-slug')

            content = content.replace(`${text}`, `${slug}`)
            })

            return content
        },
        handleBackspace(event) {
            if (event.keyCode === 8) { // Check if backspace key is pressed
                const currentBlock = this.editor
                if(process.env.VUE_APP_DEBUG) console.log('currentBlock', currentBlock)
            }
        }
    }
}

</script>

<style scoped>
.editor {
    outline: solid 3px #c090d6;
    border-radius: 10px;
}
</style>