<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="about-section">
                <!-- about -->
                <h1 class="text-uppercase mb-3">{{ $t('about-page.title') }}</h1>
                <div class="row">
                    <div class="card p-4 rounded-5" v-html="$t('about-page.main-text')"></div>
                </div>
                <!-- /about -->

                <!-- indicators -->
                <h2 class="text-uppercase text-center mt-5 mb-5">{{ $t('about-page.indicators') }}</h2>
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center">
                        <div class="row row-cols-md-3 g-4">
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body text-center">
                                        <h3 class="card-title" style="color: #7b6ce0;"><count-up :start-val="0" :end-val="3480" :duration='3' :options="countUpOptions"></count-up></h3>
                                        <p class="card-text">{{ $t('about-page.happy-client') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body text-center">
                                        <h3 class="card-title" style="color: #7b6ce0;"><count-up :start-val="0" :end-val="817" :duration='3' :options="countUpOptions"></count-up></h3>
                                        <p class="card-text">{{ $t('about-page.successful-case') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100">
                                    <div class="card-body text-center">
                                        <h3 class="card-title" style="color: #7b6ce0;"><count-up :start-val="0" :end-val="749520316" :duration='3' :options="countUpOptions"></count-up></h3>
                                        <p class="card-text">{{ $t('about-page.got-money') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /indicators -->

                <!-- founders sectoin -->
                <h2 class="text-uppercase text-center mt-5 mb-5">{{ $t('about-page.founders-title') }}</h2>
                <!-- mr. dessyatnik -->
                <div class="row justify-content-center g-2 mb-5">
                    <div class="col-md-4 d-flex">
                        <div class="card">
                            <img src="../../assets/img/member-dessyatnik.jpg" :alt="$t('about-page.member-dessyatnik')" class="card-img-top" />
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <p class="card-text text-muted">{{ $t('about-page.member-dessyatnik-position') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column">
                        <div class="card flex-grow-1">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title">{{ $t('about-page.member-dessyatnik-name') }}</h4>
                                <p class="card-text mt-2"><span v-html="$t('about-page.member-dessyatnik-bio')"></span></p>
                                <div class="mt-auto">
                                    <p><a :href="'mailto:' + $t('about-page.member-dessyatnik-email')" class="links"><i class="bi bi-envelope"></i>  {{ $t('about-page.member-dessyatnik-email') }}</a></p>
                                    <p><a :href="'tel:' + $t('about-page.member-dessyatnik-phone')" class="links"><i class="bi bi-phone"></i>  {{ $t('about-page.member-dessyatnik-phone') }}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /mr. dessyatnik -->

                <!-- mr. grents -->
                <div class="row justify-content-center g-2">
                    <div class="col-md-4 d-flex">
                        <div class="card">
                            <img src="../../assets/img/member-grents.jpg" :alt="$t('about-page.member-grents')" class="card-img-top" />
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <p class="card-text text-muted">{{ $t('about-page.member-grents-position') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column">
                        <div class="card flex-grow-1">
                            <div class="card-body d-flex flex-column">
                                <h4 class="card-title">{{ $t('about-page.member-grents-name') }}</h4>
                                <p class="card-text mt-2"><span v-html="$t('about-page.member-grents-bio')"></span></p>
                                <div class="mt-auto">
                                    <p><a :href="'mailto:' + $t('about-page.member-grents-email')" class="links"><i class="bi bi-envelope"></i>  {{ $t('about-page.member-grents-email') }}</a></p>
                                    <p><a :href="'tel:' + $t('about-page.member-grents-phone')" class="links"><i class="bi bi-phone"></i>  {{ $t('about-page.member-grents-phone') }}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /mr. grents -->
                <!-- /founders section -->

                <!-- how we are working -->
                <h2 class="text-uppercase text-center mt-5 mb-5">{{ $t('footer-about.header-one') }}</h2>
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="card h-100 shadow">
                            <div class="card-body text-center">
                                <h3 class="card-title"><i class="bi bi-gear me-2"></i> {{ $t('footer-about.title-one') }}</h3>
                                <p class="card-text">{{ $t('footer-about.title-one-par') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card h-100 shadow">
                            <div class="card-body text-center">
                                <h3 class="card-title"><i class="bi bi-envelope me-2"></i> {{ $t('footer-about.title-two') }}</h3>
                                <p class="card-text">{{ $t('footer-about.title-two-par') }}</p>
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 mb-4">
                        <div class="card h-100 shadow">
                            <div class="card-body text-center">
                                <h3 class="card-title"><i class="bi bi-check2-circle me-2"></i> {{ $t('footer-about.title-three') }}</h3>
                                <p class="card-text">{{ $t('footer-about.title-three-par') }}</p>
                            </div>
                        </div>
                    </div>                         
                </div>
                <!-- how we are working -->
                <h2 class="text-uppercase text-center mt-5 mb-5">{{ $t('footer-about.partners') }}</h2>
                <div class="row row-cols-2 justify-content-center g-3">
                    <!-- partner logo -->
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/airba.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/freedompay.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/24saas.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/garnet.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/alpika.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/peri.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/burodolgov.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/expresstenge.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card partner-logo d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/partners/elite.png" alt="" />
                        </div>
                    </div>
                    <!-- /partner logo -->
                </div>
                <h2 class="text-uppercase text-center mt-5 mb-5">{{ $t('footer.agreements') }}</h2>
                <div class="d-flex justify-content-center">
                    <div class="row g-4 w-100">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title d-flex justify-content-between align-items-center">
                                        <a href="/policies/ru/public-offer.pdf" target="_blank" class="nav-link">{{ $t('footer.public-offer') }}</a><i class="bi bi-bank"></i>
                                    </h5>
                                    <p class="card-text">{{ $t('footer.public-offer-desc') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title d-flex justify-content-between align-items-center">
                                        <a href="/policies/ru/privacy-policy.pdf" target="_blank" class="nav-link">{{ $t('footer.policy') }}</a><i class="bi bi-bank"></i>
                                    </h5>
                                    <p class="card-text">{{ $t('footer.policy-desc') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title d-flex justify-content-between align-items-center">
                                        <a href="/policies/ru/terms-of-use.pdf" target="_blank" class="nav-link">{{ $t('footer.terms') }}</a><i class="bi bi-bank"></i>
                                    </h5>
                                    <p class="card-text">{{ $t('footer.terms-desc') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title d-flex justify-content-between align-items-center">
                                        <a href="/policies/ru/data-collection.pdf" target="_blank" class="nav-link">{{ $t('footer.data-collection') }}</a><i class="bi bi-bank"></i>
                                    </h5>
                                    <p class="card-text">{{ $t('footer.data-collection-desc') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body d-flex flex-column">
                                    <h5 class="card-title d-flex justify-content-between align-items-center">
                                        <a href="/policies/ru/payment-rules.pdf" target="_blank" class="nav-link">{{ $t('footer.payment-rules') }}</a><i class="bi bi-bank"></i>
                                    </h5>
                                    <p class="card-text">{{ $t('footer.payment-rules-desc') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'
import CountUp from 'vue-countup-v3'

export default {
    data: () => ({
        countUpOptions : {
            "enableScrollSpy": "true",
            "scrollSpyOnce": "true",
            "separator": " "
        }
    }),
    components: {
        Header,
        Footer,
        CountUp
    },
    methods: {
    
    },
    mounted() {
    
    }
}

</script>

<style scoped>

    h2 {
        color: #393c40;
        font-weight: bold;
    }

    section {
        padding: 2em 1em;
    }

    .about-section {
        padding-top: 140px;
        padding-bottom: 3em;
        min-height: 70vh;
    }

    .container-fluid {
        background-color: #f3f3f3;
    }

    .image-container {
        position: relative;
    }

    .image-container img {
        width: 100%;
        display: block;
    }

    .text-overlay {
        background-color: rgba(255, 110, 42, 0.2);
        position: absolute;
        top: 30%;
        left: 50%;
    }

    .text-overlay p {
        font-size: 30px;
        user-select: none;
        color: #393c40;
    }

    .bi {
        color: #7b6ce0;
    }

    .links {
        text-decoration: none;
        color: inherit;
    }

    .links:hover {
        color: #393c40;
    }

    .nav-link,
    .nav-link.router-link-active {
        color: #9082d9;
        text-decoration: none;
        padding-left: 0;
    }

    .nav-link:hover,
    .nav-link:focus,
    .nav-link.router-link-active:hover {
        color: #7b6ce0;
    }

    .partner-logo {
        transition: transform .2s ease-in;
        height: 100px;
    }

    .partner-logo img {
        max-width: 135px;
        max-height: 80px;
    }

    .partner-logo:hover {
        transform: scale(1.05);
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (max-width: 900px) {
        .text-overlay {
            top: 10%;
        }
        .text-overlay p {
            font-size: 24px;
        }
    }

    @media (max-width: 500px) {
        .text-overlay {
            top: 15%;
            left: 40%;
        }
        .text-overlay p {
            font-size: 12px;
        }
    }

</style>