<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="question-section">
                <AmBreadcrumbs
                    :showCurrentCrumb="true"
                />
                <h1>{{ question }}</h1>
                <div class="content mt-5" v-html="answer"></div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";


export default {
    name: 'Question',
    data: () => ({
        loading: false,
        slug: '',
        question: '',
        answer: ''
    }),
    components: {
        Header,
        Footer,
        Fancybox
    },
    methods: {
        async get() {
            if(this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/faq/' + this.slug

            await axios.get(url, {
                params: { 
                    language: this.$i18n.locale
                }
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.question = r.data.question
                    this.answer = r.data.answer

                    document.title = this.question + ''

                    this.createMeta(r.data.question)

                    this.setBreadcrumb()
                } else {
                    this.$router.push(`/${this.$i18n.locale}/faq`)
                }
            })
            this.loading = false
        },
        createMeta(content) {
            this.$emit('createMeta', content)
        },
        setBreadcrumb() {
            this.$breadcrumbs.value[1].label = this.question
        }
    },
    created() {
        this.slug = this.$route.params.slug
    },
    mounted() {
        this.get()
    }
}

</script>

<style scoped>
.container-fluid {
    background-color: #ffffff;
}
</style>

<style>
    section {
        padding: 2em 1em;
    }

    .question-section {
        padding-top: 140px;
        margin-bottom: 3em;
        min-height: 70vh;
    }

    .content h1,
    .content h2,
    .content h3,
    .content h4,
    .content h5,
    .content h6 {
        font-weight: 600;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>