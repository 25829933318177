<template>
    <Header :inner-page="true" />
    <div class="container" id="container">
        <div class="row justify-content-center my-5">
            <div class="col-md-12 text-center" v-show="loading">
                <div class="shadow p-3">
                    <div class="receipt">
                        <div class="loading"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-5" v-show="!loading">
                <div class="shadow p-3">
                    <div class="receipt" v-if="receipt">
                        <div class="my-3 text-center">
                            <img src="../assets/img/logo.png" width="60" alt="">
                        </div>
                        <h3 class="text-center">{{ $t('receipt.title') }}</h3>
                        <p class="text-center">{{ receipt.from.title }}</p>
                        <p class="text-center">{{ $t('receipt.from.idnum') }}: {{ receipt.from.idnum }}</p>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="w-50">{{ $t('receipt.date') }}</td>
                                    <td class="w-50">{{ receipt.date }}</td>
                                </tr>
                                <tr>
                                    <td class="w-50">{{ $t('receipt.slug') }}</td>
                                    <td class="w-50">{{ receipt.slug }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table" v-if="receipt.positions.length">
                            <thead>
                                <tr>
                                    <th>{{ $t('receipt.column-1') }}</th>
                                    <th>{{ $t('receipt.column-2') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in receipt.positions">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.price }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{{ $t('receipt.total') }}</th>
                                    <th>{{ receipt.total }}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="w-50">{{ $t('receipt.payment-method') }}</td>
                                    <td class="w-50">{{ receipt.paymentMethod }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5 class="text-center">{{ $t('receipt.client-data') }}</h5>
                        <table class="table">
                            <tbody>
                                <tr v-if="receipt.to.title">
                                    <td>{{ $t('receipt.client.title') }}</td>
                                    <td>{{ receipt.to.title }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('receipt.client.idnum') }}</td>
                                    <td>{{ receipt.to.idnum }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5 class="text-center">{{ $t('receipt.qr') }}</h5>
                        <div class="text-center" v-if="receipt.qr">
                            <img :src="receipt.qr" />
                        </div>
                        <p class="text-center text-muted">{{ $t('receipt.desc') }}</p>
                        <p class="text-center text-muted">{{ $t('receipt.link', { link: receipt.link }) }}</p>
                        <h5 class="text-center my-3">{{ $t('receipt.source') }}</h5>
                    </div>
                    <div class="receipt text-center" v-else>
                        <h5>{{ $t('receipt.not-found') }}</h5>
                        <p>{{ $t('receipt.not-found-desc') }}</p>
                        <router-link class="btn btn-primary my-3" to="/">{{ $t('not-found.goto-home') }}</router-link>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <button type="button" class="btn btn-primary w-50" @click="downloadPdf">{{ $t('receipt.download-pdf') }}</button>
                    <button type="button" class="btn btn-secondary w-50">{{ $t('receipt.download-cms') }}</button>
                </div>
            </div>
        </div>
    </div>
    
    <Footer />
</template>

<script>

import Header from './home/Header.vue'
import Footer from './home/Footer.vue'

    export default {
        data: () => ({
            slug: '',
            receipt: null,
            loading: false
        }),
        components: {
            Header,
            Footer
        },
        mounted() {
            this.slug = this.$route.params.slug
            this.getReceipt()
        },
        methods: {
            async getReceipt() {
                if(this.loading) return
                this.loading = true

                let r = await this.$api.getReceipt(this.slug)

                if(r.result === 'SUCCESS') {
                    this.receipt = r.data
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('receipt.notify.error'),
                        type: 'error'
                    })
                }

                this.loading = false
            },
            downloadPdf() {
                let url = process.env.VUE_APP_API_URL + '/receipts/' + this.slug + '/pdf'
                window.open(url, '_blank', 'noreferrer')
            }
        }
    }

</script>

<style scoped>
    p {
        margin-bottom: 0;
    }

    table {
        width: 100%;
    }

    td:nth-child(1),
    th:nth-child(1) {
        text-align: left;
    }

    td:nth-child(2),
    th:nth-child(2) {
        text-align: right;
    }

    #container {
        padding-top: 140px;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
            min-height: 300px;
        }
    }
</style>