<template>
    <div class="modal fade" ref="modalRef" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">{{ title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ClaimText :claim="claim" :template="claim.template" />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ClaimText from './ClaimText.vue'

export default {
    name: 'claimTextModal',
    data: () => ({
        id: 'claimTextModal',
        title: ''
    }),
    components: {
        ClaimText
    },
    props: {
        claim: {
            type: Object,
            default: {}
        }
    }
}

</script>

<style scoped>

.button {
    background: none;
    color: #3a3077;
    padding: .5rem;
    border: none;
    cursor: pointer;
}

.button:active {
    background: #3a3077;
    color: white;
}

.modal-content {
    /* min-width: 600px; */
    /* max-width: fit-content; */
    align-self: center;
    border-radius: 0;
    /* box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3); */
    /* position: absolute; */
    /* top: 5rem; */
    /* right: -50px; */
}

.modal-footer, .modal-header {
    border: none;
}

.modal-body {
    margin-right: 2rem;
}

</style>