<template>
    <Header :inner-page="true"/>
    <div class="container" id="container">
        <div class="row justify-content-center align-items-center my-5">
            <div class="col-md-3">
                <div class="shadow p-3 mb-5 rounded text-center">
                    <div class="text-center" v-if="loading">
                        <span class="loading"></span>
                        <p>{{ $t('loading') }}</p>
                    </div>
                    <div class="text-center" v-else-if="result">
                        <i class="bi bi-check-lg text-success"></i>
                        <h5>{{ $t('change-email.result.success') }}</h5>
                    </div>
                    <div class="text-center" v-else>
                        <i class="bi bi-x-lg text-danger"></i>
                        <h5>{{ $t('change-email.result.error') }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
    import Header from './home/Header.vue'
    import Footer from './home/Footer.vue'

    export default {
        name: 'Change-email',
        data: () => ({
            loading: false,
            result: false
        }),
        components: {
            Header,
            Footer
        },
        computed: {
            notifyMsg: {
                get() {
                    return {
                        'success': this.$t('change-email.notify.success'),
                        'user-not-found-or-incorrect-hash': this.$t('change-email.notify.user-not-found-or-incorrect-hash'),
                        'user-not-found': this.$t('change-email.notify.user-not-found'),
                        'incorrect-userId-or-hash': this.$t('change-email.notify.incorrect-userId-or-hash'),
                        other: this.$t('change-email.notify.error')
                    }
                }
            }
        },
        mounted() {
            let hash = this.$route.params.hash.split('@')
            let userId = hash[0] ?? '',
                secret = hash[1] ?? ''
            
            if(userId && secret) {
                this.changeEmail(userId, secret)
            } else {
                this.$router.push('/')
            }
        },
        methods: {
            async changeEmail(userId, secret) {
                if(this.loading) return
                
                this.loading = true

                let url = process.env.VUE_APP_API_URL + '/users/change-email'
                
                await axios.get(url, {
                    params: {
                        userId: userId,
                        hash: secret
                    },
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.result = true
                        this.$router.push('/login')
                    } else {
                        this.result = false

                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message in this.notifyMsg ? this.notifyMsg[r.message] : this.notifyMsg.other,
                            type: 'error'
                        })
                    }
                })

                this.loading = false
            }
        }
    }
</script>

<style scoped>

    #app > .container-fluid {
        height: 100vh;
        background-size: cover;
    }

    .logo {
        margin: 0 auto 1rem;
    }

    .logo img {
        max-width: 180px;
    }

    #container {
        padding-top: 140px;
    }

    @media (min-width: 1400px) {
        .logo {
            max-width: 1140px;
        }
    }

</style>