<template>
    <button @click="setFavourite()" class="btn btn-small btn-primary essence-button">
        <FavouriteStar :active="active"></FavouriteStar>
    </button>
</template>

<script>
    import FavouriteStar from './FavouriteStar.vue'
    export default {
        data: () => ({
            active: false
        }),
        props: {
            essence: {
                type: Number,
                default: false
            },
            user: {
                type: Number,
                default: false
            }
        },
        components:{
            FavouriteStar
        },
        watch: {
            essence: {
                async handler() {
                    await this.checkFavourite()
                }
            }
        },
        methods: {
            async setFavourite() {
                let url = process.env.VUE_APP_API_URL + '/essences/set-favourite'

                await axios.post(url, {
                    userId: this.$store.state.user.id,
                    essenceId: this.essence
                },{
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    this.active = !this.active
                    this.$emit('refreshFavourites')
                })
            },
            async checkFavourite() {
                let url = process.env.VUE_APP_API_URL + '/essences/check-favourite'

                await axios.get(url, {                    
                    params: {
                        userId: this.$store.state.user.id,
                        essenceId: this.essence
                    },
                    withCredentials: true,
                }).then((response) => {
                    let r = response.data
                    if(r.data[0].favourite == 1)
                    {
                        this.active = true
                    }
                    else{
                        this.active = false
                    }
                })
            }
        },
        mounted() {
            this.checkFavourite()
        }
    }
</script>

<style scoped>
.essence-button {
    padding: 5px 10px;
}
</style>

