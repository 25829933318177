<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="rate-plans-section">
                <h1 class="text-uppercase text-center mb-3">{{ $t('footer-rates.title') }}</h1>
                <div class="rate-plans-desc">
                    <p>{{ $t('footer-rates.desc') }}</p>
                </div>
                <div class="d-flex flex-wrap justify-content-center py-3">
                    <div class="rate-card" v-for="item in preparedItems">
                        <h2 class="rate-name"><i :class="item.icon"></i> {{ item.name }}</h2>
                        <div class="rate-desc" v-html="item.desc"></div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
    data: () => ({
        items: []
    }),
    computed: {
        standardTitle() {
            return this.$t('rates.standard.title')
        },
        standardDesc() {
            return this.$t('rates.standard.desc')
        },
        enterpriseTitle() {
            return this.$t('rates.enterprise.title')
        },
        enterpriseDesc() {
            return this.$t('rates.enterprise.desc')
        },
        preparedItems() {
            let arr = this.items

            for(let i in arr) {
                let name = arr[i].name.toLowerCase()

                if(name == 'standard') {
                    arr[i].name = this.standardTitle
                    arr[i].desc = this.standardDesc
                    arr[i].icon = 'bi bi-star'
                }
                
                if(name == 'enterprise') {
                    arr[i].name = this.enterpriseTitle
                    arr[i].desc = this.enterpriseDesc
                    arr[i].icon = 'bi bi-building'
                }
            }

            return arr
        }
    },
    components: {
        Header,
        Footer
    },
    methods: {
        getRatesWithoutAuth() {
            let url = process.env.VUE_APP_API_URL + '/rates/rate-plans'

            axios.get(url, {
                withCredentials: true,
            })
            .then((response) => {
                this.items = response.data.data;
            })
        }
    },
    mounted() {
        this.getRatesWithoutAuth()
    }
}

</script>

<style scoped>

    .container-fluid {
        background-color: #f3f3f3;
    }

    section {
        padding: 2em 1em;
    }

    .rate-plans-section {
        padding-top: 140px;
        padding-bottom: 3em;
        min-height: 70vh;
    }

    .rate-plans-desc {
        padding: 20px;
        text-align: center;
        margin: 0;
    }

    .rate-plans-desc p {
        font-size: 18px;
        margin: 0;
    }

    .rate-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin: 10px;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .rate-name {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 10px;
        background-color: #7c6ce0;
        color: #ffffff;
        border-radius: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .rate-price {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        margin: 0;
    }

    .rate-sub-price {
        color: #777;
        font-size: 18px;
        font-weight: 500;
    }

    .rate-desc {
        list-style: none;
        padding: 0;
        text-align: center;
    }

    .rate-desc li {
        margin-bottom: 8px;
        font-size: 16px;
    }

    .bi {
        font-size: 18px;
        color: #ffffff;
        margin-right: 10px;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>