<template>
    <h2 class="section-heading text-center mb-5">{{ $t('claims-check.title') }}</h2>
    <div class="row justify-content-center">
        <div class="col-md-4">
            <ul class="nav nav-tabs" id="claimsCheckTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="claims-check-idnum-tab" data-bs-toggle="tab" data-bs-target="#claims-check-idnum-tab-pane" type="button" role="tab" aria-controls="claims-check-idnum-tab-pane" aria-selected="true">{{ $t('claims-check.by-idnum') }}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="claims-check-idnum-tab" data-bs-toggle="tab" data-bs-target="#claims-check-address-tab-pane" type="button" role="tab" aria-controls="claims-check-address-tab-pane" aria-selected="false">{{ $t('claims-check.by-address') }}</button>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <div class="tab-content" id="claimsCheckTabContent">
                <div class="tab-pane fade show active" id="claims-check-idnum-tab-pane" role="tabpanel" aria-labelledby="claims-check-idnum-tab" tabindex="0">
                    <Input
                        v-model="idnum"
                        name="idnum"
                        maska="############"
                        :tipType="!!incorrectIdNum ? 'danger': ''"
                        :tip="!!incorrectIdNum ? incorrectIdNum : ''"
                        :placeholder="$t('claims-check.idnum')"
                        :validations="['required', 'minLength(12)', 'maxLength(12)']"
                        @change="onChangeIdnum"
                    />
                    <div class="my-3 text-center">
                        <button type="button" class="btn btn-primary mb-3" v-show="!loading" @click="checkByIdnum">{{ $t('claims-check.search') }}</button>
                        <button type="button" class="btn btn-primary mb-3 disabled" v-show="loading">
                            <span class="loading"></span>
                        </button>
                        <p v-show="resultIdnum === true" class="text-danger"><b>{{ $t('claims-check.found').toUpperCase() }}</b></p>
                        <p v-show="companyName"><b>{{ companyName }}</b></p>
                        <p v-show="resultIdnum === false" class="text-success"><b>{{ $t('claims-check.not-found').toUpperCase() }}</b></p>
                        <p v-show="resultIdnum === undefined" class="text-danger">{{ $t('claims-check.idnum-not-found') }}</p>
                    </div>
                </div>
                <div class="tab-pane fade" id="claims-check-address-tab-pane" role="tabpanel" aria-labelledby="claims-check-address-tab-tab" tabindex="0">
                    <Location :disabled-add-street="true" @changed="addressOnChange" />
                    <div class="my-3 text-center">
                        <button type="button" class="btn btn-primary mb-3" v-show="!loading" @click="checkByAddress">{{ $t('claims-check.search') }}</button>
                        <button type="button" class="btn btn-primary mb-3 disabled" v-show="loading">
                            <span class="loading"></span>
                        </button>

                        <p class="mb-3 fw-500 fs-5" v-show="resultAddress === true || noticeResultAddress === true">Претензий по ЖКХ: {{ numberOfClaims }}</p>
                        <p class="mb-3 fw-500 fs-5" v-show="resultAddress === true || noticeResultAddress === true">Уведомлений о задолженности: {{ numberOfNotices }}</p>
                        <table v-show="resultAddress === true || noticeResultAddress === true" class="table table-striped fixed-table">
                            <thead>
                                <tr>
                                    <th class="bold th">{{ columns[0].text }}</th>
                                    <th class="bold th">{{ columns[1].text }}</th>
                                    <th class="bold th">{{ columns[2].text }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in claimNoticesData">
                                    <td class="td">{{ item.type }}</td>
                                    <td class="td">{{ item.sender }}</td>
                                    <td class="td">{{ item.amount }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-show="resultAddress === false && noticeResultAddress === false" class="text-success"><b>{{ $t('claims-check.not-found').toUpperCase() }}</b></p>
                        <p v-show="resultAddress === false && noticeResultAddress === false" class="text-success"><b>{{ $t('notices-check.not-found').toUpperCase() }}</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '../form/Input.vue'
import Location from '../cabinet/Location.vue'
import { checkIdNum } from '../../../libs/user'
import DataList from '../cabinet/DataList.vue'

export default {
    data: () => ({
        idnum: '',
        address: null,
        resultIdnum: null,
        companyName: '',
        resultAddress: null,
        senders: [],
        noticeResultAddress: null,
        noticeSenders: [],
        loading: false,
        incorrectIdNum: false,
        claimNoticesData: [],
        numberOfClaims: 0,
        numberOfNotices: 0
    }),
    computed: {
        columns: {
            get() {
                return [
                    { value: 'type', text: this.$t('inbox.columns.type') },
                    { value: 'sender', text: this.$t('inbox.columns.recipient') },
                    { value: 'amount', text: this.$t('inbox.columns.amount_owed') }
                ]
            }
        }
    },
    components: {
        Input,
        Location,
        DataList
    },
    methods: {
        addressOnChange(data) {
            if(process.env.VUE_APP_DEBUG) console.log(this.address)
            this.address = data
        },
        async checkByIdnum() {
            this.clear()

            if(!this.idnum) return
            if(this.idnum.length < 12 || !checkIdNum(this.idnum)) {
                this.incorrectIdNum = this.$t('claims-check.idnum-not-found')
                return
            }

            this.loading = true
            let r = await this.$api.checkClaims({ idnum: this.idnum })
            this.loading = false

            this.resultIdnum = r.data.result
            this.companyName = r.data.companyName
        },
        async checkByAddress() {
            this.clear()

            if(!this.address || !(this.address.completed ?? false)) return

            this.loading = true
            let r = await this.$api.checkClaims({ address: this.address })
            let n = await this.$api.checkNoticesHome({ address: this.address })
            this.loading = false

            if(r && r.data) {
                this.resultAddress = r.data.result
                this.senders = r.data.senders
                this.numberOfClaims = r.data.data?.length || 0
            }
            if(n && n.data) {
                this.noticeResultAddress = n.data.result
                this.noticeSenders = n.data.senders
                this.numberOfNotices = n.data.data?.length || 0
            }

            let claims = r.data.data ? [...r.data.data] : []
            let notices = n.data.data ? [...n.data.data] : []

            this.claimNoticesData = [...claims, ...notices]
        },
        clear() {
            this.resultIdnum = null
            this.companyName = ''
            this.resultAddress = null
            this.senders = []
            this.noticeResultAddress = null
            this.noticeSenders = []
            this.incorrectIdNum = false
            this.claimNoticesData = []
            this.numberOfClaims = 0
            this.numberOfNotices = 0
        }
    }
}

</script>

<style scoped>
#claimsCheckTab .nav-item {
    flex: auto;
}

#claimsCheckTab .nav-item .nav-link {
    width: 100%;
    padding: 1em;
    color: #261D58;
    background-color: #E1E1EC;
    border: none;
}

#claimsCheckTab .nav-item:first-child .nav-link {
    border-radius: 1em 0 0 1em;
}

#claimsCheckTab .nav-item:last-child .nav-link {
    border-radius: 0 1em 1em 0;
}

#claimsCheckTab .nav-item .nav-link.active {
    width: 100%;
    color: #FFFFFF;
    background-color: #BFBCF4;
}
#claimsCheckTabContent {
    margin: 2em 0;
}
#claims-check-idnum-tab-pane {
    max-width: 300px;
    margin: auto;
}

.bold {
    font-size: 1.1em !important;
    font-weight: 600 !important;
}

.fixed-table {
    table-layout: fixed;
    width: 100%;
    padding: 0.8rem 0;
}

.loading::after {
    font-size: 1em;
}

.th, .td {
    width: 33.33%;
}
</style>