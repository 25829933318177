<template>
    <footer>
        <div class="container py-5">
            <div class="row d-flex justify-content-between">
                <div class="col-md-3 align-content-between">
                    <div class="logo">
                        <img src="../../assets/img/logo.png" :alt="$t('footer.logo_alt')" style="height: 67px; width: auto;">
                    </div>
                    <p>
                        <b class="text-uppercase">{{ $t('footer.kdp') }} –</b><br>{{ $t('footer.kdp-text') }}
                    </p>
                </div>
                <div class="col-md-3 mt-4 my-md-0">
                    <h5 class="text-uppercase fw-bold">{{ $t('footer.menu') }}</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <router-link :to="{ path: `/${this.$i18n.locale}/about` }" class="nav-link text-uppercase">{{ $t('footer.about') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ path: `/${this.$i18n.locale}/articles` }" class="nav-link text-uppercase">{{ $t('footer.info') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ path: `/${this.$i18n.locale}/instruction` }" class="nav-link text-uppercase">{{ $t('footer.instructions') }}</router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link v-if="this.$store.state.user" to="/cabinet" class="nav-link text-uppercase">{{ $t('footer.cabinet') }}</router-link>
                            <router-link v-else to="/login" class="nav-link text-uppercase">{{ $t('footer.login-registration') }}</router-link>
                        </li> -->
                        <li class="nav-item">
                            <router-link :to="{ path: `/${this.$i18n.locale}/rate-plans` }" class="nav-link text-uppercase">{{ $t('footer.plans') }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ path: `/${this.$i18n.locale}/contacts` }" class="nav-link text-uppercase">{{ $t('footer.contacts') }}</router-link>
                        </li>
                    </ul>
                    <!-- visa / mastercard requirement -->
                    <p class="mt-2">
                        <img src="../../assets/img/visa.png" alt="VISA" width="70" height="23" />&nbsp;
                        <img src="../../assets/img/mastercard.png" alt="MasterCard" width="70" height="42" />
                    </p>
                </div>
                <div class="col-md-3 align-content-between mt-4 my-md-0">
                    <div class="mb-3">
                        <h5 class="text-uppercase fw-bold">{{ $t('footer.documents') }}</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                <router-link to="/policies/ru/public-offer.pdf" target="_blank" class="nav-link docs">{{ $t('footer.public-offer') }}</router-link>
                            </li>
                            <li class="nav-item text-nowrap">
                                <router-link to="/policies/ru/privacy-policy.pdf" target="_blank" class="nav-link docs">{{ $t('footer.policy') }}</router-link>
                            </li>
                            <li class="nav-item text-nowrap">
                                <router-link to="/policies/ru/payment-rules.pdf" target="_blank" class="nav-link docs">{{ $t('footer.payment-rules') }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <h5 class="text-uppercase">{{ $t('footer.contacts') }}</h5>
                    <p>
                        {{ $t('footer.address') }}<br/>
                        {{ $t('footer.email') }}<br/>
                        {{ $t('footer.phone') }}
                    </p>
                </div>
                <div class="col-md-2 d-flex flex-md-column justify-content-md-center align-items-center gap-3 mt-4 my-md-0">
                    <a :href="$t('footer.contacts-icons.icon-1.link')" class="btn text-white fs-4 p-0">
                        <i :class="$t('footer.contacts-icons.icon-1.class')"></i>
                    </a>
                    <a :href="$t('footer.contacts-icons.icon-2.link')" class="btn text-white fs-4 p-0">
                        <i :class="$t('footer.contacts-icons.icon-2.class')"></i>
                    </a>
                    <a :href="$t('footer.contacts-icons.icon-3.link')" class="btn text-white fs-4 p-0">
                        <i :class="$t('footer.contacts-icons.icon-3.class')"></i>
                    </a>
                    <a :href="$t('footer.contacts-icons.icon-4.link')" class="btn text-white fs-4 p-0">
                        <i :class="$t('footer.contacts-icons.icon-4.class')"></i>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    data: () => ({})
}

</script>

<style scoped>

    footer {
        flex: auto;
        background-color: #000;
        color: #ffffff;
    }

    .logo {
        margin-bottom: 1.5rem;
    }

    .logo img {
        max-width: 180px;
    }

    .divider-left {
        padding-left: 1rem;
        border-left: 1px solid #ffffff;
        /* margin-top: 1rem; */
    }

    .divider-left.nav-link.router-link-active {
        padding-left: 1rem;
    }

    .nav-link,
    .nav-link.router-link-active {
        background-color: #000;
        color: #ffffff;
        text-decoration: none;
        padding-left: 0;
    }

    .nav-link:hover,
    .nav-link:focus,
    .nav-link.router-link-active:hover {
        color: #9082d9;
    }

    .docs {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>