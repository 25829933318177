<template>
    <div class="lang-switcher">
        <ul class="d-flex ps-0 ps-sm-auto">
            <li
                class="lang-item text-center"
                v-for="lang in langs"
                :class="{active:lang.active}"
                :key="`locale-${lang.key}`"
                @click="changeLang(lang.key)"
            >
                {{ lang.title }}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    data: () => ({
        current: '',
        langs: []
    }),
    computed: {
        _langs: {
            get() {
                return [
                    { key: 'ru', title: this.$t('lang-switcher.ru'), active: false },
                    { key: 'kk', title: this.$t('lang-switcher.kk'), active: false },
                    { key: 'en', title: this.$t('lang-switcher.en'), active: false }
                ]
            }
        },
        currentLang() {
            return this.$route.params.lang;
        }
    },
    methods: {
        getCurrent() {
            let l
            if(this.currentLang!=undefined) {
                l = this.currentLang
            }
            else if(this.$route.path == "/") {
                l = 'ru'
            }
            else{
                l = this.$libs.cookies.getCookie('lang') || this.$i18n.locale
            }
            this.$libs.cookies.setCookie('lang', l)
            this.$i18n.locale = l
            this.current = this.$i18n.locale
        },
        getLangs() {
            this.langs = this._langs

            // set current active
            for(let i in this.langs) {
                this.langs[i].active = this.current == this.langs[i].key
            }
        },
        changeLang(lang) {
            const currentPath = this.$route.path;
            let newPath = currentPath;

            this.$i18n.locale = lang
            this.current = this.$i18n.locale
            this.$libs.cookies.setCookie('lang', this.current)

            if (currentPath.match(/^\/(ru|en|kk)\//)) {
                newPath = currentPath.replace(/^\/(ru|en|kk)\//, `/${lang}/`);
            } 
            else if(this.$route.path == "/") {
                newPath = currentPath + `${lang}/`
            }

            if(newPath !== currentPath) {
                this.$router.push(newPath).catch((err) => console.log(err))
            }

            this.langs.forEach(langOption => {
                langOption.active = langOption.key === this.current
            })
        }
    },
    mounted() {
        this.getCurrent()
        this.getLangs()
    }
}

</script>

<style scoped>

ul {
    list-style-type: none;
    margin: auto;
}

.lang-switcher {
    color: #fff;
}

.lang-item {
    width: 50px;
    height: auto;
    padding: 0 .5rem;
    border-left: 1px solid;
    cursor: pointer;
    line-height: 14px;
}

.lang-item:first-child {
    border-left: none;
}

.lang-item.active {
    font-weight: bold;
}

</style>