<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">{{ $t('claim.choice-contacts.title') }}</h1>
                    <button type="button" class="btn-close" tabindex="-1" data-bs-dismiss="modal" aria-label="Close" ref="close" @click="handleClose"></button>
                </div>
                <div class="modal-body p-4">
                    <div class="row mb-3">
                        <div class="col-md-12" v-if="contacts.emails.length">
                            <h5>{{ $t('claim.choice-contacts.emails') }}</h5>
                            <div class="form-check" v-for="c in contacts.emails" :key="c.id">
                                <input
                                    class="form-check-input"
                                    name="claimContactEmails"
                                    v-model="emails"
                                    type="checkbox"
                                    :id="'claimContactEmails' + c.id"
                                    :value="c.id" @change="onChange"
                                    :disabled="isEmailCheckboxDisabled(c.id)"
                                >
                                <label class="form-check-label" :for="'claimContactEmails' + c.id">
                                    {{ c.value }}
                                </label>
                                <small class="tip" v-show="messages.emails">{{ messages.emails }}</small>
                            </div>
                        </div>
                        <div class="col-md-12" v-else>
                            <h5>{{ $t('claim.choice-contacts.no-emails') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12" v-if="contacts.phones.length">
                            <h5>{{ $t('claim.choice-contacts.phones') }}</h5>
                            <div class="form-check" v-for="c in contacts.phones" :key="c.id">
                                <input
                                    class="form-check-input"
                                    name="claimContactPhones"
                                    v-model="phones"
                                    type="checkbox"
                                    :id="'claimContactPhones' + c.id"
                                    :value="c.id"
                                    :disabled="isPhoneCheckboxDisabled(c.id)"
                                    @change="onChange">
                                <label class="form-check-label" :for="'claimContactPhones' + c.id">
                                    {{ c.value }}
                                </label>
                                <small class="tip" v-show="messages.phones">{{ messages.phones }}</small>
                            </div>
                        </div>
                        <div class="col-md-12" v-else>
                            <h5>{{ $t('claim.choice-contacts.no-phones') }}</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12" v-if="contacts.addresses.length">
                            <h5>{{ $t('claim.choice-contacts.addresses') }}</h5>
                            <div class="form-check" v-for="c in contacts.addresses" :key="c.id">
                                <input
                                    class="form-check-input"
                                    name="claimContactAddresses"
                                    v-model="addresses"
                                    type="checkbox"
                                    :id="'claimContactAddresses' + c.id"
                                    :value="c.id"
                                    :disabled="isAddressCheckboxDisabled(c.id)"
                                    @change="onChange"
                                >
                                <label class="form-check-label" :for="'claimContactAddresses' + c.id">
                                    {{ c.value }}
                                </label>
                                <small class="tip" v-show="messages.addresses">{{ messages.addresses }}</small>
                            </div>
                        </div>
                        <div class="col-md-12" v-else>
                            <h5>{{ $t('claim.choice-contacts.no-addresses') }}</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="handleDone" :disabled="emails.length<1&&phones.length<1&&addresses.length<1">{{ $t('claim.choice-contacts.submit') }}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="handleClose">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChoiceContactsModal',
    data: () => ({
        id: 'ChoiceContactsModal',
        contacts: {
            emails: [],
            phones: [],
            addresses: []
        },
        emails: [],
        phones: [],
        addresses: [],
        messages: {
            emails: '',
            phones: '',
            addresses: ''
        }
    }),
    props: {
        emailsLimit: {
            type: Number,
            default: 1
        },
        phonesLimit: {
            type: Number,
            default: 1
        },
        addressesLimit: {
            type: Number,
            default: 1
        }
    },
    methods: {
        onChange() {
            // this.checkLimits()
        },
        checkLimits() {
            if(this.emails.length > this.emailsLimit) {
                // this.emails.slice(0, this.emailsLimit - 1)
                this.messages.emails = 'Error emails count'
            } else {
                this.messages.emails = ''
            }
            if(this.phones.length > this.phonesLimit) {
                // this.emails.slice(0, this.phonesLimit - 1)
                this.messages.phones = 'Error phones count'
            } else {
                this.messages.phones = ''
            }
            if(this.addresses.length > this.addressesLimit) {
                // this.addresses.slice(0, this.addressesLimit - 1)
                this.messages.addresses = 'Error addresses count'
            } else {
                this.messages.addresses = ''
            }
        },
        isEmailCheckboxDisabled(val) {
            return !this.emails.includes(val) && this.emails.length >= this.emailsLimit
        },
        isPhoneCheckboxDisabled(val) {
            return !this.phones.includes(val) && this.phones.length >= this.phonesLimit
        },
        isAddressCheckboxDisabled(val) {
            return !this.addresses.includes(val) && this.addresses.length >= this.addressesLimit
        },
        getContacts() {
            return this.contacts
        },
        handleDone() {
            this.$emit('selected', {
                email: this.emails,
                phone: this.phones,
                address: this.addresses
            })
        },
        handleClose() {
            this.$emit('closed')
        },
        clear() {
            this.contacts = {
                emails: [],
                phones: [],
                addresses: []
            }
            this.emails = []
            this.phones = []
            this.addresses = []
            this.messages = {
                emails: '',
                phones: '',
                addresses: ''
            }
        },
        close() {
            this.$refs.close.click()
        }
    }
}

</script>