<template>
    <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h3 class="mb-0">Ошибка</h3>
                </div>
                <div class="modal-body">
                    <p>Произошла ошибка с получением информации о соглашении с публичной офертой.</p>
                    <p>Пожалуйста выйдите из системы, и войдите обратно.</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger mt-3" @click="logout">Выйти</button>
                </div>
            </div>
            <button type="button" class="d-none" data-bs-dismiss="modal" ref="agreementFailClose"></button>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'agreementFetchFailModal'
    }),
    methods: {
        logout() {
            this.$emit('logout')
            this.$refs.agreementFailClose.click()
        }
    }
}

</script>

<style scoped>

.modal-content {
    height: 400px;
}

</style>