<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="mb-0">{{ $t('change-email.confirmation-sent.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="confirmSentModalClose" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t('change-email.confirmation-sent.body') }} {{ newEmail }}.</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary mt-3 ms-3" @click="otherEmail">{{ $t('change-email.confirmation-sent.other-email') }}</button>
                    <button class="btn btn-outline-danger mt-3 ms-3" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'confirmSentModal'
    }),
    props: {
        newEmail: {
            type: String,
            default: ''
        }
    },
    methods: {
        otherEmail() {
            this.$refs.confirmSentModalClose.click()
            this.$emit('otherEmail')
        }
    }
}

</script>

<style scoped>

</style>