<template>
    <!-- PC version disabled -->
    <!-- <div class="rel disabled-on-mobile" :class="{ excluded: item.excluded }">
        <li class="nav-item">
            <router-link class="nav-link" :to="item.link" :class="{ disabled: item.disabled }"
                @click="sidebarCollapse()" @mouseover="showSubNav = true" @mouseleave="handleMouseLeave">
                <i :class="'bi bi-' + item.icon + ' special-icon'"></i> {{ item.text }}
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'inbox-unread'">
                    {{ inboxCountData }}
                    <span class="visually-hidden">unread claims</span>
                </span>
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'outbox'">
                    {{ outboxCountData }}
                    <span class="visually-hidden">outbox claims</span>
                </span>
                <i v-if="item.sub && item.sub.length" class="bi bi-chevron-right bi-sm chevron-right"></i>
            </router-link>
            <div @mouseover="showSubNavHover = true" @mouseleave="handleMouseLeaveSubNav" class="sub-nav-container rel" v-if="item.sub && item.sub.length && (showSubNav || showSubNavHover)">
                <ul class="nav sub" @click="sidebarCollapse()">
                    <sidebar-child v-for="(subItem, index) in item.sub"
                    :key="index" :item="subItem" :inboxCount="inboxCountData " :outboxCount="outboxCountData"></sidebar-child>
                </ul>
            </div>
        </li>
    </div> -->

    <!-- PC version -->
    <div class="rel disabled-on-mobile width-100" :class="{ excluded: item.excluded }">
        <li class="nav-item">
            <router-link v-if="!item.sub || !item.sub.length" :to="item.link" class="nav-link pd-left-2" :class="{ disabled: item.disabled }"
                @click="sidebarHide()">
                <i :class="'bi bi-' + item.icon + ' special-icon'"></i> {{ item.text }}
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'inbox-unread'">
                    {{ inboxCountData }}
                    <span class="visually-hidden">unread claims</span>
                </span>
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'outbox'">
                    {{ outboxCountData }}
                    <span class="visually-hidden">outbox claims</span>
                </span>
            </router-link>
            <p v-else class="nav-link pd-left-2 mb-0" :class="{ disabled: item.disabled }"
                @click="hideIfHasSubs($event)">
                <i :class="'bi bi-' + item.icon + ' special-icon'"></i> {{ item.text }}
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'inbox-unread'">
                    {{ inboxCountData }}
                    <span class="visually-hidden">unread claims</span>
                </span>
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'outbox'">
                    {{ outboxCountData }}
                    <span class="visually-hidden">outbox claims</span>
                </span>
            </p>
            <i v-if="item.sub && item.sub.length" class="bi bi-chevron-right bi-sm chevron-right" @click="toggleVisibility($event)"></i>
            <div class="sub-nav-container rel" v-if="item.sub && item.sub.length">
                <ul class="nav sub">
                    <sidebar-child v-for="(subItem, index) in item.sub" @sidebarHide="sidebarHide"
                    :key="index" :item="subItem" :inboxCount="inboxCountData " :outboxCount="outboxCountData"></sidebar-child>
                </ul>
            </div>
        </li>
    </div>

    <!-- Mobile version -->
    <div class="rel disabled-on-pc width-100" :class="{ excluded: item.excluded }">
        <li class="nav-item">
            <router-link v-if="!item.sub || !item.sub.length" :to="item.link" class="nav-link pd-left-2" :class="{ disabled: item.disabled }"
                @click="sidebarHide()">
                <i :class="'bi bi-' + item.icon + ' special-icon'"></i> {{ item.text }}
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'inbox-unread'">
                    {{ inboxCountData }}
                    <span class="visually-hidden">unread claims</span>
                </span>
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'outbox'">
                    {{ outboxCountData }}
                    <span class="visually-hidden">outbox claims</span>
                </span>
            </router-link>
            <p v-else class="nav-link pd-left-2 mb-0" :class="{ disabled: item.disabled }"
                @click="hideIfHasSubs($event)">
                <i :class="'bi bi-' + item.icon + ' special-icon'"></i> {{ item.text }}
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'inbox-unread'">
                    {{ inboxCountData }}
                    <span class="visually-hidden">unread claims</span>
                </span>
                <span class="badge ms-1 rounded-pill bg-danger" v-if="item.badge === 'outbox'">
                    {{ outboxCountData }}
                    <span class="visually-hidden">outbox claims</span>
                </span>
            </p>
            <i v-if="item.sub && item.sub.length" class="bi bi-chevron-right bi-sm chevron-right" @click="toggleVisibility($event)"></i>
            <div class="sub-nav-container rel" v-if="item.sub && item.sub.length">
                <ul class="nav sub">
                    <sidebar-child v-for="(subItem, index) in item.sub" @sidebarHide="sidebarHide"
                    :key="index" :item="subItem" :inboxCount="inboxCountData " :outboxCount="outboxCountData"></sidebar-child>
                </ul>
            </div>
        </li>
    </div>
</template>

<script>
import axios from 'axios';
import { rates } from '../../libs/rate'
export default {
    name: 'SidebarChild',
    props: {
        item: {
            type: Object,
            required: true
        },
        inboxCount: {
            type: Number,
            required: true
        },
        outboxCount: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        sidebarShow: false,
        outboxCountData: 0,
        inboxCountData: 0,
        showSubNav: false,
        showSubNavHover: false,
        currentPlan: {}
    }),
    watch: {
        outboxCount: {
            immediate: true,
            handler(val) {
                this.outboxCountData = val
            }
        },
        inboxCount: {
            immediate: true,
            handler(val) {
                this.inboxCountData  = val
            }
        }
    },
    methods: {
        getInboxCount() {
            let url = process.env.VUE_APP_API_URL + '/claims/inbox-count';
            axios.get(url, { withCredentials: true })
                .then(response => {
                    let r = response.data;
                    if (r.result === 'SUCCESS') {
                        if(r.data >= 0) this.inboxCount = r.data;
                        this.$emit('updatedInboxCount', this.inboxCount);
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Не удалось проверить количество входящих' + r.message,
                            type: 'error'
                        });
                    }
                });
        },
        getOutboxCount() {
            let url = process.env.VUE_APP_API_URL + '/claims/outbox-count';
            axios.get(url, { withCredentials: true })
                .then(response => {
                    let r = response.data;
                    if (r.result === 'SUCCESS') {
                        if(r.data >= 0) this.outboxCount = r.data
                        this.$emit('updatedOutboxCount', this.outboxCount);
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Не удалось проверить количество исходящих' + r.message,
                            type: 'error'
                        });
                    }
                });
        },
        sidebarCollapse() {
            //collapse sidebar sub elements
            this.handleMouseLeave()
            this.handleMouseLeaveSubNav()
        },
        sidebarHide() {
            //hide sidebar on mobile
            this.$emit('sidebarHide');
        },
        hideIfHasSubs(event) {
            this.toggleVisibility(event)

        },
        toggleVisibility(event) {
            //toggle visibility of sub elements as well as rotate the arrow when clicked
            let target
            if(event.target.tagName == 'P') {
                target = event.target.nextElementSibling
            }
            else if(event.target.classList.contains('special-icon')) {
                target = event.target.parentElement.nextElementSibling
            }
            else{
                target = event.target
            }
            const clickedIcon = target
            const parentSubNavContainer = clickedIcon.closest('.sub-nav-container')
            const subNavContainer = clickedIcon.nextElementSibling
            const allIconElements = document.querySelectorAll('.chevron-right')
            const allSubNavs = document.querySelectorAll('.sub-nav-container')

            //removing rotation of all other chevron icons, and toggling the one clicked
            if (clickedIcon.classList.contains('rotate-180')) {
                allIconElements.forEach(element => {
                    element.classList.remove('rotate-180')
                })
            } else {
                allIconElements.forEach(element => {
                    element.classList.remove('rotate-180')
                })
                clickedIcon.classList.add('rotate-180')
            }

            //removing visibility of all other sub-nav containers, and toggling the one clicked
            if (subNavContainer && subNavContainer.classList.contains('sub-nav-container') && subNavContainer.classList.contains('visible')) {
                allSubNavs.forEach(element => {
                    element.classList.remove('visible')
                });
            } else {
                allSubNavs.forEach(element => {
                    element.classList.remove('visible')
                });
                subNavContainer.classList.toggle('visible')
            }

            //making sure parent element is not affected, as well as it's sibling icon
            if(parentSubNavContainer) {
                parentSubNavContainer.classList.toggle('visible') 
                parentSubNavContainer.previousElementSibling.classList.add('rotate-180')
            }
        },
        handleMouseLeave() {
            // Delay hiding to allow for smooth transition
            setTimeout(() => {
                this.showSubNav = false
            }, 20);
        },
        handleMouseLeaveSubNav() {
            // Delay hiding to allow for smooth transition
            setTimeout(() => {
                this.showSubNavHover = false
            }, 20)
        },
        async getCurrentPlan() {
                let url = process.env.VUE_APP_API_URL + '/rates/get-current-plan'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.currentPlan = r.data
                        if(this.currentPlan.id !== rates.enterprise && this.item.link == '/claims/import') { // if not enterprise
                            this.item.excluded = true
                        }
                    } else {
                        this.item.exluded = true
                    }
                })
            }
    },
    mounted() {
        // this.getInboxCount()
        // this.getOutboxCount()
        this.getCurrentPlan()
    }
};
</script>

<style scoped>
    .rel {
        position: relative;
        width: -webkit-fill-available;
    }

    .excluded{ 
        display: none !important
    }

    p.mb-0{
        margin-bottom: 0px !important;
    }

    .width-100 {
        width: 100%;
    }
    
    /*PC styling disabled*/
    /*@media (min-width: 768px) {
        .sub-nav-container { 
            position: absolute;
            top: 0;
            left: 100%; 
            background: rgba(58, 48, 119, 1);
            padding-left: 0.5em;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-top: -0.5em;
            width: 250px;
        }

        
        .nav-item .nav-link i.chevron-right {
            font-size: smaller;
            right: 0;
            top: 50%;
        }

        .disabled-on-pc {
            display: none;
        }

        .disabled-on-mobile {
            display: block;
        }
    }*/

    /* PC styling */
    @media (min-width: 767px) {
        .sub-nav-container {
            display: none;
            transition: opacity 0.3s ease;
            background: rgba(58, 48, 119, 1);
            width: auto;
            z-index: 9999;
            margin-top: 3px;
            margin-bottom: 3px;
        }

        .sub-nav-container.visible {
            display: block; /* Show the element when the visible class is added */
            opacity: 1; /* Set opacity to fully visible */
        }
 
        i.chevron-right {
            font-size: small;
            right: 4%;
            margin-top: -19px;
            cursor: pointer;
        }


        .disabled-on-pc {
            display: none;
        }

        .disabled-on-mobile {
            display: block;
        }

        .rotate-180 {
            transform: translateY(-50%) rotate(90deg) !important;
        }

        .sub-nav-container .sub-nav-container {
            background: rgba(78, 68, 139, 1)
        }

        .pd-left-2 { 
            padding-left: 2em !important; 
        }

        .visible {
            display: block;
        }

        .nav-item{
            cursor: pointer;
        }
    }

    /* Mobile styling */
    @media (max-width: 767px) {
        .sub-nav-container {
            display: none;
            transition: opacity 0.3s ease;
            background: rgba(58, 48, 119, 1);
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-top: -0.5em;
            width: auto;
            z-index: 9999;
            margin-top: 5px;
        }

        .sub-nav-container.visible {
            display: block; /* Show the element when the visible class is added */
            opacity: 1; /* Set opacity to fully visible */
        }
 
        i.chevron-right {
            font-size: small;
            right: 4%;
            margin-top: -19px;
        }


        .disabled-on-pc {
            display: block;
        }

        .disabled-on-mobile {
            display: none;
        }

        .rotate-180 {
            transform: translateY(-50%) rotate(90deg) !important;
        }

        .sub-nav-container .sub-nav-container {
            background: rgba(78, 68, 139, 1)
        }

        .pd-left-2 { 
            padding-left: 2em !important; 
        }

        .visible {
            display: block;
        }
    }

    .chevron-right {
        position: absolute;
        transform: translateY(-50%);
        color: #d98292;
        transition: transform 0.3s ease;
    }

    #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 280px;
        height: 100vh;
        padding-left: 1em;
        color: #ffffff;
        background: rgb(20,13,62);
        background: -moz-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: -webkit-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140d3e",endColorstr="#3a3077",GradientType=1);
        /* box-shadow: .125rem 0 .75rem rgb(0 0 0 / 50%); */
        z-index: 3;
    }

    .logo {
        max-width: 140px;
        margin: 1rem .5rem 2.5rem;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .nav {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .nav-link.disabled {
        color: #8f82d8;
    }

    .nav-link,
    .btn-link {
        display: flex;
        align-items: center;
        padding: 0 .5rem;
        color: #c7c3e5;
        background: none;
        text-decoration: none;
        font-size: .85rem;
        font-weight: 400;
        text-transform: none;
        position: relative
    }

    .nav-link .bi,
    .btn-link .bi {
        color: #d98292;
        font-size: 1.5rem;
    }

    .nav-link:hover,
    .btn-link:hover {
        color: #ffffff;
        background: none;
    }

    .nav-link:focus,
    .btn-link:focus,
    #app .btn-link:active {
        color: #8f82d8;
        background: none;
    }

    #sidebar .nav-link .bi,
    #sidebar .btn-link .bi {
        margin-right: .75rem;
    }

    .nav-link.router-link-active {
        color: #ffffff;
    }

    .nav.sub .nav-link {
        font-weight: 400;
    }

    .badge {
        font-size: .65em;
    }

    .build-version {
        margin: .5rem;
        color: #8f82d8;
        font-size: .6rem;
    }

    #sidebar::-webkit-scrollbar {
        width: 5px;
    }
    
    #sidebar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    
    /* #sidebar::-webkit-scrollbar-thumb {
        background-color: #d98292;
        outline: 1px solid #d98292;
    } */

    #mobileNavbar {
        display: none;
        padding: .5em 1em;
        background: rgb(20,13,62);
        background: -moz-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: -webkit-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140d3e",endColorstr="#3a3077",GradientType=1);
        z-index: 4;
    }

    #mobileNavbar .nav-title {
        display: block;
    }

    #mobileNavbar .nav-link,
    #mobileNavbar .btn-link {
        display: block;
        color: #ffffff;
        text-align: center;
    }

    @media (max-width: 991px) {
        #sidebar {
            left: -100%;
            width: 100%;
        }

        #sidebar.show {
            left: 0;
        }

        #mobileNavbar {
            display: block;
        }
    }

</style>