<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="mb-0">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeBtn" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-html="text">
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary mt-3 ms-3" @click="confirm">{{ $t('btn.confirm') }}</button>
                    <button class="btn btn-secondary mt-3 ms-3" data-bs-dismiss="modal">{{ $t('btn.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        
    }),
    props: {
        id: {
            type: String,
            default: 'declineModal'
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        }
    },
    methods: {
        async confirm() {
            this.$emit('confirmed')
            this.close()
        },
        close() {
            this.$refs.closeBtn.click()
        }
    }
}
</script>