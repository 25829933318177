<template>
<p v-html="template"></p>
</template>

<script>
import { generate } from '../../../../libs/generator'
import { templateFields } from '../../../../libs/templateFields'

export default {
    data:() => ({
        fields: templateFields.zadatok,
        template: ''
    }),
    props: {
        claim: {
            type: Object,
            default: {}
        }
    },
    watch: {
        claim() {
            this.generate()
        }
    },
    mounted() {
        this.$emit('setLanguage', this.getLanguage())
        this.$emit('templateFields', this.getFields())
        this.generate()
    },
    methods: {
        getLanguage() {
            return this.claim.template_language
        },
        getFields() {
            return this.fields
        },
        updateTemplateFields(fields) {
            this.fields = fields
            this.generate()
        },
        generate() {
            this.template = generate(this.claim.template, this.claim, this.claim.sender, this.claim.recipient, this.claim.template_language)
        }
    }
}

</script>