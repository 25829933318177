<template>
    <Header :inner-page="true"/>
    <div class="container" id="container">
        <div class="row my-5">
            <div class="col-md-12" v-show="loading">
                <div class="claim-not-found">
                    <div class="loading"></div>
                </div>
            </div>
            <div class="col-md-12" v-show="!loading">
                <div class="claim-short" v-if="claim">
                    <h5 class="text-center">{{ $t('claim-short.has-claim.title') + number }}</h5>
                    <p>
                        <!-- {{ $t('claim-short.has-claim.number') }}: {{ number }}<br/> -->
                        {{ $t('claim-short.has-claim.from') }}: {{ senderTitle }}<br/>
                        {{ $t('claim-short.has-claim.to') }}: {{ recipientTitle }}<br/>
                    </p>
                    <p>{{ $t('claim-short.has-claim.desc') }}</p>
                    <button type="button" class="btn btn-primary my-3" @click="login">{{ isLogged ? $t('claim-short.has-claim.view-claim') : $t('claim-short.has-claim.login') }}</button>
                </div>
                <div class="claim-not-found" v-else>
                    <h5>{{ $t('claim-short.not-found-claim.title') }}</h5>
                    <p>{{ $t('claim-short.not-found-claim.desc') }}</p>
                    <router-link class="btn btn-primary my-3" to="/">{{ $t('not-found.goto-home') }}</router-link>
                </div>
            </div>
        </div>
    </div>
    
    <Footer />
</template>

<script>

import Header from '../home/Header.vue'
import Footer from '../home/Footer.vue'
import { shortenCompanyName } from '../../../libs/text'

    export default {
        data: () => ({
            number: 0,
            slug: '',
            numberLength: 8,
            claim: false,
            loading: false,
            isLogged: false
        }),
        computed: {
            statuses: {
                get() {
                    return {
                        'draft': this.$t('claim.statuses.draft'),
                        'unread': this.$t('claim.statuses.unread'),
                        'read': this.$t('claim.statuses.read'),
                        'disputed': this.$t('claim.statuses.disputed'),
                        'declined': this.$t('claim.statuses.declined'),
                        'accepted': this.$t('claim.statuses.accepted'),
                        'withdrawn': this.$t('claim.statuses.withdrawn'),
                        'expired': this.$t('claim.statuses.expired'),
                        'unknown': this.$t('claim.statuses.unknown'),
                        'badge_draft': this.$t('claim.statuses.badge_draft'),
                        'badge_unread': this.$t('claim.statuses.badge_unread'),
                        'badge_read': this.$t('claim.statuses.badge_read'),
                        'badge_disputed': this.$t('claim.statuses.badge_disputed'),
                        'badge_declined': this.$t('claim.statuses.badge_declined'),
                        'badge_accepted': this.$t('claim.statuses.badge_accepted'),
                        'badge_accepted': this.$t('claim.statuses.badge_withdrawn'),
                        'badge_expired': this.$t('claim.statuses.badge_expired'),
                        'badge_unknown': this.$t('claim.statuses.badge_unknown')
                    }
                }
            },
            senderTitle: {
                get() {
                    if(this.claim.sender.company_id_num && this.claim.sender.company_name) return shortenCompanyName(this.claim.sender.company_name)
                    return shortenCompanyName(this.claim.sender.title)
                }
            },
            recipientTitle: {
                get() {
                    if(this.claim.recipient.company_id_num && this.claim.recipient.company_name) return shortenCompanyName(this.claim.recipient.company_name)
                    return shortenCompanyName(this.claim.recipient.title)
                }
            }
        },
        components: {
            Header,
            Footer
        },
        mounted() {
            this.number = this.$route.params.number
            this.slug = this.$route.params.number
            this.isLogged = this.$store.state.user ? true : false
            this.getClaim()
        },
        methods: {
            getClaim() {
                this.loading = true

                let slug = this.slug
                let url = process.env.VUE_APP_API_URL + '/claims/' + slug + '/get-short'

                axios.get(url).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.claim = r.data

                        if(this.claim) {
                            // translate claim status
                            this.claim.statusText = this.claim.statusText in this.statuses ? this.statuses[this.claim.statusText] : this.statuses.unknown
                        }
                        
                        this.loading = false
                    } else {
                        this.loading = false

                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('claim.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            getIdByNumber(num) {
                return parseInt(num)
            },
            isClaimNumber(num) {
                return num.length === this.numberLength
            },
            login() {
                let link = '/claims/' + this.slug
                // let link = '/claims/' + this.getIdByNumber(this.number)

                this.$libs.cookies.setCookie('claimPreview', this.slug)

                if(!this.isLogged) {
                    this.$store.commit('setRedirects', {
                        afterLogin: link
                    })
                    this.$router.push('/login')
                } else {
                    this.$router.push(link)
                }
            }
        }
    }

</script>

<style scoped>

    .claim-short {
        max-width: 600px;
        margin: auto;
        text-align: center;
    }
    .claim-not-found {
        max-width: 600px;
        margin: auto;
        text-align: center;
    }

    #container {
        padding-top: 140px;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
            min-height: 300px;
        }
    }
</style>