<template>
    <div class="claim-pay-block my-3" v-if="text || htmlText">
        <span v-if="!htmlText">{{ text }}</span>
        <span v-else v-html="htmlText"></span>
        <div class="mt-2 text-center">
            <button
                type="button"
                class="btn btn-secondary w-100"
                v-if="showButton"
                :class="{disabled:loading}"
                v-html="_btnText"
                @click="handleClick"
            >
            </button>
            <small class="fst-italic text-muted text-center mt-2" v-if="taxInfo" v-html="$t('claim.pay-block.tax-info')"></small>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            loading: false
        }),
        props: {
            text: {
                type: String,
                default: ''
            },
            htmlText: {
                type: String,
                default: ''
            },
            taxInfo: {
                type: Boolean,
                default: true
            },
            showButton: {
                type: Boolean,
                default: false
            },
            btnText: {
                type: String,
                default: ''
            },
            btnLink: {
                type: String,
                default: ''
            }
        },
        computed: {
            _btnText() {
                if(this.loading) return '<span class="loading"></span>'
                return this.btnText || this.$t('pay-block.btn-text')
            }
        },
        methods: {
            handleClick() {
                if(this.loading) return

                this.$emit('clicked')
            },
            setLoading(status) {
                this.loading = status
            }
        }
    }
</script>

<style scoped>
    .claim-pay-block {
        padding: 1em;
        background-color: #f5f5f5;
        border-radius: 1em;
    }
</style>