<template>
    <div class="container-fluid">
        <Header />
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-5 col-xl-3 text-center">
                <LoginForm
                    backTo="/login/main"
                    :type="type"
                    :secondBtnLink="getEdocumentLoginLink()"
                    secondBtnText="Зарегистрироваться по смс"
                    @logged="logged"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import LoginForm from 't3m-auth/src/components/Login.vue'
    import Header from './home/Header.vue'

    export default {
        data: () => ({
            title: 'KDP',
            type: 'nca'
        }),
        components: {
            LoginForm,
            Header
        },
        methods: {
            getEdocumentLoginLink() {
                return '/login/edocument'
            },
            logged() {
                let auth = {
                    status: true,
                    version: process.env.VUE_APP_VERSION,
                }

                this.$store.commit('setAuth', auth)
                this.$router.push(this.$store.state.redirects.afterLogin)
                this.$store.commit('clearRedirects')
            }
        }
    }
</script>

<style scoped>

    #app > .container-fluid {
        height: 100vh;
        background-image: url('../assets/img/legal-papers-sign06.png');
        background-size: cover;
        /* background: linear-gradient(-45deg, #8f82d8, #6c56ea);
        animation: gradient 15s ease infinite; */
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }

    .logo {
        margin: 0 auto 1rem;
    }

    .logo img {
        max-width: 180px;
    }

    @media (min-width: 1400px) {
        .logo {
            max-width: 1140px;
        }
    }

</style>