<template>
    <div class="banner rounded-4">
        <div class="banner-img">
            <img
                src="../../assets/img/men.png"
                :alt="alt"
            />
        </div>
        <div class="banner-overlay">
            <h4 class="banner-text" v-if="text">{{ text }}</h4>
            <router-link class="btn banner-btn rounded-4" v-if="link && linkTitle" :to="link">{{ linkTitle }}</router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Banner',
    data: () => ({}),
    props: {
        alt: String,
        text: String,
        link: String,
        linkTitle: String
    }
}

</script>

<style scoped>

.banner {
    position: relative;
    height: 40vh;
    width: 30vw;
    color: #ffffff;
    background-color: #8f82d8;
    background-image: url('../../assets/img/back.png');
    background-repeat: repeat;
}

.banner img {
    max-width: 100%;
    height: auto;
}

.banner-img {
    position: absolute;
    right: 10px;
    bottom: 0;
    max-width: 40%;
}

.banner-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    width: 60%;
    padding: 1rem;
    text-align: right;
}

.banner-text {
    font-weight: 600;
}

.banner-btn {
    max-width: max-content;
    margin-top: .5rem;
    margin-left: auto;
    color: #8f82d8;
    background-color: #ffffff;
    font-weight: 500;
}

.banner-btn:hover {
    color: #ffffff;
    background-color: #8f82d8;
    border-color: #ffffff;
}

</style>