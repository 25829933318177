<template>
    <div class="container-fluid">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-3 text-center">
                <LoginForm backTo="/" :type="type" @logged="logged" />
            </div>
        </div>
    </div>
</template>

<script>
    import LoginForm from 't3m-auth/src/components/Login.vue'

    export default {
        data: () => ({
            title: 'KDP',
            type: 'nca'
        }),
        components: {
            LoginForm
        },
        methods: {
            logged(data = {}) {
                if(process.env.VUE_APP_DEBUG) console.log('logged', data)
                this.$router.push(this.$store.state.redirects.afterLogin)
                this.$store.commit('clearRedirects')
            }
        }
    }
</script>

<style scoped>

    #app > .container-fluid {
        height: 100vh;
        background-size: cover;
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }

    .logo {
        margin: 0 auto 1rem;
    }

    .logo img {
        max-width: 180px;
    }

    @media (min-width: 1400px) {
        .logo {
            max-width: 1140px;
        }
    }

</style>