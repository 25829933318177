<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row my-3">
            <div class="col-md-7 order-md-1 order-2">
                <ClaimText
                    :claim="tmpClaim"
                    :text="newTemplate"
                    :template="tmpClaim.template || 'default'"
                    :preview="true"
                    @templateFields="templateFields"
                    ref="claimText"
                />
            </div>
            <div class="col-md-5 order-md-2 order-1">
                <Form
                    ref="newClaimForm"
                    @updateCounters="updateCounters"
                    @updateBalance="updateBalance"
                    @setClaim="setClaim"
                    @changedTemplateFields="changedTemplateFields"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Form from './Form.vue'
    import ClaimText from './ClaimText.vue'

    export default {
        data: () => ({
            tmpClaim: {},
            newTemplate: ''
        }),
        components: {
            Form,
            ClaimText
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('sidebar.new-claim'))
            },
            updateCounters() {
                this.$emit('updateCounters')
            },
            updateBalance() {
                this.$emit('updateBalance')
            },
            setClaim(claim) {
                this.tmpClaim = claim || this.$store.state.claim
            },
            templateFields(fields) {
                this.$refs.newClaimForm.templateFields = fields
            },
            changedTemplateFields(fields) {
                this.$refs.claimText.updateTemplateFields(fields)
                // if(this.$refs.claimText.$refs.templateDefault.$refs?.template) {
                //     this.$refs.claimText.$refs.templateDefault.$refs?.template.fields = fields
                // }
            },
            async checkRelation() {
                let url = process.env.VUE_APP_API_URL + `/recipients/relations/${this.$store.state.user.id}/${this.$route.query.recipient}`

                let relation = false

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                
                    relation = r
                })
                
                if(relation.result === 'ERROR') this.$router.go(-1)
            }
        },
        mounted() {
            //setPageTitle
            if(this.$route.query.recipient) {
                let relation = this.checkRelation()

                if(relation.result === 'ERROR') {
                    this.$router.go(-1)
                }

                this.$refs.newClaimForm.setRecipient(this.$route.query.recipient)

                if(this.$route.query.essence && this.$route.query.essence === 'osi') {
                    this.$refs.newClaimForm.setEssence(4)
                    this.$refs.newClaimForm.blockEssences()
                }

                if(this.$route.query.amount_owed) this.$refs.newClaimForm.setAmountOwed(this.$route.query.amount_owed)
            }

            this.$emit('setPageTitle', this.$t('sidebar.new-claim'))

            this.$store.commit('getClaim')

            this.newTemplate = this.$store.state.claim.newTemplate ?? ''

            this.setClaim(this.$store.state.claim)
        }
    }

</script>
