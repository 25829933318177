const itemsPerPage = 25

function getPaginationObjByOptions(options = {}) {
    let {
        page = 1,
        limit = itemsPerPage,
        sortBy = '',
        sortType = ''
    } = options

    if(page <= 0) page = 1

    let offset = (page - 1) * limit

    return {
        page: page,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortType: sortType
    }
}

module.exports = {
    getPaginationObjByOptions,
    itemsPerPage
}