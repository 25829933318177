<template>
    <Form @submit="onSubmit" ref="form" id="locationForm">
        <div class="row">
            <div class="col-md-4">
                <Select2
                    v-model="country"
                    :name="'countrySelect'"
                    :label="$t('location.country')"
                    :placeholder="$t('location.country-placeholder')"
                    :options="countries"
                    :parent="parentElement"
                    :search="false"
                    :validations="['required']"
                    @change="onCountryChange"
                />
            </div>
            <div class="col-md-4">
                <Select2
                    v-model="region"
                    :name="'regionSelect'"
                    :label="$t('location.region')"
                    :options="regions"
                    :parent="parentElement"
                    :validations="['required']"
                    :disabled="!country"
                    @change="onRegionChange"
                />
            </div>
            <div class="col-md-4">
                <Select2
                    v-model="locality"
                    :name="'localitySelect'"
                    :label="$t('location.locality')"
                    :options="localities"
                    :parent="parentElement"
                    :validations="['required']"
                    :disabled="!region"
                    @change="onLocalityChange"
                />
            </div>
            <div class="col-md-4" v-for="(sub, index) in subLocalities">
                <Select2
                    :name="'subLocalitySelect-' + (index + 1)"
                    :label="$t('location.locality-placeholder')"
                    :options="sub"
                    :parent="parentElement"
                    :validations="['required']"
                    :disabled="!locality"
                    @change="onSubLocalityChange"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <Input
                    v-model="street"
                    v-show="displayStreet"
                    name="street"
                    :list="streets"
                    :label="$t('location.street')"
                    :placeholder="$t('location.street-placeholder')"
                    :validations="displayStreet ? ['required', 'minLength(2)', 'maxLength(200)'] : []"
                    :autocomplete="'off'"
                    @change="onStreetChange"
                    @datalistClicked="datalistClicked"
                />
            </div>
            <div class="col-md">
                <Input
                    v-model="buildingNumber"
                    v-show="displayBuildingNumber"
                    name="buildingNumber"
                    :label="$t('location.building-number')"
                    :placeholder="$t('location.building-number-placeholder')"
                    :validations="displayBuildingNumber ? ['required', 'min(0)'] : []"
                    @change="onBuildingNumberChange"
                />
            </div>
            <div class="col-md-4">
                <Input
                    v-model="apartment"
                    v-show="displayApartment"
                    name="apartment"
                    :label="$t('location.apartment')"
                    :placeholder="$t('location.apartment-placeholder')"
                    @change="onApartmentChange"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <Input
                    v-model="extraField"
                    v-show="displayExtraField"
                    name="extraField"
                    :label="'Доп. информация'"
                    :placeholder="'ряд / проулок / гараж / участок'"
                    @change="onExtraChange"
                />
            </div>
        </div>
    </Form>
</template>

<script>
    import { Form } from 'vee-validate'
    import Select2 from '../form/Select2.vue'
    import Input from '../form/Input.vue'

    export default {
        data: () => ({
            completed: false,
            countries: [],
            country: null,
            regions: [],
            region: null,
            localities: [],
            locality: null,
            localityId: null, // finally locality_id
            localityIdForStreets: null,
            subLocalities: [],
            streets: [],
            street: '',
            displayStreet: false,
            buildingNumber: '',
            displayBuildingNumber: false,
            floor: 0,
            apartment: '',
            displayApartment: false,
            extraField: '',
            displayExtraField: false
        }),
        props: {
            parentElement: {
                type: String,
                default: '#locationForm'
            },
            disableAddStreet: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Select2,
            Input
        },
        mounted() {
            this.getCountries()
        },
        methods: {
            async changed() {
                this.completed = this.isCompleted()

                this.$emit('changed', this.getAddress(), this.completed)
            },
            async onCountryChange() {
                this.region = null
                await this.getRegions(this.country)

                this.localities = []
                this.locality = null
                this.subLocalities = []
                this.streets = []

                this.hideDetails()

                this.changed()
            },
            async onRegionChange() {
                this.locality = null
                this.subLocalities = []
                this.streets = []
                await this.getLocalities(this.region)

                this.hideDetails()

                this.changed()
            },
            async onLocalityChange(_id, _text, { item }) {
                this.subLocalities = []
                this.streets = []
                this.localityId = this.locality
                this.localityIdForStreets = this.localityId
                await this.getSubLocalities(this.locality)

                this.hideDetails()

                if(item.displayDetails ?? false) {
                    this.showDetails()
                    await this.getStreets(this.localityIdForStreets)
                } else {
                    this.hideDetails()
                }

                this.changed()
            },
            async onSubLocalityChange(localityId, localityName, { name, item }) {
                this.subLocalities = this.subLocalities.slice(0, name.split('-').pop())

                this.localityId = localityId
                if(item.forAddress) this.localityIdForStreets = this.localityId
                
                if((item.displayDetails ?? false) || this.checkDetails()) {
                    this.showDetails()
                    await this.getStreets(this.localityIdForStreets)
                } else {
                    this.hideDetails()
                }

                this.changed()

                if(!item.child) return

                await this.getSubLocalities(localityId)
            },
            handleStreet(item) {
                this.street = item.text
            },
            onStreetChange() {
                this.changed()
            },
            onBuildingNumberChange() {
                this.changed()
            },
            onFloorChange() {
                this.changed()
            },
            onApartmentChange() {
                this.changed()
            },
            onExtraChange() {
                this.changed()
            },
            getAddress() {
                return {
                    completed: this.completed,
                    country: this.country,
                    region: this.region,
                    locality: this.localityId,
                    street: this.street,
                    buildingNumber: this.buildingNumber,
                    floor: this.floor,
                    apartment: this.apartment,
                    extra: this.extraField
                }
            },
            isCompleted() {
                if(!this.displayStreet) {
                    return this.country && this.region && this.locality
                }

                return this.country &&
                    this.region &&
                    this.locality &&
                    this.street &&
                    this.buildingNumber != ''
            },
            async getCountries() {
                let arr = []

                let r = await this.$api.getCountries()

                if(r.result === 'SUCCESS') {
                    for(let i in r.data) {
                        arr.push({ id: r.data[i].id, text: r.data[i].title })
                    }
                }

                this.countries = arr
            },
            async getRegions(countryId) {
                let arr = []

                let r = await this.$api.getRegions(countryId)

                if(r.result === 'SUCCESS') {
                    for(let i in r.data) {
                        arr.push({ id: r.data[i].id, text: r.data[i].title })
                    }
                }

                this.regions = arr
            },
            async getLocalities(regionId) {
                let arr = []

                let r = await this.$api.getLocalities(regionId)

                if(r.result === 'SUCCESS') {
                    for(let i in r.data) {
                        arr.push({
                            id: r.data[i].id,
                            text: r.data[i].title,
                            parent: r.data[i].parent,
                            child: r.data[i].child,
                            displayDetails: r.data[i].displayDetails
                        })
                    }
                }

                this.localities = arr
            },
            async getSubLocalities(localityId) {
                let arr = []
                let r = await this.$api.getSubLocalities(localityId)

                if(r.result === 'SUCCESS') {
                    for(let i in r.data) {
                        arr.push({
                            id: r.data[i].id,
                            text: r.data[i].title,
                            parent: r.data[i].parent,
                            child: r.data[i].child,
                            displayDetails: r.data[i].displayDetails,
                            forAddress: r.data[i].forAddress
                        })
                    }
                    if(arr.length) this.subLocalities.push(arr)
                }
            },
            async getStreets(localityId) {
                let arr = []
                let r = await this.$api.getStreets(localityId)

                if(process.env.VUE_APP_DEBUG) console.log('getStreets', r)

                if(r.result === 'SUCCESS') {
                    for(let i in r.data) {
                        arr.push({
                            id: r.data[i].id,
                            text: r.data[i].title
                        })
                    }
                    if(arr.length) this.streets = arr
                }
            },
            async addStreet() {
                if(this.disableAddStreet) return false
                let r = await this.$api.addStreet(this.localityIdForStreets, this.street)

                if(r.result === 'SUCCESS') return r.data
                return false
            },
            async load(data) {
                this.country = data.countryId ?? null
                this.region = data.regionId ?? null
                this.locality = data.localityId ?? null
                this.localityId = data.localityId ?? null
                this.street = data.street ?? ''
                this.buildingNumber = data.buildingNumber ?? ''
                this.floor = data.floor ?? 0
                this.apartment = data.apartment ?? ''
                this.extraField = data.extra ?? ''

                await this.getCountries()
                await this.getRegions(this.country)
                await this.getLocalities(this.region)
                await this.getSubLocalities(this.locality)
                await this.getStreets(this.localityId)

                this.changed()
            },
            datalistClicked(item) {
                this.street = item.text
            },
            showDetails() {
                this.displayStreet = true
                this.displayBuildingNumber = true
                this.displayApartment = true
                this.displayExtraField = true
            },
            hideDetails() {
                this.displayStreet = false
                this.displayBuildingNumber = false
                this.displayApartment = false
                this.displayExtraField = false

                this.clearDetails()
            },
            checkDetails() {
                return this.displayStreet &&
                this.displayBuildingNumber &&
                this.displayApartment &&
                this.displayExtraField
            },
            clearDetails() {
                this.street = ''
                this.buildingNumber = ''
                this.floor = 0
                this.apartment = ''
                this.extraField = ''
            },
            clear() {
                this.country = null
                this.region = null
                this.locality = null
                this.street = ''
                this.buildingNumber = ''
                this.floor = 0
                this.apartment = ''
                this.extraField = ''
            },
            reset() {
                // this.countries = []
                this.regions = []
                this.localities = []
                this.subLocalities = []
                this.streets = []
                this.clear()
            }
        }
    }

</script>

<style scoped>
</style>
