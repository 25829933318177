<template>
<p v-html="template"></p>
</template>

<script>
import { generate } from '../../../../libs/generator'
import { templateFields } from '../../../../libs/templateFields'

export default {
    data:() => ({
        fields: templateFields.osi,
        template: ''
    }),
    props: {
        claim: {
            type: Object,
            default: {}
        }
    },
    watch: {
        claim() {
            this.generate()
            this.setAddresses()
        },
        recipientAddresses: function() {
            this.setAddresses()
        }
    },
    computed: {
        recipientAddresses: {
            get() {
                if(!this.claim.recipient || !this.claim.recipient.contacts) return []

                return this.claim.recipient.contacts_send.addresses
            }
        }
    },
    mounted() {
        this.$emit('setLanguage', this.getLanguage())
        this.$emit('templateFields', this.getFields())
        this.generate()
    },
    methods: {
        getLanguage() {
            return this.claim.template_language
        },
        getFields() {
            return this.fields
        },
        updateTemplateFields(fields) {
            this.fields = fields
            this.generate()
        },
        setAddresses() {
            this.fields.object_address.options = this.recipientAddresses.map(obj => obj.value)
            this.fields.object_address.field_value = this.recipientAddresses[0] ? this.recipientAddresses[0].value : ''
        },
        generate() {
            this.template = generate(this.claim.template, this.claim, this.claim.sender, this.claim.recipient, this.claim.template_language)
        }
    }
}
</script>