<template>
    <div class="container-fluid">
        <Header />
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-6 text-center">
                <RegistrationForm backTo="/"/>
            </div>
        </div>
    </div>
</template>

<script>
    import RegistrationForm from 't3m-auth/src/components/Registration.vue'
    import Header from './home/Header.vue'

    export default {
        data: () => ({
            title: 'KDP'
        }),
        components: {
            RegistrationForm,
            Header
        }
    }
</script>

<style scoped>
    #app > .container-fluid {
        height: 100vh;
        background-image: url('../assets/img/legal-papers-sign06.png');
        /* background: linear-gradient(-45deg, #8f82d8, #6c56ea);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite; */
    }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }

    @media (min-width: 1400px) {
        .logo {
            max-width: 1140px;
        }
    }
</style>