<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-body">
                    <h5 class="mb-3">{{ $t('claim.withdraw-reason-title') }}</h5>
                    <div class="form-check" v-for="r in reasons">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" :id="'radioWithdraw' + r.id" :value="r.title" @change="onChange">
                        <label class="form-check-label" :for="'radioWithdraw' + r.id">
                            {{ withdrawReasons[r.title] ?? withdrawReasons.other }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="radioWithdrawOther" value="other" @change="onChange">
                        <label class="form-check-label" for="radioWithdrawOther">
                            {{ $t('claim.withdraw-reasons.other') }}
                        </label>
                    </div>
                    <div class="mt-3">
                        <textarea class="form-control" rows="2" ref="textArea" :disabled="!showTextArea" v-model="reasonText"></textarea>
                    </div>
                    <button class="btn btn-danger mt-3" data-bs-dismiss="modal" @click="withdraw()" :disabled="reason.length==0||(reason=='other'&&reasonText=='')">{{ $t('claim.btn-withdraw') }}</button>
                    <button class="btn btn-secondary ms-3" data-bs-dismiss="modal">{{ $t('claims.add.confirm-modal.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'confirmWithdrawModal',
        reasons: [],
        reason: '',
        reasonText: '',
        showTextArea: false
    }),
    computed: {
        withdrawReasons: {
            get() {
                return {
                    debt_paid: this.$t('claim.withdraw-reasons.debt_paid'),
                    agreement_reached: this.$t('claim.withdraw-reasons.agreement_reached'),
                    other: this.$t('claim.withdraw-reasons.other')
                }
            }
        }
    },
    mounted() {
        this.getWithdrawReasons()
    },
    methods: {
        withdraw() {
            if(this.reasonText) this.reason = this.reasonText

            this.$emit('withdrawClaim', this.reason)
        },
        getWithdrawReasons() {
            this.reasons = []

            let url = process.env.VUE_APP_API_URL + '/claims/withdraw-reasons'

            axios.get(url, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data

                this.reasons = r.data
            })
        },
        onChange(event) {
            if(process.env.VUE_APP_DEBUG) console.log('Changed', event.target.value)

            if(event.target.value == 'other') {
                this.showTextArea = true

                this.$nextTick(() => {
                    this.$refs.textArea.focus()
                })
            } else {
                this.reasonText = ''
                this.showTextArea = false
            }

            this.reason = event.target.value
        }
    }
}

</script>