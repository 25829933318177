<template>
    <div id="content" class="col" v-if="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-if="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row">
            <div class="col-md-7">
                <DebtText
                    ref="debtText"
                    :address="address"
                    :senderData="senderData"
                    :amount_owed="amount_owed"
                />
            </div>
            <div class="col-md-5">
                <DebtForm
                    :addressProp="address"
                    :occurrenceDate="occurrence_date"
                    :amountOwed="amount_owed"
                    :addressObject="address_object"
                    :noticeId="notice.id"
                    @updateAddress="updateAddress($event)"
                    @updateSender="updateSender($event)"
                    @updateAmount="updateAmount($event)"
                    @createNotice="createNotice($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DebtForm from './Form.vue'
import DebtText from './DebtText.vue'
import { statuses } from '../../../libs/fullstack'

export default {
    data: () => ({
        notice: {},
        amount_owed: '',
        occurrence_date: '',
        address_object: {},
        address: '',
        shortAddress: '',
        sender: '',
        senderData: '',
        send: true,
        isOsi: false,
        mounting: true
    }),
    components: {
        DebtForm,
        DebtText
    },
    mounted() {
        this.checkOsi()
        this.$emit('setPageTitle', this.$t('debt-notice.edit.title'))

        this.getSender()
        this.getNotice()
    },
    methods: {
        langChange() {
            this.$emit('setPageTitle', this.$t('debt-notice.edit.title'))
        },
        async checkOsi() {
            let isOsi = await this.$api.checkOsiStatus()
            if(!isOsi) {
                this.$router.replace('/dashboard')
            } else{
                this.mounting = false
            }
        },
        async getNotice() {
            let url = process.env.VUE_APP_API_URL + `/debt-notice/${this.$route.params.slug}`

            await axios.get(url, {
                withCredentials: true
            }).then(async response => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.notice = r.data

                    if(r.data.status !== statuses.draft) return this.$router.push(`/debt-notice/${r.data.slug}`)
    
                    this.amount_owed = this.notice.amount_owed
                    this.occurrence_date = this.notice.occurrence_date
                    let addrObj = {
                        country: 1,
                        address_id: this.notice.address_id,
                        recipient_id: this.notice.recipient_id,
                        locality_id: this.notice.locality_id,
                        street: this.notice.street,
                        buildingNumber: this.notice.building_number,
                        apartment: this.notice.apartment,
                        extra: this.notice.extra ?? ''
                    }
                    this.address_object = addrObj
    
                    await this.getAddressString(addrObj)
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })

                    this.$router.push('/debt-notice')
                }
            })
        },
        async getAddressString(obj) {
            let url = process.env.VUE_APP_API_URL + '/location/address-str'

            await axios.get(url, {
                params: {
                    address: obj
                },
                withCredentials: true
            }).then(response => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.address = r.data.full
                    this.shortAddress = r.data.short
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }
            })
        },
        async getSender() {
            let url = process.env.VUE_APP_API_URL + '/recipients/sender-id'

            await axios.get(url, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data
                
                this.sender = r.data.id
                this.senderData = r.data
            })

            this.$refs.debtText.generateText()
        },
        async createNotice(data) {
            let url = process.env.VUE_APP_API_URL + '/debt-notice/new'

            await axios.post(url, data, {
                withCredentials: true
            }).then(response => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: 'Уведомление сохранено',
                        type: 'success' 
                    })
                    if(data.sendNow) this.$router.push(`/debt-notice/${data.slug}`)
                    if(!data.sendNow) this.$router.push(`/debt-notice/${data.slug}/edit`)
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }
            })
        },
        updateAddress(value) {
            this.address = value
        },
        updateSender(value) {
            this.senderData = value
        },
        updateAmount(value) {
            this.amount_owed = value
        }
    }
}
</script>