<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-body p-3">
                    <h5 class="mb-3">{{ $t('sign-modal.title') }}</h5>
                    <ul class="nav nav-tabs" id="signTypes" role="tablist" v-if="showEdocument">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="sign-nca-tab" data-bs-toggle="tab" data-bs-target="#sign-nca-tab-pane" type="button" role="tab" aria-controls="sign-nca-tab-pane" aria-selected="true">{{ $t('sign-modal.nca-tab') }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="sign-edocument-tab" data-bs-toggle="tab" data-bs-target="#sign-edocument-tab-pane" type="button" role="tab" aria-controls="sign-edocument-tab-pane" aria-selected="false">{{ $t('sign-modal.edocument-tab') }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="signModalTabContent">
                        <div class="tab-pane fade show active" id="sign-nca-tab-pane" role="tabpanel" aria-labelledby="sign-nca-tab" tabindex="0">
                            <Form @submit="onNcaSubmit">
                                <div class="input-group">
                                    <label class="form-label">{{ $t('sign-modal.file-label') }}</label>
                                    <input type="file" ref="file" class="form-control">
                                </div>
                                <Input
                                    v-model="password"
                                    name="password"
                                    type="password"
                                    :label="$t('sign-modal.password-label')"
                                    :placeholder="$t('sign-modal.password-placeholder')"
                                    :validations="['required']"
                                />
                                <div class="text-end mt-4">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        :class="{'btn-loading':loading}"
                                        :disabled="loading"
                                    >
                                        {{ $t('sign-modal.sign') }}
                                        <span class="loading" v-if="loading"></span>
                                    </button>
                                    <button type="button" class="btn btn-danger ms-3" data-bs-dismiss="modal" :disabled="loading">{{ $t('sign-modal.cancel') }}</button>
                                </div>
                            </Form>
                        </div>
                        <div class="tab-pane fade" id="sign-edocument-tab-pane" role="tabpanel" aria-labelledby="sign-edocument-tab" tabindex="0" v-if="showEdocument">
                            <Form @submit="onApproveSubmit">
                                <div class="my-3 text-center" v-if="isSendCode">
                                    <p v-show="beforeSendCodeDesc" v-html="beforeSendCodeDesc"></p>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        :class="{'btn-loading':loading}"
                                        :disabled="loading"
                                        @click="sendCode"
                                    >
                                        Отправить СМС с кодом
                                    </button>
                                </div>
                                <div class="my-3 text-center" v-if="isApproveCode">
                                    <p v-show="sendCodeDesc" v-html="sendCodeDesc"></p>
                                    <Input
                                        v-model="code"
                                        name="code"
                                        maska="######"
                                        :label="'Код подтверждения'"
                                        :placeholder="'123456'"
                                        :validations="['minLength(6)', 'maxLength(6)']"
                                        :disabled="!!signKey"
                                    />
                                </div>
                                <div class="text-end mt-4">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        v-if="isApproveCode" :disabled="loading || code == ''"
                                        :class="{'btn-loading':loading}"
                                        @click="approveCode"
                                    >
                                        Подписать
                                    </button>
                                    <!-- <button
                                        type="submit"
                                        class="btn btn-primary"
                                        v-show="signKey"
                                        :class="{'btn-loading':loading}"
                                        :disabled="loading || signKey == ''"
                                    >
                                        {{ $t('sign-modal.sign') }}
                                    </button> -->
                                    <button
                                        type="button"
                                        class="btn btn-danger ms-3"
                                        data-bs-dismiss="modal"
                                    >
                                        {{ $t('sign-modal.cancel') }}
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="d-none" data-bs-dismiss="modal" ref="closeSignModal"></button>
    </div>
</template>

<script>

import { Form } from 'vee-validate'
import Input from '../form/Input.vue'

export default {
    name: 'SignModal',
    data: () => ({
        id: 'signModal',
        password: '',
        fileExtensions: ['p12'],
        isSendCode: true,
        sessionId: '',
        isApproveCode: false,
        code: '',
        signKey: '',
        loading: false
    }),
    props: {
        claimId: {
            type: Number,
            required: true
        },
        showEdocument: {
            type: Boolean,
            default: false
        },
        userIdnum: {
            type: String,
            default: ''
        },
        userPhone: {
            type: String,
            default: ''
        },
        beforeSendCodeDesc: {
            type: String,
            default: ''
        },
        sendCodeDesc: {
            type: String,
            default: ''
        }
    },
    components: {
        Form,
        Input
    },
    methods: {
        async onNcaSubmit() {
            if(!(this.$refs.file.files[0] ?? false)) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-file'),
                    type: 'error'
                })

                return
            }

            if(!this.checkFileExt(this.$refs.file.files[0])) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.wrong-ext-sign-file'),
                    type: 'error'
                })

                return
            }

            if(!this.password) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-password'),
                    type: 'error'
                })

                return
            }

            await this.ncaSubmit()
        },
        async sendCode() {
            if(this.loading) return
            this.loading = true

            let r = await this.$api.otpSendCode(this.userPhone)

            this.loading = false

            if(r.result == 'SUCCESS' && r?.session_id) {
                this.sessionId = r.session_id
                this.isSendCode = false
                this.isApproveCode = true
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: 'Не удалось отправить код, попробуйте еще раз',
                    type: 'error'
                })
            }
        },
        async onApproveSubmit() {
            if(this.loading) return
            this.loading = true

            let r = await this.$api.otpApproveCode(this.sessionId, this.code)

            this.loading = false

            if(r.result == 'SUCCESS') {
                this.signKey = r.key

                this.$emit('signed', {
                    key: this.signKey
                })

                this.close()
                this.clear()
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: 'Неверный код, попробуйте еще раз',
                    type: 'error'
                })
            }
        },
        async ncaSubmit() {
            let file = this.$refs.file.files[0],
                filename = this.$refs.file.files[0].name

            let formData = new FormData()
            formData.append('file', file, filename)
            formData.append('password', this.password)

            this.$emit('signed', {
                file: file,
                password: this.password
            })
            this.close()
            this.clear()
        },
        getFileExt(filename) {
            return filename.split('.').pop()
        },
        checkFileExt(file) {
            return this.fileExtensions.includes(this.getFileExt(file.name))
        },
        clear() {
            this.password = ''
            this.isSendCode = true
            this.sessionId = ''
            this.isApproveCode = false
            this.code = ''
            this.signKey = ''
        },
        close() {
            this.$refs.closeSignModal.click()
        }
    }
}

</script>

<style scoped>

.input-group {
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.input-group input.form-control:focus {
    border-color: #8f82d8;
    outline: 0;
    box-shadow: inset 0 0 0 1px #8f82d8;
}

.form-label {
    flex: 0 0 100%;
}

input {
    border-radius: .25rem;
}

input::placeholder {
    color: #cfcfcf;
}

.input-danger {
    border-color: #dc3545;
}

.input-warning {
    border-color: #ffc107;
}

.input-success {
    border-color: #198754;
}

.tab-content {
    padding: 1em;
}

.nav-tabs {
    display: flex;
    margin-bottom: 1em;
}

.nav-tabs .nav-item {
    flex: 1;
}

.nav-tabs .nav-link {
    width: 100%;
}

.nav-tabs .nav-link.active {
    color: #8f82d8;
    background: none;
    border-color: #8f82d8;
}
</style>