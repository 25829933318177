<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">{{ $t('claims.import.title.header') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <Input
                        v-model="title"
                        name="title"
                        :placeholder="placeholder"
                    />
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" @click="onSubmit">{{ $t('btn.save') }}</button>
                </div>
            </div>
            <button type="button" class="d-none" ref="importTitleClose" data-bs-dismiss="modal"></button>
        </div>
    </div>
</template>

<script>
import { addZeros } from '../../../libs/text'
import Input from '../form/Input.vue'

export default {
    data: () => ({
        modalId: 'importTitle',
        title: ''
    }),
    computed: {
        placeholder() {     
            return `${this.$t('claims.import.title.placeholder')} ${this.today}`
        },
        today() {
            let date = new Date(),
                day = addZeros(date.getDate(), 2),
                month = addZeros(date.getMonth()+1, 2),
                year = date.getFullYear().toString().slice(-2)

            return `${day}-${month}-${year}`
        }
    },
    components: {
        Input
    },
    methods: {
        onSubmit() {
            let import_title = this.title || `Импорт данных ${this.today}`
            
            this.$emit('titleAdded', import_title)

            this.closeModal()
        },
        closeModal() {
            this.$refs.importTitleClose.click()
        }
    }
}

</script>