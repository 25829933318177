<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h3 class="mb-0">Уведомление о задолженности</h3>
                </div>
                <div class="modal-body">
                    По указанному Вами адресу, {{ address }}, образовалась задолженность перед {{ senderTitle }} в сумме {{ notice.amount_owed }} ₸.
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="viewNotice">Просмотреть</button>
                    <button class="btn btn-danger" data-bs-dismiss="modal" @click="handleClose">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'debtNoticePreviewModal',
    data: () => ({
        modalId: 'debtNoticePreviewModal',
        notice: {},
        senderPhoneNumber: '',
        senderTitle: ''
    }),
    computed: {
        title: {
            get() {
                return this.$t('claim.title') + ' #'
            }
        },
        address: {
            get() {
                let str = this.notice.street + ' ' + this.notice.building_number
                str += this.notice.apartment ? `, ${this.notice.apartment}` : ''
                str += this.notice.extra ? `, ${this.notice.extra}` : ''
                return str
            }
        }
    },
    methods: {
        async read(slug) {
            await this.$api.readNotice(slug)
        },
        async getSenderPhone() {
            let url = process.env.VUE_APP_API_URL + '/recipients/sender-phone'
            
            await axios.get(url, {
                params: {
                    senderId: this.notice.sender
                },
                withCredentials: true
            }).then((response) => {
                this.senderPhoneNumber = response.data
            })
        },
        async getSenderTitle() {
            let url = process.env.VUE_APP_API_URL + '/recipients/sender-title'

            await axios.get(url, {
                params: {
                    senderId: this.notice.sender
                },
                withCredentials: true
            }).then(response => {
                this.senderTitle = response.data
            })
        },
        viewNotice() {
            this.$router.push(`/debt-notice/${this.notice.slug}`)
            this.handleClose()
        },
        clear() {
            this.notice = {}
            this.senderPhoneNumber = ''
        },
        handleClose() {
            this.clear()
            this.$emit('closed')
        }
    },
}
</script>