<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <div v-html="header"></div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-html="body"></div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger" data-bs-dismiss="modal" @click="deleteObj()">{{ $t('claim.btn-remove') }}</button>
                    <button class="btn btn-secondary ms-3" data-bs-dismiss="modal">{{ $t('claims.add.confirm-modal.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'confirmDeleteModal'
    }),
    props: {
        header: {
            type: String,
            default: ''
        },
        body: {
            type: String,
            default: ''
        }
    },
    methods: {
        deleteObj() {
            this.$emit('deleteObj')
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

.modal-body {
    margin-right: 2rem;
}

.btn-primary {
    width: 140px;
}

.bi-exclamation-triangle {
    color: #8f82d8;
}

</style>