<template>
    <div class="input-group" :class="[`input-group-${status}`, tip ? 'has-tip' : '']">
        <label class="form-label" v-if="label">{{ label }}</label>
        <Field
            v-model="value"
            v-maska
            @focus="handleFocus"
            @blur="handleBlur"
            :data-maska="maska"
            :data-maska-tokens="maskaTokens"
            class="form-control"
            :type="type"
            :name="name"
            :class="`input-${tipType}`"
            :placeholder="placeholder"
            :rules="validate"
            :max="max"
            :autocomplete="autocomplete"
            :disabled="disabled"
        />
        <ul class="input-datalist" v-if="_list.length" v-show="showDatalist">
            <li class="datalist-item" v-for="opt in _list" :key="opt.text" @click.stop="handleDatalistItemClick(opt)">
                {{ opt.text }}
            </li>
        </ul>
        <div
            class="tip"
            :class="`text-${validateClass ? validateClass : 'muted'}`"
        >
            <small class="tip-text">
                <ErrorMessage :name="name" />
                {{ tip }}
            </small>
            <div class="tip-icon" v-if="tipType == 'danger'">
                <i class="bi bi-exclamation-triangle-fill text-danger"></i>
            </div>
        </div>
    </div>
</template>

<script>

import { Field, ErrorMessage } from 'vee-validate'
import { vMaska } from "maska"

export default {
    name: 'Input',
    data: () => ({
        isFocused: false,
        validateClass: 'regular',
        newDisabled: null,
        showDatalist: false
    }),
    directives: { maska: vMaska },
    props: {
        modelValue: [String, Number],
        type: {
            type: String,
            default: 'text'
        },
        name: String,
        autocomplete: {
            type: String,
            default: 'on'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maska: {
            type: String,
            default: ''
        },
        maskaTokens: {
            type: String,
            default: ''
        },
        label: String,
        tip: String,
        tipType: {
            type: String,
            default: 'regular'
        },
        status: {
            type: String,
            default: 'regular'
        },
        placeholder: String,
        min: {
            type: [String, Number],
            default: 1
        },
        max: {
            type: [String, Number],
            default: ''
        },
        list: {
            type: Array,
            default: []
        },
        validations: {
            type: Array,
            default: []
        }
    },
    components: {
        Field,
        ErrorMessage
    },
    emits: ['update:modelValue'],
    computed: {
        disabled: {
            get() {
                if(this.newDisabled !== null) return this.newDisabled
                return this.disabled
            }
        },
        _list: {
            get() {
                if(this.list && (!this.value || this.value.length == 0)) return this.list
                return this.filteredList(this.list)

            }
        },
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('change', value, this.name)
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        disable(status = true) {
            this.newDisabled = status
        },
        hasFocus() {
            return this.isFocused
        },
        handleFocus() {
            this.isFocused = true
            this.showDatalist = true
            this.$emit('focused')
        },
        handleBlur() {
            this.isFocused = false
            if(this.showDatalist) {
                setTimeout(() => {
                    if(!this.isFocused)
                        this.showDatalist = false
                    this.$emit('blurred')
                }, 500)
            }
        },
        onChange(e) {
            this.$emit('change', e)
        },
        handleDatalistItemClick(item) {
            this.modalValue = item.text
            this.$emit('datalistClicked', item)
            this.showDatalist = false
        },
        filteredList(arr) {
            return arr.filter(item => item.text.toLowerCase().includes(this.value.toLowerCase()))
        },
        validate(value = '') {
            return true
            if(this.disabled || this.showDatalist) return true

            if(typeof value === 'string' || value instanceof String) value = value.trim()

            let types = {
                date: () => {
                    if(!value) return true

                    const regex = /[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/
                    if(!regex.test(value)) return this.$t('form.validate.date')
                    
                    return true
                },
                dateConflict: () => {
                    if(!value) return true

                    return this.$t('form.validate.date-conflict')
                },
                dateCompare: () => {
                    if(!value) return true

                    let currentDate = new Date()
                    let inputDate = new Date(value)

                    if(inputDate > currentDate) return this.$t('form.validate.date-future')

                    return true
                },
                email: () => {
                    if(!value) return true

                    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    if(!regex.test(value)) return this.$t('form.validate.email')
                    
                    return true
                },
                min: (val) => {
                    if(value <= val) return this.$t('form.validate.min', { val: val })
                    
                    return true
                },
                max: (val) => {
                    if(!value) return true

                    if(value >= val) return this.$t('form.validate.max', { val: val })
                    
                    return true
                },
                minLength: (len) => {
                    if(value.length < len) return this.$t('form.validate.min-length', { len: len })
                    
                    return true
                },
                maxLength: (len) => {
                    if(!value) return true

                    if(value.length > len) return this.$t('form.validate.max-length', { len: len })
                    
                    return true
                },
                confirm: () => {

                },
                required: () => {
                    if(!value) return this.$t('form.validate.required-field')

                    return true
                },
                test: () => {
                    if(process.env.VUE_APP_DEBUG) console.log('test validate', value)

                    return true
                }
            }

            let r = true

            if(this.validations.length > 0) {
                this.validateClass = 'danger'

                for(let i in this.validations) {
                    let v = this.validations[i],
                        regexp = /\(([^)]+)\)/,
                        matches = regexp.exec(v),
                        arg = matches ? matches[1] : null
                    
                    if(arg) v = v.replace(matches[0], '')

                    r = types[v](arg)

                    if(r !== true) return r
                }

                this.validateClass = 'regular'
            }

            return r
        }
    }
}

</script>

<style scoped>

.input-group {
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.input-group input.form-control:focus {
    border-color: #8f82d8;
    outline: 0;
    box-shadow: inset 0 0 0 1px #8f82d8;
}

.form-label {
    flex: 0 0 100%;
}

input.form-control {
    padding: 0 auto;
    border-radius: .25rem !important;
}

input::placeholder {
    color: #cfcfcf;
}

.input-danger {
    border-color: #dc3545;
}

.input-warning {
    border-color: #ffc107;
}

.input-success {
    border-color: #198754;
}

.input-group .input-datalist {
    position: absolute;
    width: 100%;
    max-height: 120px;
    top: 100%;
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 0 0 0.5em 0.5em !important;
    list-style: none;
    overflow-y: auto;
    z-index: 1;
}

.datalist-item {
    color: #000000;
    padding: .25em .5em;
    cursor: pointer;
}

.datalist-item:hover {
    background-color: #eeeeee;
}

.tip {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: .125rem 0;
    font-size: .75rem;
}

</style>
