<template>
    <div class="claim-text">
        <!-- <component v-if="!text" :is="'Template' + template" :claim="claim" :preview="preview"></component> -->
        <!-- <div v-else>{{ text }}</div> -->
        <Default
            v-if="!text"
            ref="templateDefault"
            :claim="claim"
            :realClaim="realClaim"
            :preview="preview"
            @templateFields="templateFields"
        />
        <Default
            v-else
            ref="templateDefault"
            :text="text"
            :realClaim="realClaim"
            :claim="claim"
            @templateFields="templateFields"
        />
    </div>
</template>

<script>
import Default from './templates/Default.vue'

    export default {
        data: () => ({
            dueDate: 10
        }),
        props: {
            claim: {
                type: Object,
                default: {}
            },
            template: {
                type: String,
                default: 'default'
            },
            preview: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: ''
            },
            realClaim: {
                type: Object,
                default: {}
            }
        },
        components: {
            Default
        },
        methods: {
            templateFields(fields) {
                this.$emit('templateFields', fields)
            },
            updateTemplateFields(fields) {
                this.$refs.templateDefault.updateTemplateFields(fields)
            }
        }
    }

</script>

<style scoped>

.claim-text {
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}

</style>