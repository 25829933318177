<template>
    <div id="content" class="col" v-if="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-if="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row">
            <div class="col-md-7">
                <DebtText
                    ref="debtText"
                    :address="address"
                    :senderData="senderData"
                    :amount_owed="amount_owed"
                />
            </div>
            <div class="col-md-5">
                <DebtForm
                    @updateAddress="updateAddress($event)"
                    @updateSender="updateSender($event)"
                    @updateAmount="updateAmount($event)"
                    @createNotice="createNotice($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DebtForm from './Form.vue'
    import DebtText from './DebtText.vue'

    export default {
        data:() => ({
            occurrence_date: '',
            address: '',
            shortAddress: '',
            address_object: {},
            amount_owed: '',
            sender: '',
            senderData: '',
            send: true,
            text: '',
            mounting: true
        }),
        mounted() {
            this.checkOsi()
            
            this.$emit('setPageTitle', this.$t('debt-notice.new.title'))

            this.getSender()
        },
        components: {
            DebtText,
            DebtForm
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('debt-notice.new.title'))
            },
            async checkOsi() {
                let isOsi = await this.$api.checkOsiStatus()
                if(!isOsi) {
                    this.$router.replace('/dashboard')
                } else{
                    this.mounting = false
                }
            },
            async getSender() {
                let r = await this.$api.getSenderData(this.$store.state.user)

                if(r.result === 'SUCCESS') {
                    this.sender = r.data.id
                    this.senderData = r.data
                    this.senderPhone = r.data.phone.replace(/\D/g, '')
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }

                // let url = process.env.VUE_APP_API_URL + '/recipients/sender-id'

                // await axios.get(url, {
                //     withCredentials: true
                // })
                // .then((response) => {
                //     let r = response.data
                    
                //     this.sender = r.data.id
                //     this.senderData = r.data
                // })

                this.$refs.debtText.generateText()
            },
            async createNotice(data) {
                let url = process.env.VUE_APP_API_URL + '/debt-notice/new'

                await axios.post(url, data, {
                    withCredentials: true
                }).then(response => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        if(data.sendNow) this.$router.push(`/debt-notice/${r.data}`)
                        if(!data.sendNow) this.$router.push(`/debt-notice/${r.data}/edit`)
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message,
                            type: 'error'
                        })
                    }
                })
            },
            updateAddress(value) {
                this.address = value
            },
            updateSender(value) {
                this.senderData = value
            },
            updateAmount(value) {
                this.amount_owed = value
            }
        }
    }
</script>