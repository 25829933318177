<template>
    <div class="modal fade modal-z-index" ref="modalRef" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="addRecipientModalTitle">{{ $t('claims.add.add-recipient') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <AddRecipientForm
                        ref="recipientModalForm"
                        @recipientAdded="recipientAdded($event)"
                        @showConfirmModal="showConfirmModal"
                    />
                </div>
            </div>
        </div>
    </div>
    <ConfirmDeleteModal
        ref="confirmRecipientModal"
        :body="$t('recipient.modal-delete')"
        @deleteObj="remove()"
    />
</template>

<script>

import AddRecipientForm from './Form.vue'
import ConfirmDeleteModal from './ConfirmDeleteModal.vue'

export default {
    name: 'addRecipientModal',
    data: () => ({
        id: 'addRecipientModal',
        title: '',
        objId: ''
    }),
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    components: {
        AddRecipientForm,
        ConfirmDeleteModal
    },
    methods: {
        recipientAdded(id) {
            this.$store.commit('modalHide', { id: this.id})

            this.$emit('recipientAdded', id)
        },
        load(data, edit = false) {
            this.$refs.recipientModalForm.load(data, edit)
        },
        remove() {
            this.$refs.recipientModalForm.remove(this.id)
        },
        showConfirmModal(id) {
            this.objId = id

            this.$store.commit('modalShow', { id: 'confirmDeleteModal' })
        },
        clearFields() {
            this.$refs.recipientModalForm.load({})
            this.$refs.recipientModalForm.reset()
        }
    }
}

</script>

<style scoped>

.modal-content {
    /* min-width: 600px; */
    /* max-width: fit-content; */
    align-self: center;
    border-radius: 0;
    /* box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3); */
    /* position: absolute; */
    /* top: 5rem; */
    /* right: -50px; */
}

.modal-footer, .modal-header {
    border: none;
}

.modal-z-index {
    z-index: 5000;
}

</style>