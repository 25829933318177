<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg':type=='address'}">
        <!-- <div class="modal-dialog modal-dialog-centered modal-lg"> -->
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="addRecipientModalTitle">{{ $t(`claims.add.add-${type}`) }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="addRecipientContactsModalClose"></button>
                </div>
                <div class="modal-body">
                    <Form
                        ref="formContacts"
                        @recipientContactsAdded="added"
                        :id="recipientId"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Form from '../FormContacts.vue'

export default {
    name: 'AddRecipientContactsModal',
    data: () => ({
        id: 'addRecipientContactsModal',
        type: 'email',
        recipientId: null
    }),
    components: {
        Form
    },
    methods: {
        added(data) {
            this.close()
            this.$emit('added', data)
        },
        close() {
            this.$refs.addRecipientContactsModalClose.click()
            this.$refs.formContacts.clear()
        },
        setType(type, id) {
            this.type = type
            
            this.recipientId = id

            this.$refs.formContacts.setType(type)
        }
    }
}

</script>

<style scoped>

.modal-content {
    align-self: center;
    border-radius: 0;
}

.modal-footer, .modal-header {
    border: none;
}


</style>