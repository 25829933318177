<template>
    <div class="modal fade" ref="searchModalRef" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="searchRecipientModalTitle">{{ $t('claims.add.add-recipient') }}</h1>
                    <button type="button" class="btn-close" ref="searchRecipientModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="card shadow-none">
                        <div class="card-header">
                            <ul class="nav nav-tabs card-header-tabs" id="myTabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active fs-6" id="iin" data-bs-toggle="tab" href="#iin-pane" role="tab" aria-controls="iin" aria-selected="true">{{ $t('recipients.form.id_no') }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fs-6" id="phone" data-bs-toggle="tab" href="#phone-pane" role="tab" aria-controls="phone" aria-selected="false">{{ $t('recipients.form.phone') }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fs-6" id="bin" data-bs-toggle="tab" href="#bin-pane" role="tab" aria-controls="bin" aria-selected="false">{{ $t('recipients.form.bin') }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="iin-pane">
                                    <SearchForm
                                        ref="recipientSearchIinForm"
                                        @recipientAdded="recipientAdded($event)"
                                        @closeModal="close"
                                        @openAddRecipientModal="openAddRecipientModal"
                                        :search-type="'iin'"
                                    />
                                </div>
                                <div class="tab-pane fade" id="phone-pane">
                                    <SearchForm
                                        ref="recipientSearchPhoneForm"
                                        @recipientAdded="recipientAdded($event)"
                                        @closeModal="close"
                                        @openAddRecipientModal="openAddRecipientModal"
                                        :search-type="'phone'"
                                    />
                                </div>
                                <div class="tab-pane fade" id="bin-pane">
                                    <SearchForm
                                        ref="recipientSearchBinForm"
                                        @recipientAdded="recipientAdded($event)"
                                        @closeModal="close"
                                        @openAddRecipientModal="openAddRecipientModal"
                                        :search-type="'bin'"
                                    />
                                </div>
                                <div v-if="false">
                                    <h4>{{ $t('recipients.search.latest') }}</h4>
                                    <DataList
                                        ref="latestRecipients"
                                        :columns="columns"
                                        :data="latest"
                                        :enableSearch="false"
                                        @row-clicked="recipientAdded($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmDeleteModal
        ref="confirmRecipientModal"
        :body="$t('recipient.modal-delete')"
        @deleteObj="remove()"
    />
    <AddRecipientModal
        ref="addRecipientFromSearch"
        @recipientAdded="recipientAdded"
    />
</template>

<script>

import SearchForm from './../SearchForm.vue'
import ConfirmDeleteModal from './../ConfirmDeleteModal.vue'
import AddRecipientModal from '../AddRecipientModal.vue'
import DataList from '../../cabinet/DataList.vue'

export default {
    name: 'searchRecipientModal',
    data: () => ({
        id: 'searchRecipientModal',
        title: '',
        objId: '',
        latest: [],
        showLatest: false
    }),
    computed: {
        columns: {
            get() {
                let cols = []

                cols.push({ value: 'iinBin', text: 'ИИН / БИН' })
                cols.push({ value: 'title', text: 'Фамилия / Наименование' })
                cols.push({ value: 'phone', text: 'Телефон' })

                return cols
            }
        }
    },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    components: {
        SearchForm,
        ConfirmDeleteModal,
        AddRecipientModal,
        DataList
    },
    methods: {
        async recipientAdded(id) {
            if(typeof id === 'object') id = id.id

            await this.addRelation(this.$store.state.user.id, id)

            this.$refs.searchRecipientModalClose.click()
            this.$store.commit('modalHide', { id: this.id})

            this.$emit('recipientAdded', id)
        },
        load(data, edit = false) {
            this.$refs.recipientModalForm.load(data, edit)
        },
        unblock() {
            setTimeout(() => {
                this.$refs.recipientModalForm.unblock()
            }, 1000)
        },
        remove() {
            this.$refs.recipientModalForm.remove(this.id)
        },
        showConfirmModal(id) {
            this.objId = id

            this.$store.commit('modalShow', { id: 'confirmDeleteModal' })
        },
        clearFields() {
            this.$refs.recipientModalForm.load({})
            this.$refs.recipientModalForm.reset()
        },
        reset() {
            this.$refs.recipientSearchIinForm.reset()
            this.$refs.recipientSearchPhoneForm.reset()
            this.$refs.recipientSearchBinForm.reset()
        },
        editRecipient(data) {
            this.$refs.addRecipientFromSearch.load(data)
            this.$store.commit('modalShow', { id: 'addRecipientModal' })
        },
        openAddRecipientModal(searchType, param, lastName = '') {
            if(searchType === 'iin') this.$refs.addRecipientFromSearch.load({ id_no: param, lastName: lastName })
            if(searchType === 'phone') this.$refs.addRecipientFromSearch.load({ phone: param, lastName: lastName })
            if(searchType === 'bin') this.$refs.addRecipientFromSearch.load({ id_no: param })
            
            this.$store.commit('modalShow', { id: 'addRecipientModal' })
        },
        async addRelation(userId, recipientId) {
            let data = {
                userId: userId,
                recipientId: recipientId
            }
            
            let url = process.env.VUE_APP_API_URL + '/recipients/relations'

            let result = await axios.post(url, data, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data
                if(r.result === 'SUCCESS') {
                    return r.data
                } else {
                    return false
                }
            })

            return result
        },
        async getLatestRecipients() {
            this.showLatest = true

            let url = process.env.VUE_APP_API_URL + '/recipients/latest'

            await axios.get(url, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.latest = r.data
                }
            })
        }
    }
}

</script>

<style scoped>

.card {
    background-color: #fbfbfb;
}
.modal-content {
    /* min-width: 600px; */
    /* max-width: fit-content; */
    align-self: center;
    border-radius: 0;
    /* box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3); */
    /* position: absolute; */
    /* top: 5rem; */
    /* right: -50px; */
}

.modal-footer, .modal-header {
    border: none;
}

.nav-tabs .nav-link.active {
    color: 	#3a3077;
    border-color: #3a3077;
}

</style>