<template>
    <div class="select-group">
        <label class="form-label" v-if="label">{{ label }}</label>
        <select
            v-model="value"
            class="form-select"
            :name="name"
            :class="`select-${status ? status : 'regular'}`"
            :rules="validate"
            :multiple="multiple"
            :disabled="disabled"
        >
            <option value="" v-if="placeholder">{{ placeholder }}</option>
            <option v-for="option in options" :value="option[val]">{{ option[title] }}</option>
        </select>
        <div
            class="tip"
            :class="`text-${validateClass ? validateClass : 'muted'}`"
        >
            <small class="tip-text">
                <ErrorMessage :name="name" />
                {{ tip }}
            </small>
            <div class="tip-icon">
                <i class="bi bi-exclamation-triangle-fill" v-if="status == 'danger'"></i>
            </div>
        </div>
    </div>
</template>

<script>

import { Field, ErrorMessage } from 'vee-validate'

export default {
    name: 'Select',
    data: () => ({
        validateClass: 'regular'
    }),
    props: {
        modelValue: [String, Number],
        name: {
            type: String,
            default: ''
        },
        label: String,
        placeholder: {
            type: String,
            default: ''
        },
        tip: String,
        status: String,
        multiple: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        val: {
            type: String,
            default: 'id'
        },
        title: {
            type: String,
            default: 'title'
        },
        validations: {
            type: Array,
            default: []
        }
    },
    components: {
        Field,
        ErrorMessage
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        validate(value) {
            if(this.disabled) return true

            let types = {
                required: () => {
                    if(!value) return this.$t('form.validate.required-field')

                    return true
                },
                test: () => {
                    if(process.env.VUE_APP_DEBUG) console.log('test validate', value)

                    return true
                }
            }

            if(this.validations.length > 0) {
                this.validateClass = 'danger'
                
                let r = true

                for(let i in this.validations) {
                    let v = this.validations[i],
                        regexp = /\(([^)]+)\)/,
                        matches = regexp.exec(v),
                        arg = matches ? matches[1] : null
                    
                    if(arg) v = v.replace(matches[0], '')

                    r = types[v](arg)
                    if(r !== true) return r
                }

                this.validateClass = 'regular'

                return r
            }
        }
    }
}

</script>

<style scoped>

.select-group {
    flex-direction: column;
    margin-bottom: 1rem;
}

select {
    outline: none;
}

select:focus {
    border-color: #8f82d8;
}

.select-danger {
    border-bottom-color: #dc3545;
}

.select-warning {
    border-bottom-color: #ffc107;
}

.select-success {
    border-bottom-color: #198754;
}

.tip {
    display: flex;
    justify-content: space-between;
    margin: .125rem 0;
    font-size: .75rem;
}

</style>