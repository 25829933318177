<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row" v-if="isOsi">
            <div class="col-md-12 mb-3">
                <h2>Отравьте уведомление о задолженности на адрес должника</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mb-3" v-if="isOsi">
                <div class="card card-hover has-click" @click="handleClickNewClaim">
                    <div class="card-body text-center">
                        <i class="bi bi-file-earmark-plus-fill fs-1"></i>
                        <p class="card-text">
                            Создать новое уведомление о задолженности
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3" v-if="isOsi">
                <div class="card card-hover has-click" @click="handleClickImport">
                    <div class="card-body text-center">
                        <i class="bi bi-file-earmark-spreadsheet-fill fs-1"></i>
                        <p class="card-text">
                            Импорт уведомлений
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3" v-if="isOsi">
                <div class="card card-hover has-click" @click="handleClickOutbox">
                    <div class="card-body text-center">
                        <i class="bi bi-send-check-fill fs-1"></i>
                        <p class="card-text">
                            Исходящие уведомления
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3" v-else>
                <div class="card card-hover has-click" @click="handleClickInbox">
                    <div class="card-body text-center">
                        <i class="bi bi-inboxes-fill fs-1"></i>
                        <p class="card-text">
                            Входящие уведомления
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        isOsi: false
    }),
    mounted() {
        this.$emit('setPageTitle', this.$t('debt-notice.title'))

        this.checkIfOsi()
    },
    methods: {
        langChange() {
            this.$emit('setPageTitle', this.$t('debt-notice.title'))
        },
        async checkIfOsi() {
            this.isOsi = await this.$api.checkOsiStatus()
        },
        handleClickNewClaim() {
            this.$router.push('/debt-notice/new')
        },
        handleClickOutbox() {
            this.$router.push('/debt-notice/outbox')
        },
        handleClickInbox() {
            this.$router.push('/debt-notice/inbox')
        },
        handleClickImport() {
            this.$router.push('/debt-notice/import')
        }
    }
}

</script>

<style scoped>

.card-hover {
    color: #8f82d8;
    transition: .25s;
    -o-transition: .25s;
    -moz-transition: .25s;
    -webkit-transition: .25s;
}

.card-hover:hover {
    color: #ffffff;
    background-color: #8f82d8;
}

.card-hover.has-click {
    cursor: pointer;
}

</style>