const defaultLang = 'ru'

const locales = {
    ru: require('../src/common/locales/api/ru.json'),
    kk: require('../src/common/locales/api/kk.json'),
    en: require('../src/common/locales/api/ru.json')
}

function isString(v) {
    return typeof v === 'string'
}

function getTranslate(keys, locale = {}) {
    if(isString(locale)) return locale
    
    for(let i in keys) {
        if(keys[i] in locale) {
            let r = locale[keys[i]]

            return keys.length == 1 ? r : getTranslate(keys.slice(1), r)
        }
    }

    return false
}

function translate(str, lang = defaultLang) {
    let t = getTranslate(str.split('.'), locales[lang])

    return t ? t : str.split('.').pop()
}

module.exports = {
    translate
}