<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="article-section">
                <h1 class="text-uppercase mb-3">{{ $t('articles.title') }}</h1>
                <div class="loading-wrap" v-if="loading">
                    <div class="loading"></div>
                    <p>{{ $t('loading') }}</p>
                </div>
                <div class="row g-4" v-else-if="articles.length">
                    <div class="col-md-4" v-for="article in articles">
                        <div class="card h-100">
                            <div class="card-header">
                                <h2>
                                    <i class="bi bi-newspaper"></i>
                                </h2>
                                <p>{{ article.humanDate }}</p>
                            </div>
                            <div class="card-body">
                                <router-link class="header-link" :to="{ path: `/${this.locale}${article.link}` }">
                                    <h5>{{ article.title }}</h5>
                                </router-link>
                            </div>
                            <div class="card-footer">
                                <router-link class="btn btn-primary" :to="{ path: `/${this.locale}${article.link}` }">{{ $t('read') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <nav aria-label="Article Pagination" v-if="totalPages>1">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                <a class="page-link" href="#" @click="currentPage--">
                                    <i class="bi bi-chevron-left"></i>
                                </a>
                            </li>
                            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }">
                                <a class="page-link" href="#" @click="currentPage=page">{{ page }}</a>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                <a class="page-link" href="#" @click="currentPage++">
                                    <i class="bi bi-chevron-right"></i>    
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="text-center" v-else>
                    <p>{{ $t('articles.no-articles') }}</p>
                </div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
    name: 'Articles',
    data: () => ({
        loading: false,
        articles: [],
        totalArticles: 0,
        totalPages: 1,
        articlesPerPage: 3,
        currentPage: 1,
        locale: ""
    }),
    components: {
        Header,
        Footer
    },
    methods: {
        async get() {
            if(this.loading) return
            this.loading = true
            this.locale = this.$i18n.locale

            let url = process.env.VUE_APP_API_URL + '/articles'

            await axios.get(url, {
                params: { 
                    page: this.currentPage,
                    language: this.$i18n.locale
                }
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.totalPages = r.data.totalPages
                    this.articles = r.data.articles
                    this.articlesPerPage = r.data.articlesPerPage
                } else {
                    this.articles = []
                }
            })

            this.loading = false
        }
    },
    watch: {
        currentPage(value, old) {
            if(value == old) return
            
            if(value > 0 && value <= this.totalPages) {
                this.get()
            }
        }
    },
    mounted() {
        this.get()
    }
}

</script>

<style scoped>

    .container-fluid {
        background-color: #f3f3f3;
    }

    section {
        padding: 2em 1em;
    }

    .article-section {
        padding-top: 140px;
        padding-bottom: 3em;
        margin-bottom: 0;
        min-height: 70vh;
    }

    .bi {
        color: #7d6ce0;
    }

    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content h1,
    .content h2,
    .content h3,
    .content h4,
    .content h5,
    .content h6 {
        font-weight: 600;
    }

    .figure-img {
        width: 600px;
        max-width: 80vw;
    }

    .header-link {
        color: #3a3077;
    }

    .header-link:hover {
        color: #7d6ce0;
    }

    .loading-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 300px;
        text-align: center;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>