<template>
    <div class="modal fade" ref="confSendModal" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" @click="handleClick">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <Form @submit="onSubmit">
                    <div class="modal-body">
                        <p class="text-center">
                            <span class="bi bi-exclamation-triangle fs-2 align-middle mx-3"></span>
                            {{ $t('claims.add.confirm-modal.body') }}
                        </p>
                        <div class="px-5">
                            <div class="input-group d-flex">
                                <div class="row m-0">
                                    <label class="form-label p-0">{{ $t('claims.add.confirm-modal.form-label') }}</label>
                                    <input type="file" ref="sign" class="form-control">
                                </div>
                                <div class="row m-0">
                                    <div class="tip text-danger" v-if="error">
                                        <small class="tip-text">
                                            <span role="alert">{{error}}</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <Input
                                v-model="password"
                                name="password"
                                type="password"
                                :label="$t('claims.add.confirm-modal.input-label')"
                                :placeholder="$t('claims.add.confirm-modal.input-placeholder')"
                                :validations="['required']"
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            :disabled="loading"
                        >
                            {{ $t('claims.add.confirm-modal.send') }}
                            <span class="loading" v-show="loading"></span>
                        </button>
                        <button type="button" class="btn btn-danger ms-3" data-bs-dismiss="modal" @click="stopLoader">{{ $t('claims.add.confirm-modal.cancel') }}</button>
                        <button type="button" class="d-none" ref="closeConfirmSendModal" data-bs-dismiss="modal"></button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>

import { Form } from 'vee-validate'
import Input from '../form/Input.vue'

export default {
    data: () => ({
        id: 'confirmSendModal',
        password: '',
        count: 5,
        loading: false,
        error: ''
    }),
    components: {
        Form,
        Input
    },
    methods: {
        async onSubmit() {
            this.error = ''

            if(!this.$refs.sign.files[0] || (this.$refs.sign.files[0].type !== 'application/pkcs12' && this.$refs.sign.files[0].type !==  'application/x-pkcs12')) {
                this.error = 'Неправильный формат файла'
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-file'),
                    type: 'error'
                })

                return
            } else if(this.$refs.sign.files[0].name.startsWith('AUTH')) {
                this.error = 'Требуется файл подписи (не AUTH)'
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.wrong-ext-sign-file'),
                    type: 'error'
                })

                return
            }

            if(!this.password) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.notify.no-sign-password'),
                    type: 'error'
                })

                return
            }

            let url = process.env.VUE_APP_API_URL + '/claims/check-signature'
            let formData = new FormData()
            let data = {
                sign: this.$refs.sign.files[0],
                password: this.password
            }

            for(let i in data) {
                if(i != 'sign') {
                    formData.append(i, data[i])
                } else {
                    formData.append('file', data[i], data[i].name)
                }
            }

            this.loading = true

            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.loading = false

                    this.send()
                } else {
                    this.loading = false

                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })

                    return
                }
            })
        },
        send() {
            if(this.loading) return
            this.loading = true

            this.$emit('confirmed', {
                sign: this.$refs.sign.files[0],
                password: this.password
            })

            this.loading = false
            this.close()
        },
        close() {
            this.error = ''
            this.$refs.closeConfirmSendModal.click()
        },
        stopLoader() {
            this.error = ''
            this.$emit('stopLoader')
        },
        handleClick(e) {
            if(e.target === e.currentTarget) {
                this.stopLoader()
            }
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

.btn-primary {
    width: 140px;
}

.bi-exclamation-triangle {
    color: #8f82d8;
}

.input-group {
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.input-group input.form-control:focus {
    border-color: #8f82d8;
    outline: 0;
    box-shadow: inset 0 0 0 1px #8f82d8;
}

.form-label {
    flex: 0 0 100%;
}

input {
    border-radius: .25rem;
}

input::placeholder {
    color: #cfcfcf;
}

.input-danger {
    border-color: #dc3545;
}

.input-warning {
    border-color: #ffc107;
}

.input-success {
    border-color: #198754;
}

.tip-text {
    line-height: 16.8px;
}

</style>