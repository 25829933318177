<template>
    <Sidebar
        ref="sidebar"
        @updatedCurrentPlan="updatedCurrentPlan"
        @updatedInboxCount="updatedInboxCount"
        @updatedOutboxCount="updatedOutboxCount"
    />
    <Navbar
        ref="navbar"
        :title="title"
        @balanceChanged="updatedBalance"
        @langChanged="langChanged"
    />
</template>

<script>
    import Sidebar from '../Sidebar.vue'
    import Navbar from './Navbar.vue'

    export default {
        name: 'Panel',
        props: {
            title: {
                type: String,
                default: 'Cabinet'
            }
        },
        components: {
            Sidebar,
            Navbar
        },
        methods: {
            langChanged(lang) {
                this.$emit('langChanged', lang)
            },
            updateCounters() {
                this.$refs.sidebar.getInboxCount()
                this.$refs.sidebar.getOutboxCount()
            },
            updateBalance() {
                this.$refs.navbar.getBalance()
            },
            updatedCurrentPlan(val) {
                this.$emit('updated', { key: 'plan', value: val })
            },
            updatedInboxCount(val) {
                this.$emit('updated', { key: 'inbox', value: val })
            },
            updatedOutboxCount(val) {
                this.$emit('updated', { key: 'outbox', value: val })
            },
            updatedBalance(val) {
                if(process.env.VUE_APP_DEBUG) console.log('updatedBalance', val)
                this.$emit('updated', { key: 'balance', value: val })
            },
            updateOrganizationType() {
                this.$refs.sidebar.updateOrganizationType()
            },
            async getUnreadNotices() {
                await this.$refs.sidebar.getUnreadDebtNotice()
            }
        }
    }
</script>