<template>
    <div id="content" class="col">
        <div class="row">
            <div class="col-md-8">
                <Form class="shadow p-3 mb-5 rounded" @submit="onMainSubmit">
                    <div class="row">
                        <div class="col-md-6">
                            <Input
                                v-model="lastName"
                                name="lastName"
                                ref="profileLastName"
                                :disabled="true"
                                :label="$t('user.lastName')"
                                :placeholder="$t('user.lastName-placeholder')"
                                :validations="['required', 'minLength(3)', 'maxLength(50)']"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="firstName"
                                name="firstName"
                                ref="profileFirstName"
                                :disabled="true"
                                :label="$t('user.firstName')"
                                :placeholder="$t('user.firstName-placeholder')"
                                :validations="['required', 'minLength(3)', 'maxLength(50)']"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="patronymic"
                                name="patronymic"
                                :disabled="true"
                                :label="$t('user.patronymic')"
                                :placeholder="$t('user.patronymic-placeholder')"
                                :validations="['required', 'minLength(3)', 'maxLength(50)']"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="phone"
                                name="phone"
                                maska="+7 (###) ###-##-##"
                                :label="$t('user.phone')"
                                :placeholder="$t('recipients.form.phone-placeholder')"
                                :validations="['minLength(12)', 'maxLength(20)']"
                                :tipType="!phone?'danger':''"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="address"
                                name="address"
                                :disabled="true"
                                :label="$t('user.address')"
                                :placeholder="$t('user.address-placeholder')"
                                :validations="['required', 'minLength(3)']"
                                :tipType="!address?'danger':''"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="id_no"
                                name="id_no"
                                :disabled="true"
                                :label="$t('user.id_no')"
                                :placeholder="$t('recipients.form.id_no-placeholder')"
                            />
                        </div>
                        <div class="col-md-6" v-if="companyTitle">
                            <Input
                                v-model="companyTitle"
                                name="companyTitle"
                                :disabled="true"
                                :label="$t('user.company-title')"
                                :placeholder="$t('user.company-title-placeholder')"
                            />
                        </div>
                        <div class="col-md-6" v-if="companyIdNo">
                            <Input
                                v-model="companyIdNo"
                                name="companyIdNo"
                                :disabled="true"
                                :label="$t('user.company-id-no')"
                                :placeholder="$t('user.company-id-no-placeholder')"
                            />
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="email"
                                name="email"
                                :disabled="true"
                                :label="$t('user.email')"
                                :placeholder="$t('user.email-placeholder')"
                                :tipType="!email?'danger':''"
                            />
                        </div>
                        <div class="col-md-6 align-self-center">
                            <div class="d-flex flex-column align-items-start">
                                <p></p>
                                <div class="d-flex align-items-center">
                                    <i :class="'bi bi-'+emailTip.icon+' fs-4 me-3'"></i>{{ emailTip.text }} {{ emailTip.type==='waiting'?'('+user.settings.validEmail+')':'' }}
                                </div>
                                    
                            </div>
                        </div>
                        <div class="col-md-12" v-show="canSignFromCompany ?? false">
                            <p class="text-muted">
                                <i class="bi" :class="canSignFromCompany ? 'bi-check-lg text-success' : 'bi-x-lg text-danger'"></i>
                                {{ $t('settings.profile.can-sign-from-company') }}
                            </p>
                        </div>
                    </div>
                    <div class="my-3">
                        <button type="submit" class="btn btn-primary mb-3 me-3">{{ $t('settings.profile.save') }}</button>
                        <button
                            type="button"
                            class="btn btn-secondary mb-3 me-3"
                            @click="clickChangeEmail"
                        >
                            {{ email ? $t('settings.profile.change-email') : $t('settings.profile.set-email') }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary mb-3 me-3"
                            @click="showChangePasswordModal"
                        >
                            {{ $t('settings.profile.change-password') }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary mb-3 me-3"
                            @click="showAddressModal"
                        >
                            {{ $t('settings.profile.change-address') }}
                        </button>
                    </div>
                </Form>
            </div>
            <div class="col-md-8">
                <Form class="shadow p-3 mb-5 rounded" @submit="onBankDetailsSubmit">
                    <div class="row my-3">
                        <div class="col-md-12">
                            <h5>{{ $t('user.requisites') }}</h5>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-md-5">
                            <Input
                                v-model="iban"
                                name="iban"
                                maska="KZ#####BBBBBBBBBBBBB"
                                maska-tokens="B:[A-Z0-9]"
                                :label="$t('user.iban')"
                                :placeholder="$t('user.iban-placeholder')"
                                :validations="['required', 'minLength(20)', 'maxLength(20)']"
                            />
                        </div>
                        <div class="col-md-4">
                            <Select
                                v-model="bank_bic"
                                name="bank_bic"
                                :label="$t('user.bank-bic')"
                                :placeholder="$t('user.bank-bic-placeholder')"
                                :options="bics"
                                :validations="['required']"
                            />
                        </div>
                        <div class="col-md-3" v-if="false">
                            <Input
                                v-model="knp"
                                name="knp"
                                :disabled="true"
                                :label="$t('user.knp')"
                                :placeholder="$t('user.knp-placeholder')"
                            />
                        </div>
                        <div class="col-md-3" v-if="companyIdNo">
                            <Select
                                v-model="kbe"
                                name="kbe"
                                :label="$t('user.kbe')"
                                :placeholder="$t('user.kbe-placeholder')"
                                :options="kbes"
                                :validations="['required']"
                            />
                        </div>
                    </div>
                    <div class="my-3">
                        <button type="submit" class="btn btn-primary mb-3 me-3">{{ $t('settings.profile.save') }}</button>
                    </div>
                    <div class="row my-1">
                        <div class="col-md-12">
                            <div class="alert alert-light d-flex">
                                <i class="bi bi-info-circle text-start mx-3"></i>
                                <p>{{ requisitesDescription }} <router-link v-if="userType==='company'" to="/settings/rates">{{ $t('sidebar.payment-settings') }}</router-link></p>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div class="col-md-8">
                <Form class="shadow p-3 mb-5 rounded" @submit="onMainSubmit">
                    <div class="row my-3">
                        <div class="col-md-5">
                            <Select
                                v-model="userTypeModel"
                                :name="'userTypeSelect'"
                                :label="$t('user.user-type')"
                                :placeholder="placeholder"
                                :options="userTypes"
                                :search="false"
                                @change="onTypeChange"
                                :disabled="responseAwait"
                            />
                        </div>
                    </div>
                </Form>
            </div>
            <ImportApiKey v-if="apiAccess" />
            <NotificationSettings />
            <div class="col-md-8" v-if="contacts">
                <div class="shadow p-3 mb-5 rounded">
                    <h4>{{ $t('settings.profile.contacts') }}</h4>
                    <div class="my-3" v-if="contacts.addresses.length">
                        <h6>{{ $t('settings.profile.my-addresses') }}</h6>
                        <div class="contact-item" v-for="contact in contacts.addresses">
                            <span class="contact-address">{{ contact.value }}</span>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                v-if="addressId != contact.id && contacts.addresses.length > 1"
                                @click="showRemoveContactModal(contact.id, 'address', contact.value)"
                            >
                                {{ $t('settings.profile.remove-contact') }}
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div class="my-3" v-if="contacts.emails.length">
                        <h6>{{ $t('settings.profile.my-emails') }}</h6>
                        <div class="contact-item" v-for="(contact, index) in contacts.emails">
                            <span class="contact-email">{{ contact.value }}</span>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                v-if="index > 0 && contacts.emails.length > 1"
                                @click="showRemoveContactModal(contact.id, 'email', contact.value)"
                            >
                                {{ $t('settings.profile.remove-contact') }}
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div class="my-3" v-if="contacts.phones.length">
                        <h6>{{ $t('settings.profile.my-phones') }}</h6>
                        <div class="contact-item" v-for="(contact, index) in contacts.phones">
                            <span class="contact-phone">{{ contact.value }}</span>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                v-if="index > 0 && contacts.phones.length > 1"
                                @click="showRemoveContactModal(contact.id, 'phone', contact.value)"
                            >
                                {{ $t('settings.profile.remove-contact') }}
                                <i class="bi bi-x"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="canSignAuthorize">
                <div class="my-3">
                    <h5>{{ $t('settings.profile.choice-signer-title') }}</h5>
                    <Form class="shadow p-3 mb-5 rounded" @submit="onSubmitNewSigner">
                        <div class="row">
                            <div class="col-md-6">
                                <Select
                                    v-model="newSignerIdNum"
                                    name="newSignerIdNum"
                                    :placeholder="$t('settings.profile.choice-new-signer')"
                                    :options="_employees"
                                    :validations="['required']"
                                />
                            </div>
                        </div>
                        <div class="btn-group my-3" role="group">
                            <button type="submit" class="btn btn-primary">{{ $t('settings.profile.save-new-signer') }}</button>
                        </div>
                    </Form>
                    <div class="my-3">
                        <h5>{{ $t('settings.profile.choice-signer-list-title') }}</h5>
                        <DataList
                            ref="DataList"
                            :columns="columns"
                            :data="signers"
                            :enableSearch="false"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by="sortBy"
                            :sort-type="sortType"
                            :total="totalItems"
                            @button-action="handleButtonAction"
                            @row-clicked="handleRowClicked"
                            @updated="update"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ChangeEmailModal ref="changeEmailModal" @changed="changedEmail" :currentEmail="email" />
    <ChangePasswordModal ref="changePasswordModal" @changed="changedPassword" />
    <ConfirmSentModal ref="confirmSentModal" :newEmail="emailConfirm.email||''" @otherEmail="showChangeEmailModal" />
    <AddressModal ref="addressModal" @saved="addressSaved" />
    <RemoveContactModal ref="removeContactModal" @removed="contactRemoved" />
</template>

<script>
    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'
    import Select from '../form/Select2.vue'
    import DataList from '../cabinet/DataList.vue'
    import ChangeEmailModal from './ChangeEmailModal.vue'
    import ChangePasswordModal from './ChangePasswordModal.vue'
    import ConfirmSentModal from './ConfirmSentModal.vue'
    import AddressModal from './AddressModal.vue'
    import RemoveContactModal from './RemoveContactModal.vue'
    import ImportApiKey from './ImportApiKey.vue'
    import NotificationSettings from './NotificationSettings.vue'

    export default {
        name: 'Profile',
        data: () => ({
            user: null,
            user_id: null,
            showChangePassword: false,
            firstName: '',
            lastName: '',
            patronymic: '',
            phone: '',
            address: '',
            id_no: '',
            companyTitle: '',
            companyIdNo: '',
            email: '',
            iban: '',
            bank_bic: '',
            bank_bics: [],
            kbe: '',
            kbes: [],
            knp: '343',
            role: '',
            userTypeModel: '',
            userTypes: [],
            contacts: {
                addresses: [],
                emails: [],
                phones: []
            },
            addressId: null,
            canSignAuthorize: false,
            employees: [],

            columns: [
                { value: 'title', 'text': 'ФИО' },
                { value: 'buttons', 'text': 'Действия' }
            ],
            signers: [],
            newSignerIdNum: '',

            currentPage: 1,
            perPage: 5,
            sortBy: 'created',
            sortType: 'desc',
            totalItems: 0,
            canSignFromCompany: false,
            hasPassword: false,
            emailConfirm: {},
            emailTip: {},
            apiAccess: false,
            responseAwait: false
        }),
        computed: {
            _employees: {
                get() {
                    let arr = []

                    for(let i in this.employees) {
                        let employee = {
                            id: this.employees[i].id_no,
                            text: this.employees[i].title
                        }

                        arr.push(employee)
                    }

                    return arr
                }
            },
            roles: {
                get() {
                    return {
                        admin: this.$t('user.roles.admin'),
                        user: this.$t('user.roles.user'),
                        empty: this.$t('user.roles.empty')
                    }
                }
            },
            buttons: {
                get() {
                    return {
                        remove: this.$t('data-list.buttons.remove'),
                        mark: this.$t('data-list.buttons.mark'),
                        unmark: this.$t('data-list.buttons.unmark')
                    }
                }
            },
            translateTypes: {
                get() {
                    return {
                        'none': this.$t('user.user-types.null'),
                        'ip': this.$t('user.user-types.sole-proprietor'),
                        'osi': this.$t('user.user-types.assosiaction-property-owners')
                    }
                }

            },
            userType: {
                get() {
                    if(this.user?.settings && this.user?.settings?.bin) {
                        return 'company'
                    } else if (this.user?.settings && !this.user?.settings?.bin) {
                        return 'person'
                    } else {
                        return ''
                    }
                }
            },
            requisitesDescription: {
                get() {
                    if(this.user?.settings && this.user?.settings?.bin) {
                        return this.$t('user.requisites-desc.company')
                    } else if (this.user?.settings && !this.user?.settings?.bin) {
                        return this.$t('user.requisites-desc.person')
                    } else {
                        return ''
                    }
                }
            }
        },
        components: {
            Form,
            Input,
            Select,
            DataList,
            ChangeEmailModal,
            ChangePasswordModal,
            ConfirmSentModal,
            AddressModal,
            RemoveContactModal,
            ImportApiKey,
            NotificationSettings
        },
        created() {
            this.perPage = this.$libs.pagination.itemsPerPage
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('settings.profile.title'))

            this.user_id = this.$store.state.user.id

            this.getUser()
            this.checkApiAcces()

            this.canSignFromCompany = this.$store.state.user.settings.canSignFromCompany

            this.$emit('updateBalance')
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('settings.profile.title'))
            },
            async onTypeChange(){
                this.responseAwait = true
                let url = process.env.VUE_APP_API_URL + `/users/change-type`
                let changed = await axios.post(url, {
                    type: this.userTypeModel
                },{
                    withCredentials: true
                })
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log('changeType', response)
                    let r = response.data
                    this.responseAwait = false
                    if(r.result === 'SUCCESS') {
                        this.$store.state.user.settings.organizationType = this.userTypeModel
                        this.$store.commit('saveUser')
                        this.$emit('updateOrganizationType')
                    }
                })
            },
            getRoleById(roleId) {
                let url = process.env.VUE_APP_API_URL + '/roles/' + roleId

                axios.get(url)
                .then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        if(r.data) {
                            this.role = r.data.title in this.roles ? this.roles[r.data.title] : this.roles.empty
                        } else {
                            this.role = this.roles.empty
                        }
                    } else {
                        
                    }
                })

            },
            getTypes() {  
                let arr = []
                let url = process.env.VUE_APP_API_URL + '/users/types'
                
                axios.get(url, { 
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        for(let i in r.data) {
                            arr.push({id: r.data[i].id, text: this.translateTypes[r.data[i].text]})
                        }
                        this.userTypes = arr
                    }
                })  
            },
            getType() {
                let url = process.env.VUE_APP_API_URL + '/users/type'

                axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        if(r.data[0]) this.userTypeModel = r.data[0].value
                    }
                })
            },
            getUser() {
                let url = process.env.VUE_APP_API_URL + '/users/profile'
                axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        let { login, role_id, kbes = [], bics = [], settings = {} } = r.data

                        this.user = r.data

                        if(!this.user.password) {
                            this.showChangePassword = true
                            this.hasPassword = false
                        } else {
                            this.hasPassword = true
                        }

                        this.firstName = settings.firstName
                        this.lastName = settings.lastName
                        this.patronymic = settings.patronymic
                        this.id_no = settings.iinBin
                        this.phone = settings.phone
                        this.address = settings.address
                        this.email = settings.email
                        this.companyIdNo = settings.bin
                        this.getTypes()
                        this.getType()
                        this.companyTitle = settings.organization
                        this.canSignAuthorize = settings.canSignAuthorize

                        this.iban = settings.iban ?? ''
                        this.bank_bic = settings.bank_bic ?? ''
                        this.kbe = settings.kbe ?? ''
                        this.knp = settings.knp ?? ''

                        this.addressId = settings.address_id
                        this.$store.state.user.settings.address_id = settings.address_id

                        this.$store.state.user.settings.email = settings.email

                        this.contacts = settings.contacts

                        if(settings.organizationType=='-1')
                        {
                            this.userTypeModel = this.$t('user.user-types.null')
                        }
                        else if (settings.organizationType=='1')
                        {
                            this.userTypeModel = this.$t('user.user-types.assosiaction-property-owners')
                        }
                        else if(settings.organizationType=='2') {
                            this.userTypeModel = this.$t('user.user-types.sole-proprietor')
                        }

                        // this.emailTip = settings
                        if(settings.email.length == 0 && !settings.validEmailConfirm) {
                            this.emailTip.type = 'no-email'
                            this.emailTip.text = this.$t('settings.profile.email-tip.no-email')
                            this.emailTip.icon = 'exclamation-triangle text-warning'
                        } else if(settings.email.length > 0 && (!settings.validEmail || settings.validEmailConfirm == 1)) {
                            this.emailTip.type = 'confirmed'
                            this.emailTip.text = this.$t('settings.profile.email-tip.email-confirmed')
                            this.emailTip.icon = 'check-circle text-success'
                            this.$store.state.user.settings.validEmail = settings.validEmail
                            this.$store.state.user.settings.validEmailConfirm = settings.validEmailConfirm
                        } else if(settings.validEmailConfirm == 0) {
                            this.emailTip.type = 'waiting'
                            this.emailTip.text = this.$t('settings.profile.email-tip.confirm-email')
                            this.emailTip.icon = 'exclamation-triangle-fill text-warning'
                            this.$store.state.user.settings.validEmail = settings.validEmail
                            this.$store.state.user.settings.validEmailConfirm = settings.validEmailConfirm
                        }
                        this.$store.commit('saveUser')

                        if(settings.validEmail) {
                            this.emailConfirm.email = settings.validEmail
                            this.emailConfirm.status = settings.validEmailConfirm
                        }

                        this.kbes = []

                        for(let i in kbes) {
                            this.kbes.push({
                                id: parseInt(kbes[i].code),
                                text: kbes[i].code,
                            })
                        }

                        this.bics = []

                        for(let i in bics) {
                            this.bics.push({
                                id: bics[i].bic
                            })
                        }

                        this.bics = this.bics.map(bic => ({ id: bic.id, text: this.$t(`user.bank-bic-titles.title-${bic.id}`) }))

                        this.getRoleById(role_id)
                        this.getSigners()
                        this.getEmployees()
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.get-user-error'),
                            type: 'error'
                        })
                    }
                })
            },
            onMainSubmit() {
                this.updateUser()
                this.updateRecipient()
            },
            onBankDetailsSubmit() {
                let bankDetails = {
                    iban: this.iban, 
                    bank_bic: this.bank_bic,
                    kbe: this.kbe,
                    knp: this.knp
                }
                this.updateUserBankDetails(bankDetails)
            },
            onSubmitNewSigner() {
                this.addSigner()
            },
            addressOnChange(address) {
                if(address.completed) {
                    this.address = address
                } else {
                    this.address = {}
                }
            },
            updateUserBankDetails(bankDetails = {}) {
                let url = process.env.VUE_APP_API_URL + '/users/profile'
                let updated = {
                    password: 0,
                    iban: bankDetails.iban,
                    bank_bic: bankDetails.bank_bic,
                    kbe: bankDetails.kbe,
                    knp: bankDetails.knp
                }
                axios.post(url, updated, {
                    withCredentials: true
                })
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.update-user-success'),
                            type: 'success'
                        })

                        this.getUser()
                    } else {
                        if(r.message == 'Указан неверный IBAN') {
                            this.$notify({
                                title: this.$t('notify.types.error'),
                                text: this.$t('settings.profile.notify.wrong-iban'),
                                type: 'error'
                            })
                            this.getUser()
                        }
                        else {
                            this.$notify({
                                title: this.$t('notify.types.error'),
                                text: this.$t('settings.profile.notify.update-user-error'),
                                type: 'error'
                            })
                        }
                    }
                })
            },
            updateUser() {
                let url = process.env.VUE_APP_API_URL + '/users/profile'

                let updated = {
                    password: 0,
                    phone: this.phone,
                    addressId: this.addressId
                }
                axios.post(url, updated, {
                    withCredentials: true
                })
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.update-user-success'),
                            type: 'success'
                        })

                        this.getUser()
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.update-user-error'),
                            type: 'error'
                        })
                    }
                })
            },
            updateRecipient() {
                let updated = {
                    // lastName: this.lastName,
                    // firstName: this.firstName,
                    // patronymic: this.patronymic,
                    phone: this.phone,
                    address: this.address,
                    creator: this.user_id,
                    id_num: this.id_no,
                    companyIdNum: this.companyIdNo
                }

                let url = process.env.VUE_APP_API_URL + '/recipients/update'

                axios.post(url, updated, {
                    withCredentials: true
                })
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        // update state settings
                        for(let i in updated) {
                            if(process.env.VUE_APP_DEBUG) console.log('updated settings', i, updated[i])
                            this.$store.state.user.settings[i] = updated[i]
                        }

                        this.$store.commit('saveUser')
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.update-recipient-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getEmployees() {
                if(!this.canSignAuthorize) return

                let url = process.env.VUE_APP_API_URL + '/users/employees'

                await axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(process.env.VUE_APP_DEBUG) console.log('getEmployees', r)
                    
                    if(r.result === 'SUCCESS') {
                        this.employees = r.data
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.get-employees-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async addSigner() {
                if(!this.canSignAuthorize) return

                let url = process.env.VUE_APP_API_URL + '/users/signers/add'

                await axios.post(url, {
                    idNum: this.newSignerIdNum
                }, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.getSigners()
                        this.getEmployees()

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.add-signer-success'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.add-signer-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async removeSigner(idNum, link) {
                if(!this.canSignAuthorize) return

                let url = process.env.VUE_APP_API_URL + link

                await axios.post(url, {
                    idNum: idNum
                }, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.getSigners()
                        this.getEmployees()

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.remove-signer-success'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.remove-signer-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getSigners() {
                if(!this.canSignAuthorize) return

                let url = process.env.VUE_APP_API_URL + '/users/signers'

                await axios.get(url, {
                    params: {
                        page: this.currentPage,
                        limit: this.perPage
                    },
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.signers = r.data
                        this.totalItems = r.total

                        for(let i in this.signers) {
                            this.signers[i].class = 'row-disabled'
                            
                            for(let b in this.signers[i].buttons ?? []) {
                                // translate buttons
                                if(this.signers[i].buttons[b].text in this.buttons) {
                                    this.signers[i].buttons[b].text = this.buttons[this.signers[i].buttons[b].text]
                                }
                            }
                        }
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('settings.profile.notify.get-signers-error'),
                            type: 'error'
                        })
                    }
                })
            },
            clickChangeEmail() {
                if(this.emailConfirm.status == 0) {
                    this.showConfirmSentModal()
                } else {
                    this.showChangeEmailModal()
                }
            },
            showConfirmSentModal() {
                this.$store.commit('modalShow', { id: 'confirmSentModal'})
            },
            showChangeEmailModal() {
                this.$store.commit('modalShow', { id: 'changeEmailModal'})
            },
            changedEmail() {
                this.$refs.changeEmailModal.close()
                this.$refs.changeEmailModal.clear()
                this.getUser()
            },
            showChangePasswordModal() {
                this.$store.commit('modalShow', { id: 'changePasswordModal'})
            },
            showAddressModal() {
                this.$store.commit('modalShow', { id: 'addressModal'})
            },
            changedPassword() {
                this.$refs.changePasswordModal.close()
            },
            update(options) {
                let { page = 0, rowsPerPage = 0 } = options

                if(page) this.currentPage = page
                if(rowsPerPage) this.perPage = rowsPerPage

                this.getSigners()
            },
            handleButtonAction(action, link = false, item = false) {
                if(action == 'remove') {
                    this.removeSigner(item.idNum ?? '', link)
                }
            },
            addressSaved(a) {
                this.addressOnChange(a)
                this.onMainSubmit()
                this.getUser()
                this.$emit('getUnreadNotices')
            },
            showRemoveContactModal(contactId, key, value) {
                this.$refs.removeContactModal.clear()
                
                this.$refs.removeContactModal.contactId = contactId
                this.$refs.removeContactModal.key = key
                this.$refs.removeContactModal.value = value

                this.$store.commit('modalShow', { id: 'RemoveContactModal'})
            },
            contactRemoved() {
                this.getUser()
            },
            async checkApiAcces() {
                let url = process.env.VUE_APP_API_URL + '/import/check-access'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.apiAccess = true
                    } else {
                        this.apiAccess = false
                    }
                })
            }
        }
    }
</script>

<style scoped>
.contact-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0 .5em 0 0;
    color: #000000;
    transition: .25s;
    -o-transition: .25s;
    -moz-transition: .25s;
    -webkit-transition: .25s;
}
.contact-item:hover {
    display: flex;
    align-items: center;
    padding: 0;
    color: #a6a0fb;
}
.contact-address,
.contact-email,
.contact-phone {
    width: 540px;
    max-width: 100%;
    line-height: 1.25;
}
</style>