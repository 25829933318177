<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content text-bg-light">
                <div class="modal-header m-3">
                    <div v-html="header"></div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body m-3">
                    <div v-html="body"></div>
                </div>
                <div class="modal-footer m-3">
                    <button class="btn btn-warning" data-bs-dismiss="modal" @click="reset()">{{ $t('claim-editor.reset') }}</button>
                    <button class="btn btn-secondary ms-3" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'resetTextModal'
    }),
    props: {
        header: {
            type: String,
            default: ''
        },
        body: {
            type: String,
            default: ''
        }
    },
    methods: {
        reset() {
            this.$emit('reset')
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

.modal-body {
    margin-right: 2rem;
}

.btn-primary {
    width: 140px;
}

.bi-exclamation-triangle {
    color: #8f82d8;
}

</style>