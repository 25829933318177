<template>
    <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t('settings.profile.remove-contact-modal-text', { key: keyText, value: value }) }}</p>
                </div>
                <div class="modal-footer">
                    <button
                        class="btn btn-danger"
                        v-html="btnText"
                        :class="{disabled:loading}"
                        @click="remove"
                    ></button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="handleClose">{{ $t('close')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RemoveContactModal',
    data: () => ({
        id: 'RemoveContactModal',
        loading: false,
        contactId: null,
        key: '',
        value: ''
    }),
    computed: {
        btnText() {
            if(this.loading) return '<span class="loading"></span>'
            return this.$t('settings.profile.delete')
        },
        keyText() {
            if(this.key == 'address') return this.addressText
            if(this.key == 'email') return this.emailText
            if(this.key == 'phone') return this.phoneText

            return ''
        },
        addressText() {
            return this.$t('settings.profile.address')
        },
        emailText() {
            return this.$t('settings.profile.email')
        },
        phoneText() {
            return this.$t('settings.profile.phone')
        }
    },
    methods: {
        async remove() {
            if(!this.contactId || !this.key || !this.value) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('settings.profile.notify.contact-removed-incorrect-data'),
                    type: 'error'
                })

                this.close()    

                return
            }

            this.loading = true

            let r = await this.$api.removeRecipentContact(this.key, this.contactId)

            this.loading = false

            if(process.env.VUE_APP_DEBUG) console.log('remove', r)

            if(r.result === 'SUCCESS') {
                this.$notify({
                    title: this.$t('notify.types.success'),
                    text: this.$t('settings.profile.notify.contact-removed-success'),
                    type: 'success'
                })

                this.$emit('removed')
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('settings.profile.notify.contact-removed-error'),
                    type: 'error'
                })

                this.$emit('not-removed')
            }

            this.close()
        },
        handleClose() {
            this.$emit('closed')
        },
        clear() {
            this.loading = false
            this.contactId = null
            this.key = ''
            this.value = ''
        },
        close() {
            this.clear()
            this.$refs.close.click()
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

</style>