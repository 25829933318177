<template>
    <div>
        <i class="bi" :class="{'bi-star': !isActive(), 'bi-star-fill': isActive()}"></i>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Number,
            default: 0
        }
    },
    methods: {
        isActive() {
            return this.active == 1
        }
    }
}
</script>