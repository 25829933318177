<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h3>Список получателей</h3>
                    <button ref="closeButton" class="d-none" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    {{ addressId }}
                    <DataList
                        ref='debtRecipientsDatalist'
                        class="mb-3"
                        :columns="columns"
                        :data="recipients"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :sort-by="sortBy"
                        :sort-type="sortType"
                        :total="recipients.length"
                        :enableSearch="false"
                        @row-clicked="recipientClicked($event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataList from '../cabinet/DataList.vue'

export default {
    data:() => ({
        modalId: 'debtRecipientsModal',
        recipients: [],
        currentPage: 1,
        perPage: 5,
        sortBy: 'created',
        sortType: 'desc'
    }),
    props: {
        addressId: {
            type: Number,
            default: 0
        }
    },
    computed: {
        columns: {
            get() {
                return [
                    { value: 'recipientTitle', text: 'Наименование' }
                ]
            }
        }
    },
    components: {
        DataList
    },
    methods: {
        async getRecipients() {
            if(!this.addressId) return

            let r = await this.$api.addressRecipients(this.addressId)

            if(r.result === 'SUCCESS') {
                this.recipients = r.data
            }
        },
        recipientClicked(data) {
            this.$emit('recipientClicked', data.id)
            this.handleClose()
        },
        handleClose() {
            this.$refs.closeButton.click()
        }
    }
}

</script>