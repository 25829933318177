<template>
    <div id="content" class="col p-3">
        <form @submit.prevent="updateUser">
            <div class="mb-3">
                <label for="firstName" class="form-label">{{ $t('user.firstName') }}</label>
                <input type="text" class="form-control" v-model="firstName" :placeholder="$t('user.firstName-placeholder')">
            </div>
            <div class="mb-3">
                <label for="lastName" class="form-label">{{ $t('user.lastName') }}</label>
                <input type="text" class="form-control" v-model="lastName" :placeholder="$t('user.lastName-placeholder')">
            </div>
            <div class="mb-3">
                <label for="login" class="form-label">{{ $t('user.email') }}</label>
                <input type="text" class="form-control" v-model="login" :placeholder="$t('user.email-placeholder')">
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">{{ $t('user.password') }}</label>
                <input type="password" class="form-control" v-model="password" :placeholder="$t('user.password-placeholder')" disabled>
            </div>
            <div class="mb-3">
                <label for="role" class="form-label">{{ $t('user.role') }}</label>
                <select class="form-control" v-model="role">
                    <option v-for="option in roles" :value="option.id">
                        {{ option.title }}
                    </option>
                </select>
            </div>
            <div class="btn-group my-3" role="group">
                <button type="submit" class="btn btn-primary">Сохранить</button>
                <button type="button" @click="deleteUser(user_id)" class="btn btn-danger">Удалить</button>
            </div>
        </form>
        <PermissionsList
            :user_id="getUserId()"
            :success_msg="$t('user.permissions_success_msg')"
            :error_msg="$t('user.permissions_error_msg')"
        />
    </div>
</template>

<script>
    import Panel from './cabinet/Panel.vue'
    import PermissionsList from 't3m-permissions/components/List.vue'

    export default {
        data: () => ({
            title: 'Пользователь',
            user_id: null,
            firstName: '',
            lastName: '',
            login: '',
            password: '',
            role: 1,
            roles: [
                { id: 1, title: 'Администратор' },
                { id: 2, title: 'Пользователь' }
            ]
        }),
        components: {
            Panel,
            PermissionsList
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', title + ' #' + user_id)

            this.user_id = this.$route.params.id
            this.getUser(this.user_id)
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', title + ' #' + user_id)
            },
            getUserId() {
                return this.$route.params.id
            },
            getUser(id) {
                const url = process.env.VUE_APP_API_URL + '/users/'

                fetch(url + id, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(process.env.VUE_APP_DEBUG) console.log('user data', r.data)
                    if(r.result === 'SUCCESS') {
                        this.firstName = r.data.settings.firstName
                        this.lastName = r.data.settings.lastName
                        this.login = r.data.login
                        this.role = r.data.role_id

                        this.$notify({
                            title: 'Успех',
                            text: 'Данные пользователя получены',
                            type: 'success'
                        })
                    } else {
                        this.users = []
                    }
                })
            },
            updateUser() {
                const url = process.env.VUE_APP_API_URL + '/users/'

                fetch(url + this.user_id, {
                    method: 'POST',
                    body: { login: this.login, role: this.role },
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: 'Успех',
                            text: 'Пользователь успешно обновлен',
                            type: 'success'
                        })
                        this.getUser(this.user_id)
                    }
                })
            },
            deleteUser(id) {
                const url = process.env.VUE_APP_API_URL + '/users/'

                fetch(url + id, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(r.result === 'SUCCESS') {
                        this.$router.push('/users')
                    }
                    if(process.env.VUE_APP_DEBUG) console.log('result', r)
                })
            }
        }
    }
</script>

<style scoped>

    #app {
        display: flex;
        height: 100vh;
    }
    #content {
        overflow-y: auto;
    }
</style>