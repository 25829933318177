<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="article-section">
                <AmBreadcrumbs
                    :showCurrentCrumb="true"
                />
                <h1 class="text-uppercase">{{ title }}</h1>
                <div class="content mt-5" v-html="content"></div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";


export default {
    name: 'Article',
    data: () => ({
        loading: false,
        slug: '',
        title: '',
        content: '',
        date: ''
    }),
    components: {
        Header,
        Footer,
        Fancybox
    },
    methods: {
        async get() {
            if(this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/articles/' + this.slug

            await axios.get(url, {
                params: { 
                    language: this.$i18n.locale
                }
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.title = r.data.title
                    this.content = r.data.content
                    this.date = r.data.date

                    document.title = this.title + ''

                    if(r.data.description) {
                        this.createMeta(r.data.description)
                    }

                    this.setBreadcrumb()
                } else {
                    this.$router.push(`/${this.$i18n.locale}/articles`)
                }
            })
            this.loading = false

            this.initializeFancybox()
        },
        initializeFancybox() {
            Fancybox.bind('.content img', {
                Toolbar: false,
                contentClick: false
            })
        },
        createMeta(content) {
            this.$emit('createMeta', content)
        },
        setBreadcrumb() {
            this.$breadcrumbs.value[1].label = this.title
        }
    },
    created() {
        this.slug = this.$route.params.slug
    },
    mounted() {
        this.get()
    }
}

</script>

<style scoped>
.container-fluid {
    background-color: #ffffff;
}
</style>

<style>
    section {
        padding: 2em 1em;
    }

    .article-section {
        padding-top: 140px;
        margin-bottom: 3em;
        min-height: 70vh;
    }

    .content h1,
    .content h2,
    .content h3,
    .content h4,
    .content h5,
    .content h6 {
        font-weight: 600;
    }

    .figure-img {
        width: 600px;
        max-width: 80vw;
    }

    blockquote {
        font-size: 1.4em;
        margin: 50px auto;
        font-style: italic;
        color: #555555;
        padding: 1.2em 30px 1.2em 75px;
        border-left: 8px solid #7d7d7d ;
        line-height: 1.6;
        position: relative;
        background: #efefef;
    }

    blockquote::before {
        content: "\201C";
        color:#7d7d7d;
        font-size: 4em;
        position: absolute;
        left: 10px;
        top: -10px;
    }

    blockquote::after {
        content: '';
    }

    blockquote span {
        display: block;
        color:#7d7d7d;
        font-style: normal;
        font-weight: bold;
        margin-top: 1em;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>