<template>
    <div id="content" class="col">
        <div class="row">
            <div class="col-md-5">
                <Form redirect="/recipients" />
            </div>
        </div>
    </div>
</template>

<script>
    import Form from './Form.vue'

    export default {
        data: () => ({}),
        components: {
            Form
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('recipients.new.title'))
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('recipients.new.title'))
            }
        }
    }
</script>