<template>
    <button
        type="button"
        class="w-100 btn btn-lg btn-primary mt-auto"
        :class="{ disabled:loading }"
        @click="handleClick"
    >
        <span class="loading d-flex" v-if="loading"></span>
        {{ text }}
    </button>

    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" v-if="!amount">
        <div class="modal-dialog modal-dialog-centered">
            <div v-if="requestedEnterpriseRate != true" class="modal-content text-bg-light">
                <div class="modal-header d-flex justify-content-between align-items-center">
                    <h4 class="modal-title mx-auto mb-0">{{ $t('rates.enterprise.request-sent-title') }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                </div>
                <div class="modal-body">
                    <h5>{{ $t('rates.enterprise.request-sent-text') }}</h5>
                </div>
            </div>
            <div v-else class="modal-content text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h4 class="modal-title mx-auto mb-0">{{ $t('rates.request.error-title') }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                </div>
                <div class="modal-body">
                    <h5>{{ $t('rates.request.error-text') }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from 'vee-validate'
import Input from './form/Input.vue'

export default {
    components: { Form, Input },
    data: () => ({
        modalId: 'ratesEnterpriseModal',
        loading: false,
        text: '',
        totalAmount: '',
        selected: null,
        requestedEnterpriseRate: false
    }),
    props: {
        btnText: {
            type: String,
            default: 'Кнопка'
        },
        loadingStatus: {
            type: Boolean,
            dafault: false
        }
    },
    watch: {
        loading: {
            handler(status) {
                if(status) {
                    this.text = ''
                } else {
                    this.text = this.btnText
                }
            }
        }
    },
    created() {
        if(this.btnText) this.text = this.btnText
        this.getCookies()
    },
    methods: {
        handleClick() {
            this.selected = null
            this.$store.commit('modalShow', { id: this.modalId })
            this.request()
        },
        async request() {
            await this.getCookies()
            let url = process.env.VUE_APP_API_URL + '/notifications/telegram'
            let body = {
                user: this.$store.state.user,
                type: "requestEnterpriseRate"
            }

            axios.post(url, body, {
                    withCredentials: true
            }).then((response) => {
                let r = response.data
            })
        },
        async getCookies() {
            let url = process.env.VUE_APP_API_URL + '/notifications/get-rates-status'

            axios.get(url, {
                    withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result == 'SUCCESS') {
                    this.requestedEnterpriseRate = r.data.requestedEnterpriseRate
                }
            })
        },
        selectOption(value) {
            this.selected = value
        }
    }
}
</script>

<style scoped>

.modal-header .btn-close {
    position: absolute;
    right: 27px;
    top: 27px;
}

.card {
    cursor: pointer;
    transition: transform 0.2s;
}

.card:hover {
    box-shadow: 0 0 15px #a6a0fb;
}

.card.active {
    outline: solid #a6a0fb;
}

</style>