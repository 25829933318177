<template>
    <div class="col-md-8">
        <Form class="shadow p-3 mb-5 rounded">
            <h4>{{ $t('settings.profile.notification.title') }}</h4>
            <table class="notification-settings table table-striped">
                <thead>
                    <tr>
                        <td>{{ $t('settings.profile.notification.th-1') }}</td>
                        <td>{{ $t('settings.profile.notification.th-2') }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="event in events">
                        <td>
                            {{ eventsTypes[event.type] ?? eventsTypes['default'] }}
                        </td>
                        <td>
                            <input
                            class="form-check-input"
                            type="checkbox"
                            name="subscribe"
                            value="newsletter"
                            v-model="event.notificationMethods.find(method => method.type === 'email').isActive"
                            @click="toggleEvent(event, 'email')"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Form>
    </div>

</template>

<script>
export default {
  data() {
    return {
      checkboxChecked: false,
      events: []
    }
  },
    computed: {
            eventsTypes: {
                get() {
                    //Locality TODO
                    return {
                        'claimStatus': this.$t('settings.profile.notification.claim-status'),
                        'default': this.$t('settings.profile.notification.default')
                    }
                }
            }
    },
  methods: {
    toggleCheckbox() {
      this.checkboxChecked = !this.checkboxChecked;
    },
    getEvents() {
        let url = process.env.VUE_APP_API_URL + '/notification-settings/get-user-settings'

        axios.get(url, {
            params: {
                userId: this.$store.state.user.id
            },
            withCredentials: true
        })
        .then((response) => {
            if(process.env.VUE_APP_DEBUG) console.log(response)

            let r = response.data

            if(r.result === 'SUCCESS') {
                if(r.data != []) {
                    this.events = r.data
                }
            } 
        })
    },
    toggleEvent(event, type) {
        let url = process.env.VUE_APP_API_URL + '/notification-settings/set-user-settings'
        let isActive, eventType

        event.notificationMethods.forEach(method => {
            if (method.type === type) {
                method.isActive = !method.isActive;
                isActive = method.isActive
                eventType = event.type
            }
        });

        axios.post(url, {
            userId: this.$store.state.user.id,
            event: eventType,
            notificationMethod: type,
            isActive: isActive
        }, {
            withCredentials: true
        })
        .then((response) => {
            if(process.env.VUE_APP_DEBUG) console.log(response)

            let r = response.data

            if(r.result === 'SUCCESS') {

            }
        })
    }
  },
  mounted() {
    this.getEvents()
  }
};
</script>

<style scoped>
.method:hover {
    color: #6b6981;
}

.method:hover input {
    background-color:rgb(213, 196, 240);
}

.notification-settings {
    width: 100%;
    border-collapse: collapse;
}
.notification-settings th, .notification-settings td {
    padding: 8px;
    text-align: center;
}
.notification-settings th:first-child,
.notification-settings td:first-child {
    width: 50%;
    text-align: left;
}

.form-check-input[type="checkbox"]:checked {
    background-color: #29205d;
    border-color: #29205d;
}

.form-check-input[type="checkbox"]:checked:focus {
    background-color: #29205d;
    border-color: #29205d;
}

table {
    font-size:medium;
}

</style>