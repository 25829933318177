<template>
    <div class="col-md-8">
        <Form class="shadow p-3 mb-5 rounded">
            <div class="row my-3">
                <div class="col-md-12">
                    <h5>{{ $t('import-api.header') }}</h5>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-md-9 mb-3 d-flex align-items-center">
                    <input v-if="apiKey" class="border border-secondary-subtle p-2 rounded w-100" @focus="$event.target.select()" ref="apiKey" readonly :value="apiKey" />
                    <span v-else class="text-secondary">{{ $t('import-api.no-keys') }}</span>
                </div>
                <div v-if="apiKey" class="col-md-3 mb-3">
                    <button type="button" class="btn btn-outline-secondary" @click="copyKey">
                        <i :class="{ 'bi bi-clipboard fs-5': !copied, 'bi bi-clipboard-check fs-5 text-success': copied }"></i>
                    </button>
                </div>
            </div>
            <div v-if="signRights" class="row my-3">
                <div class="col-md-6">
                    <button type="button" class="btn btn-primary" @click="generateApiKey">{{ apiKey ? $t('import-api.btn-regenerate') : $t('import-api.btn-generate') }}</button>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import { useClipboard } from '@vueuse/core'
    import { ref } from 'vue'

    export default {
        data: () => ({
            apiKey: '',
            copied: false
        }),
        mounted() {
            this.getApiKey()
        },
        computed: {
            signRights() {
                return this.$store.state.user ? this.$store.state.user.settings.canSignFromCompany : false
            }
        },
        methods: {
            async onSubmit() {
                await this.generateApiKey()
            },
            async getApiKey() {
                let url = process.env.VUE_APP_API_URL + '/import/get-api-key'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    console.log('r', r)
                    if(r.result === 'SUCCESS') {
                        this.apiKey = r.data
                    } else {
                        this.apiKey = ''
                    }
                })
            },
            async generateApiKey() {
                let url = process.env.VUE_APP_API_URL + '/import/authorize'

                await axios.post(url, {}, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.getApiKey()
                    } else {
                        this.$notify({
                            title: 'ERROR',
                            text: r.message,
                            type: 'error'
                        })
                    }
                })
            },
            copyKey() {
                const { copy } = useClipboard()
                
                copy(this.apiKey)

                this.copied = true

                setTimeout(() => {
                    this.copied = false
                }, 2000)
            }
        },
        setup() {
            const text = ref('')

            return { text }
        }
    }
</script>

<style scoped>

.bi-clipboard {
    transition: 500ms;
    -moz-transition: 500ms;
    -o-transition: 500ms;
    -webkit-transition: 500ms;
}

.bi-clipboard-check {
    transition: 500ms;
    -moz-transition: 500ms;
    -o-transition: 500ms;
    -webkit-transition: 500ms;
}

.btn-outline-secondary:active {
    background-color: #fafafa !important;
    border-color: #dcdaff !important;
}

</style>