<template>
    <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref="closeSetPasswordBtn"></button>
                </div>
                <div class="modal-body">
                    <Form class="p-3" @submit="onSubmit">
                        <Input
                            v-model="password"
                            name="password"
                            type="password"
                            :label="$t('user.password')"
                            :placeholder="$t('user.password-placeholder')"
                            :validations="['required', 'minLength(3)', 'maxLength(100)']"
                            :tip="passwordTip"
                            :tip-type="passwordTipType"
                        />
                        <Input
                            v-model="confirmPassword"
                            name="confirmPassword"
                            ref="confirmPassword"
                            type="password"
                            :label="$t('user.confirm-password')"
                            :placeholder="$t('user.confirm-password-placeholder')"
                            :validations="['required', 'minLength(3)', 'maxLength(100)']"
                            :tip="confirmPasswordTip"
                            :tip-type="confirmPasswordTipType"
                        />
                        <button type="submit" class="d-none" ref="submitBtn"></button>
                    </Form>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="submitForm">{{ $t('settings.profile.set-password')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Form } from 'vee-validate'
import Input from '../form/Input.vue'

export default {
    name: 'setPasswordModal',
    data: () => ({
        id: 'setPasswordModal',
        password: '',
        confirmPassword: '',
        passwordTip: '',
        passwordTipType: 'regular',
        confirmPasswordTip: '',
        confirmPasswordTipType: 'regular'
    }),
    components: {
        Form,
        Input
    },
    watch: {
        confirmPassword(val) {
            if(this.password && val && val !== this.password) {
                this.confirmPasswordTip = 'Должны совпадать'
                this.confirmPasswordTipType = 'danger'
                this.$refs.confirmPassword.validateClass = 'danger'
            } else {
                this.confirmPasswordTip = ''
                this.confirmPasswordTipType = 'regular'
                this.$refs.confirmPassword.validateClass = 'regular'
            }
        }
    },
    methods: {
        submitForm() {
            this.$refs.submitBtn.click()
        },
        async onSubmit() {
            if(this.password !== this.confirmPassword) return

            let url = process.env.VUE_APP_API_URL + '/users/change-password'

            let body = {
                password: this.password
            }

            await axios.post(url, body, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data

                // return r.result === 'SUCCESS' ? true : false
                if(r.result === 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('settings.profile.notify.change-password-success'),
                        type: 'success'
                    })
    
                    this.$emit('passwordSet')
                } else {
                    this.clear()

                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('settings.profile.notify.change-password-error') + ' ' + r.message,
                        type: 'error'
                    })
                }
            })
        },
        clear() {
            this.password = ''
            this.confirmPassword = ''
            this.passwordTip = ''
            this.passwordTipType = 'regular'
            this.confirmPasswordTip = ''
            this.confirmPasswordTipType = 'regular'
        },
        close() {
            this.$refs.closeSetPasswordBtn.click()
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

</style>