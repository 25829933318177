<template>
    <div class="container-fluid">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-3">
                <div class="bg-light text-black text-center p-5 rounded-3">
                    <form v-show="!reset" class="mb-3">
                        <div class="mb-3">
                            <label for="email" class="form-label float-start">Электронная почта</label>
                            <input type="text" class="form-control" v-model="email">
                        </div>
                        <button type="button" @click="resetPasswordMail" class="btn btn-primary">Выслать письмо</button>
                    </form>
                    <form v-show="reset">
                        <div class="mb-3">
                            <label for="password" class="form-label float-start">Новый пароль</label>
                            <input type="password" class="form-control" v-model="password">
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label float-start">Повторите новый пароль</label>
                            <input type="password" class="form-control" v-model="password2">
                        </div>
                        <button type="button" @click="resetPassword" class="btn btn-primary mx-auto">Сохранить</button>
                    </form>
                    <router-link to="/login/main" class="link-secondary text-decoration-none">Назад</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        reset: false,
        email: '',
        password: '',
        password2: '',
        user_id: null
    }),
    mounted() {
        let hash = this.$route.params.hash ? this.$route.params.hash.split('@') : ''
        let user_id = hash[0] ?? ''
        let secret = hash[1] ?? ''

        if(user_id && secret) {
            this.resetPasswordHash(user_id, secret)
        } else {
            this.reset = false
            this.email = ''
            this.password = ''
            this.password2 =''
        }
    },
    methods: {
        resetPasswordMail() {
            if(this.email) {
                const url = process.env.VUE_APP_API_URL + '/reset-password-mail/?email=' + this.email
                
                fetch(url, {
                    method: 'GET',
                    // body: { email: this.email },
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: 'Успех',
                            text: r.message,
                            type: r.result.toLowerCase()
                        })

                        this.email = ''
                    } else {
                        this.email = ''
                        this.$notify({
                            title: 'Ошибка',
                            text: r.message,
                            type: r.result.toLowerCase()
                        })
                    }
                })
            }
        },
        resetPasswordHash(user_id, secret) {
            const url = process.env.VUE_APP_API_URL + '/reset-password-hash/?user_id=' + user_id + '&secret=' + secret

            fetch(url, {
                method: 'GET',
                // body: { user_id: user_id, secret: secret },
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response).then(async res => {
                let r = await res.json()

                if(r.result === 'SUCCESS') {
                    this.reset = true
                    this.user_id = user_id
                    this.$notify({
                        title: 'Успех',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                } else {
                    this.$router.push('/reset-password')
                    this.$notify({
                        title: 'Ошибка',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                }
            })
        },
        resetPassword(){
            if(this.password !== this.password2) {
                this.$notify({
                    title: 'Ошибка',
                    text: 'Пароли не совпадают',
                    type: 'error'
                })

                this.password = ''
                this.password2 = ''

                return
            }

            const url = process.env.VUE_APP_API_URL + '/reset-password/?user_id=' + this.user_id + '&password=' + this.password

            fetch(url, {
                method: 'GET',
                // body: { user_id: this.user_id, password: this.password },
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response).then(async res => {
                let r = await res.json()

                if(r.result === 'SUCCESS') {
                    this.$router.push('/login/main')
                    this.$notify({
                        title: 'Успех',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                } else {
                    this.$router.push('/reset-password')
                    this.$notify({
                        title: 'Ошибка',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

    #app > .container-fluid {
        height: 100vh;
        background-image: url('../assets/img/legal-papers-sign06.png');
        background-size: cover;
    }

</style>