const mrp = parseInt(process.env.VUE_APP_MRP_AMOUNT) ?? 3450
const legalServices = parseFloat(process.env.VUE_APP_LEGAL_SERVICES_PERCENT_AMOUNT / 100) ?? 0.1 // 10%
const deliveryServices = parseInt(process.env.VUE_APP_DELIVERY_SERVICES_AMOUNT) ?? 3000

function getLegalServicesAmount(amount) {
    return parseFloat(amount) * parseFloat(legalServices)
}

function getPrivateBailiffAmount(total) {
    // < 60
    if(total < (mrp * 60)) return total * 0.25
    // 60 - 300
    if(total >= (mrp * 60) && total < (mrp * 300)) return total * 0.2
    // 300 - 1000
    if(total >= (mrp * 300) && total < (mrp * 1000)) return total * 0.15
    // 1000 - 5000
    if(total >= (mrp * 1000) && total < (mrp * 5000)) return total * 0.1
    // 5000 - 10000
    if(total >= (mrp * 5000) && total < (mrp * 10000)) return total * 0.08
    // 10000 - 20000
    if(total >= (mrp * 10000) && total < (mrp * 20000)) return total * 0.05
    
    // > 20000
    return total * 0.03
}

function getTotalPerson(amount) {
    let total = amount

    total += getLegalServicesAmount(amount)

    // гос. пошлина
    total += amount * 0.01 // для ФЛ (1% от amount)

    // delivery service
    total += deliveryServices

    // private bailiff
    total += getPrivateBailiffAmount(total)

    return total
}

function getTotalCompany(amount) {
    let total = amount

    total += getLegalServicesAmount(amount)

    // гос. пошлина
    total += amount * 0.03 // для ЮЛ (3% от amount)

    // delivery service
    total += deliveryServices

    // private bailiff
    total += getPrivateBailiffAmount(total)

    return total
}

function getTotal(amount, isPerson) {
    let total = parseFloat(amount)

    total += getLegalServicesAmount(amount)

    // гос. пошлина
    if(isPerson) {
        total += amount * 0.01 // для ФЛ (1% от amount)
    } else {
        total += amount * 0.03 // для ЮЛ (3% от amount)
    }

    // delivery service
    total += deliveryServices

    // private bailiff
    total += getPrivateBailiffAmount(total)

    if(Number.isInteger(total)) {
        return Math.round(total)
    } else {
        return Math.round(total.toFixed(2))
    }

    // return total
}

module.exports = {
    deliveryServices,
    getTotalPerson,
    getTotalCompany,
    getTotal,
    legalServices,
    mrp
}