<template>
    <div class="modal fade" ref="modalRef" :id="modal.id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog" :class="modal.size ? 'modal-'+ modal.size : ''">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" :id="modal.id + 'Title'">{{ modal.title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-html="modal.body"></div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('close') }}</button> -->
                    <button type="button" class="btn" v-for="btn in modal.buttons" :class="btn.class" :data-bs-dismiss="btn.dbd" @click="btnClick(btn.click)">{{ btn.title }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from './recipients/Form.vue'

export default {
    name: 'Modal',
    data: () => ({
        modal: {}
    }),
    methods: {
        btnClick(r) {
            if(!r) return

            let isLink = r.charAt(0) == '/' ? true : false

            this.$store.commit('hideModal')

            if(isLink) {
                this.$router.push(r)
            } else {
                this.$store.commit('setModalResult', r)
            }
        }
    },
    mounted() {
        this.modal = this.$store.state.modal
    }
}

</script>

<style scoped>

.button {
    background: none;
    color: #3a3077;
    padding: .5rem;
    border: none;
    cursor: pointer;
}

.button:active {
    background: #3a3077;
    color: white;
}

.modal-content {
    min-width: 600px;
    max-width: fit-content;
    align-self: center;
    border-radius: 0;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 5rem;
    right: -50px;
}

.modal-footer, .modal-header {
    border: none;
}

.modal-body {
    margin-right: 2rem;
}

</style>