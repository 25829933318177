<template>
    <button
        type="button"
        class="btn btn-primary"
        :class="{ disabled:loading }"
        @click="handleClick"
    >
        <span class="loading d-flex" v-if="loading"></span>
        {{ text }}
    </button>

    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" v-if="!amount">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <Form class="p-3" @submit="onSubmit">
                    <div class="modal-header d-flex align-items-center">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <h5>Введите сумму пополнения</h5>
                        <Input
                            v-model="totalAmount"
                            name="amount"
                            :placeholder="'Сумма'"
                            :validations="['required', 'min(1)']"
                        />
                        <div class="row">
                            <div class="col">
                                <div class="card" :class="{ 'active': selected === 'airba-pay' }" @click="selectOption('airba-pay')">
                                    <img class="card-img-top" src="../assets/img/partners/airba.png" alt="Card image cap">
                                </div>
                            </div>
                            <div class="col">
                                <div class="card" :class="{ 'active': selected === 'freedom-pay' }" @click="selectOption('freedom-pay')">
                                    <img class="card-img-top" src="../assets/img/partners/freedompay.png" alt="Card image cap">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="!selected">Пополнить</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from 'vee-validate'
import Input from './form/Input.vue'

export default {
    components: { Form, Input },
    data: () => ({
        modalId: 'payButtonModal',
        loading: false,
        text: '',
        totalAmount: '',
        selected: null
    }),
    props: {
        amount: {
            type: Number,
            default: null
        },
        btnText: {
            type: String,
            default: 'Кнопка'
        },
        loadingStatus: {
            type: Boolean,
            dafault: false
        }
    },
    watch: {
        loading: {
            handler(status) {
                if(status) {
                    this.text = ''
                } else {
                    this.text = this.btnText
                }
            }
        }
    },
    created() {
        if(this.btnText) this.text = this.btnText
        if(this.amount) this.totalAmount = this.amount
    },
    methods: {
        handleClick() {
            this.selected = null
            if(!this.totalAmount || this.totalAmount == 0) {
                this.$store.commit('modalShow', { id: this.modalId })
            } else {
                this.pay()
            }
        },
        onSubmit() {
            this.pay()
        },
        async pay() {
            if(this.loading) return
            this.loading = true

            let r = await this.$api.replenishAccount(this.totalAmount, this.selected)

            this.loading = false

            this.totalAmount = this.amount || 0
            this.$refs.closeModal.click()

            if(r.result == 'SUCCESS') {
                window.open(r.data.href || r.data.redirect_url, '_blank')
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('pay-button.pay-error'),
                    type: 'error'
                })
            }

            if(process.env.VUE_APP_DEBUG) console.log('pay', r)
        },
        selectOption(value) {
            this.selected = value
        }
    }
}
</script>

<style scoped>

.card {
    cursor: pointer;
    transition: transform 0.2s;
}

.card:hover {
    box-shadow: 0 0 15px #a6a0fb;
}

.card.active {
    outline: solid #a6a0fb;
}

</style>