<template>
    <div id="content" class="col" v-if="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-show="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="notice-header">
            <span class="notice-title"></span>
            <span class="badge rounded-pill status-text" :class="`status-text-` + this.notice.status">{{ this.noticeStatus }}</span>
        </div>
        <div class="row">
            <div class="col-md-7">
                <DebtText
                    v-show="!loading"
                    ref="debtText"
                    :address="address"
                    :senderData="senderData"
                    :amount_owed="amount_owed"
                    :recipient="recipientData"
                />
                <div class="shadow p-3 m-5 rounded text-center" v-if="loading">
                    <div class="text-center m-5">
                        <span class="loading"></span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="shadow p-3">
                    <ul class="nav nav-tabs" id="noticeTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="actions-tab" data-bs-toggle="tab" data-bs-target="#actions-tab-pane" type="button" role="tab" aria-controls="actions-tab-pane" aria-selected="true">{{ $t('claim.tab-actions') }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history-tab-pane" type="button" role="tab" aria-controls="history-tab-pane" aria-selected="false" disabled>{{ $t('claim.tab-history') }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="noticeTabContent">
                        <div class="tab-pane fade show active" id="actions-tab-pane" role="tabpanel" aria-labelledby="actions-tab" tabindex="0">
                            <PayBlock
                                ref="noticePayBlock"
                                :html-text="$t('claim.pay-block.html-text', { amount: getAmountBefore(), amount_after: getAmountAfter() })"
                                :show-button="this.notice.canPay"
                                @clicked="handlePayBtnClick"
                            />
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    
                                </div>
                            </div>

                            <button v-if="own_notice" v-show="notice.recipient" class="btn btn-secondary w-100 mb-2" :class="{ disabled: !notice.recipient}" @click="createClaim">Создать претензию</button>
                            <button v-if="own_notice" class="btn btn-danger w-100 mb-2" @click="showDeleteModal">Удалить уведомление</button>

                            <button
                                v-if="!own_notice"
                                type="button"
                                class="btn btn-secondary w-100 mb-2"
                                @click="phoneCall"
                            >
                                <i class="bi bi-telephone-fill me-2"></i>
                                {{ $t('messages.phone-call') }}
                            </button>
                            <button
                                v-if="!own_notice"
                                type="button"
                                class="btn btn-secondary w-100 mb-2"
                                @click="writeWhatsapp"
                            >
                                <i class="bi bi-whatsapp me-2"></i>
                                {{ $t('messages.whatsapp') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmDeleteModal :header="deleteModalHeader" :body="deleteModalBody" @deleteObj="deleteNotice" />
</template>

<script>
    import { statuses } from '../../../libs/fullstack'
    import PayBlock from '../cabinet/PayBlock.vue';
    import ConfirmDeleteModal from '../recipients/ConfirmDeleteModal.vue'
    import DebtText from './DebtText.vue'

    export default {
        data: () => ({
            notice: {},
            occurrence_date: '',
            address: '',
            shortAddress: '',
            address_object: {},
            amount_owed: '',
            sender: '',
            senderData: '',
            senderPhone: '',
            send: true,
            text: '',
            own_notice: false,
            recipientExists: false,
            mounting: false
        }),
        computed: {
            deleteModalHeader() {
                return 'Удалить'
            },
            deleteModalBody() {
                return 'Вы уверены что хотите удалить уведомление о задолженности?'
            },
            noticeStatus() {
                if(this.notice.status === statuses.draft) return 'Черновик'
                if(this.notice.status === statuses.unread) return 'Отправлена'
                if(this.notice.status === statuses.read) return 'Прочитана'
            },
            recipientData() {
                return this.notice?.recipientData
            }
        },
        components: {
            DebtText,
            ConfirmDeleteModal,
            PayBlock
        },
        mounted() {
            // this.checkOsi()
            this.$emit('setPageTitle', this.$t('debt-notice.notice.title'))

            this.getNotice()
            // this.getSender()
            this.$refs.debtText.generateText()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('debt-notice.notice.title'))
            },
            async checkOsi() {
                let isOsi = await this.$api.checkOsiStatus()
                if(!isOsi) {
                    this.$router.replace('/dashboard')
                } else{
                    this.mounting = false
                }
            },
            async getNotice() {
                let url = process.env.VUE_APP_API_URL + `/debt-notice/${this.$route.params.slug}`

                await axios.get(url, {
                    withCredentials: true
                }).then(async response => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.notice = r.data

                        this.own_notice = r.data.own_notice

                        if(r.data.status === statuses.draft) return this.$router.push(`/debt-notice/${r.data.slug}/edit`)
        
                        this.amount_owed = r.data.amount_owed
                        this.occurrence_date = r.data.occurrence_date
                        let addrObj = {
                            country: 1,
                            address_id: r.data.address_id,
                            recipient_id: r.data.recipient_id,
                            locality_id: r.data.locality_id,
                            street: r.data.street,
                            buildingNumber: r.data.building_number,
                            apartment: r.data.apartment,
                            extra: r.data.extra ?? '',
                            final_locality: r.data.final_locality
                        }
                        this.address_object = addrObj
        
                        await this.getAddressString(addrObj)
                        await this.getSender()
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message,
                            type: 'error'
                        })

                        this.$router.push('/debt-notice')
                    }
                })
            },
            async getAddressString(obj) {
                let url = process.env.VUE_APP_API_URL + '/location/address-str'

                await axios.get(url, {
                    params: {
                        address: obj
                    },
                    withCredentials: true
                }).then(response => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.address = r.data.full
                        this.shortAddress = r.data.short
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: r.message,
                            type: 'error'
                        })
                    }
                })
            },
            async getSender() {
                let r = await this.$api.getNoticeSenderData(this.notice.sender)

                if(r.result === 'SUCCESS') {
                    this.sender = r.data.id
                    this.senderData = r.data
                    this.senderPhone = r.data.phone.replace(/\D/g, '')
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }

                // await axios.get(url, {
                //     withCredentials: true
                // })
                // .then((response) => {
                //     let r = response.data
                    
                //     this.sender = r.data.id
                //     this.senderData = r.data
                //     this.senderPhone = r.data.phone.replace(/\D/g, '')
                // })

                this.$refs.debtText.generateText()
            },
            showDeleteModal() {
                this.$store.commit('modalShow', { id: 'confirmDeleteModal' })
            },
            async deleteNotice() {
                let r = await this.$api.deleteDebtNotice(this.notice.id)

                if(r.result === 'SUCCESS') {
                    this.$router.push('/debt-notice/outbox')
                }
            },
            async createClaim() {
                if(!this.notice.recipient) return
                if(!this.notice.address_id) return

                let r = await this.addRelation(this.$store.state.user.id, this.notice.recipient)

                await this.$api.addAddressVisibility(this.notice.address_id, this.$store.state.user.id)

                if(!r) return
                this.$router.push(`/claims/new?recipient=${this.notice.recipient}&essence=osi&amount_owed=${this.amount_owed}`)
            },
            async addRelation(userId, recipientId) {
                let data = {
                    userId: userId,
                    recipientId: recipientId
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/relations'

                let result = await axios.post(url, data, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            },
            phoneCall() {
                if(this.senderPhone) window.open(`tel:+${this.senderPhone}`, '_blank')
            },
            writeWhatsapp() {
                if(this.senderPhone) window.open(`https://wa.me/${this.senderPhone}`, '_blank')
            },
            getAmountBefore() {
                if(!this.notice.amount_owed) return 0
                return Number(this.notice.amount_owed).toLocaleString('kk-KZ')
            },
            getAmountAfter() {
                let fiz = false
                fiz = !this.notice.recipientData?.company_id_num

                return this.$libs.debt.getTotal(this.notice.amount_owed, fiz).toLocaleString('ru-KZ')
            },
            async handlePayBtnClick() {
                if(this.$refs.noticePayBlock.loading) return
                this.$refs.noticePayBlock.setLoading(true)

                let r = await this.$api.noticePayment(this.notice?.slug)

                if(r.data && r.data.redirect_url) {
                    window.open(r.data.redirect_url, 'noreferrer')
                }

                this.$refs.noticePayBlock.setLoading(false)
            }
        }
    }

</script>

<style scoped>

#noticeTab .nav-item {
    flex: auto;
}

#noticeTab .nav-item .nav-link {
    width: 100%;
    padding: 1em;
    color: #261D58;
    background-color: #E1E1EC;
    border: none;
}

#noticeTab .nav-item:first-child .nav-link {
    border-radius: 1em 0 0 1em;
}

#noticeTab .nav-item:last-child .nav-link {
    border-radius: 0 1em 1em 0;
}

#noticeTab .nav-item .nav-link.active {
    width: 100%;
    color: #FFFFFF;
    background-color: #BFBCF4;
}

.notice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}

.notice-title {
    margin-right: 1rem;
    font-size: 1.75rem;
    font-weight: 500;
}

.status-text {
    color: #ffffff;
    background-color: #8f82d8;
    font-size: .85rem;
}

.status-text-0 {
    background-color: #cccccc;
}

.status-text-1 {
    background-color: #8f82d8;
}

.status-text-2 {
    background-color: #55cb85;
}

</style>