<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="mb-0">{{ $t('settings.profile.change-address') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeBtn" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <Location ref="location" @changed="addressOnChange" />
                    <span class="text-danger" v-show="errorMsg">{{ errorMsg }}</span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mt-3 ms-3" @click="submit">{{ $t('settings.profile.save') }}</button>
                    <button class="btn btn-secondary mt-3 ms-3" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Location from '../cabinet/Location.vue'

export default {
    components: {
        Location
    },
    data: () => ({
        id: 'addressModal',
        loading: false,
        key: 'address',
        value: {},
        errorMsg: ''
    }),
    props: {
        recipientId: {
            type: Number,
            default: null
        },
        obj: {
            type: Object
        },
        blank: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async addressOnChange(address) {
            this.value = address
        },
        async submit() {
            if(this.loading) return

            if(!this.value.completed) {
                this.errorMsg = 'Заполните адрес'
                return
            }

            this.loading = true

            if(!this.blank) {
                await this.$refs.location.addStreet()

                let r = await this.$api.updateRecipientContact(this.recipientId, this.key, this.value)

                this.loading = false

                if(r.result == 'SUCCESS') {
                    this.$emit('saved', this.value)
                    this.close()
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('settings.profile.notify.get-user-error'),
                        type: 'error'
                    })
                }
            } else {
                this.$refs.location.addStreet()
                this.$emit('saved', this.value)
                this.loading = false
                this.close()
            }
        },
        close() {
            this.$refs.closeBtn.click()
        }
    }
}
</script>