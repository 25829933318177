<template>
    <div class="lang-switcher me-3">
        <ul class="d-flex ps-0 ps-sm-auto">
            <li
                class="lang-item"
                v-for="lang in langs"
                :class="{active:lang.active}"
                :key="`locale-${lang.key}`"
                @click="changeLang(lang.key)"
            >{{ lang.title }}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    data: () => ({
        current: '',
        langs: []
    }),
    computed: {
        _langs: {
            get() {
                return {
                    ru: { key: 'ru', title: this.$t('lang-switcher.ru'), active: false },
                    kk: { key: 'kk', title: this.$t('lang-switcher.kk'), active: false },
                    en: { key: 'en', title: this.$t('lang-switcher.en'), active: false }
                }
            }
        }
    },
    methods: {
        getCurrent() {
            let l = this.$libs.cookies.getCookie('lang') || this.$i18n.locale
            this.$libs.cookies.setCookie('lang', l)
            this.$i18n.locale = l
            this.current = this.$i18n.locale
        },
        getLangs() {
            this.langs = this._langs

            // set current active
            for(let i in this.langs) {
                this.langs[i].active = this.current == i
            }
        },
        changeLang(lang) {
            this.$i18n.locale = lang
            this.current = this.$i18n.locale

            this.$libs.cookies.setCookie('lang', this.$i18n.locale)

            for(let i in this.langs) {
                this.langs[i].active = this.current == this.langs[i].key
            }

            this.getLangs()

            this.$emit('changed', lang)

            window.location = window.location
        }
    },
    mounted() {
        this.getCurrent()
        this.getLangs()
    }
}

</script>

<style scoped>

ul {
    list-style-type: none;
    margin: auto;
}

.lang-switcher {
    color: #ffffff;
}

.cabinet-header .lang-switcher {
    color: #000000;
}

.lang-item {
    padding: 0 .5rem;
    border-left: 1px solid #ffffff;
    cursor: pointer;
    line-height: 14px;
}

.lang-item:first-child {
    border-left: none;
}

.cabinet-header .lang-item {
    border-color: #000000;
}

.lang-item.active {
    font-weight: bold;
}

.cabinet-header .lang-item.active {
    color: #3a3077;
}

</style>