<template>
    <Header :inner-page="true" />
    <div class="container" id="container">
        <div class="row justify-content-center my-5">
            <div class="col-md-8 text-center">
                <div class="logo">
                    <router-link to="/" class="navbrand">
                        <img src="../assets/img/logo.png" class="img-fluid" :alt="$t('header.logo_alt')">
                    </router-link>
                </div>
                <h3>{{ $t('not-found.heading') }}</h3>
                <p>{{ $t('not-found.desc') }}</p>
                <router-link class="btn btn-link" to="/">{{ $t('not-found.goto-home') }}</router-link>
                <!-- <button type="button" class="btn btn-link" @click="goBack">Go Back</button> -->
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './home/Header.vue'
import Footer from './home/Footer.vue'

export default {
    components: {
        Header,
        Footer
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}

</script>

<style scoped>

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }

    .logo {
        max-width: 120px;
        margin: 1rem auto;
    }

    #container {
        padding-top: 140px;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

</style>