<template>
    <button
        type="button"
        class="btn btn-primary"
        :class="{ disabled:loading }"
        @click="handleClick"
    >
        <span class="loading d-flex" v-if="loading"></span>
        {{ $t('rates.request.bill-button-text') }}
    </button>

    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" v-if="!amount" ref="modal">
        <div class="modal-dialog modal-dialog-centered">
            <div v-if="requestedBill != true" class="modal-content text-bg-light">
                <Form @submit="onSubmit">
                    <div class="modal-header d-flex align-items-center">
                        <h4 class="modal-title mx-auto mb-0">{{ $t('rates.request.bill-amount') }}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <Input
                            v-model="totalAmount"
                            name="amount"
                            :placeholder="$t('rates.request.amount')"
                            :validations="['required', 'min(1)']"
                            mask="0000-0000"
                            mask-tokens="0:digit"
                        />
                        <div class="form-check mt-3">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="needDocuments"
                                v-model="needDocuments"
                            />
                            <label class="form-check-label" for="needDocuments">
                                {{ $t('rates.request.closing-documents') }}
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="totalAmount<=0">{{ $t('rates.request.get-bill') }}</button>
                    </div>
                </Form>
            </div>
            <div v-else class="modal-content text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h4 class="modal-title mx-auto mb-0">{{ $t('rates.request.error-title') }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
                </div>
                <div class="modal-body">
                    <h5>{{ $t('rates.request.error-text') }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from 'vee-validate'
import Input from './form/Input.vue'

export default {
    components: { Form, Input },
    data: () => ({
        modalId: 'ratesBillModal',
        loading: false,
        text: '',
        totalAmount: '',
        needDocuments: false,
        selected: null,
        requestedBill: false
    }),
    props: {
        amount: {
            type: Number,
            default: null
        },
        btnText: {
            type: String,
            default: 'Кнопка'
        },
        loadingStatus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        loading: {
            handler(status) {
                if(status) {
                    this.text = ''
                }
            }
        }
    },
    created() {
        if(this.amount) this.totalAmount = this.amount
        this.getCookies()
    },
    methods: {
        async handleClick() {
            this.selected = null
            if(!this.totalAmount || this.totalAmount == 0) {
                await this.getCookies()
                this.$store.commit('modalShow', { id: this.modalId })
            } else {
                this.request()
            }
        },
        onSubmit() {
            this.request()
        },
        async request() {
            await this.getCookies()

            let url = process.env.VUE_APP_API_URL + '/notifications/telegram'
            let body = {
                user: this.$store.state.user,
                type: "requestBill",
                amount: this.totalAmount,
                needDocuments: this.needDocuments
            }

            axios.post(url, body, {
                    withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result == 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: 'Запрос успешно отправлен!',
                        type: 'success'
                    })
                    
                    this.closeModal()
                } else {
                }
            })
            
        },
        async getCookies() {
            let url = process.env.VUE_APP_API_URL + '/notifications/get-rates-status'

            axios.get(url, {
                    withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result == 'SUCCESS') {
                    this.requestedBill = r.data.requestedBill
                }
            })
        },
        closeModal() {
            const modalElement = this.$refs.modal

            if (modalElement) {
                const modal = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement)
                modal.hide()
                this.resetModalState()
            }
        },
        resetModalState() {
            this.totalAmount = ''
            this.needDocuments = false
            this.selected = null
        }
    }
}
</script>

<style scoped>

.modal-header .btn-close {
    position: absolute;
    right: 15px;
}


.card {
    cursor: pointer;
    transition: transform 0.2s;
}

.card:hover {
    box-shadow: 0 0 15px #a6a0fb;
}

.card.active {
    outline: solid #a6a0fb;
}

</style>