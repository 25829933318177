import { createRouter, createWebHistory } from 'vue-router'
import { createI18n } from 'vue-i18n'

import Home from './components/Home.vue'
import AboutProject from './components/home/AboutProject.vue'
import Articles from './components/home/Articles.vue'
import Article from './components/home/Article.vue'
import RatePlans from './components/home/RatePlans.vue'
import Faq from './components/home/Faq.vue'
import Question from './components/home/Question.vue'
import Instruction from './components/home/Instruction.vue'
import InstructionPage from './components/home/InstructionPage.vue'
import Page from './components/Page.vue'
import Login from './components/Login.vue'
import LoginNca from './components/LoginNca.vue'
import LoginEdocument from './components/LoginEdocument.vue'
import Dashboard from './components/Dashboard.vue'
import Users from './components/Users.vue'
import User from './components/User.vue'
import Registration from './components/Registration.vue'
import Verify from './components/Verify.vue'
import ChangeEmail from './components/ChangeEmail.vue'
import ResetPassword from './components/ResetPassword.vue'
import ClaimsNew from './components/claims/New.vue'
import ClaimsImport from './components/claims/ClaimsImport.vue'
import Claim from './components/claims/Claim.vue'
import ClaimsInbox from './components/claims/Inbox.vue'
import ClaimsOutbox from './components/claims/Outbox.vue'
import ClaimShort from './components/claims/ClaimShort.vue'
import Edit from './components/claims/Edit.vue'
import PartnerLink from './components/PartnerLink.vue'
import PaymentSchedules from './components/payment-schedules/All.vue'
import PretrialAgreement from './components/claims/PretrialAgreement.vue'
import Profile from './components/settings/Profile.vue'
import Receipt from './components/Receipt.vue'
import Recipients from './components/recipients/All.vue'
import RecipientsNew from './components/recipients/New.vue'
import Recipient from './components/recipients/Recipient.vue'
import Rates from './components/settings/Rates.vue'
import TemplateEditor from './components/claims/TemplateEditor.vue'
import NotFound from './components/NotFound.vue'
import SavedImport from './components/claims/SavedImport.vue'
import DebtNoticeHome from './components/debt/DebtNoticeHome.vue'
import OutboxDebtNotice from './components/debt/Outbox.vue'
import InboxDebtNotice from './components/debt/Inbox.vue'
import NewDebtNotice from './components/debt/New.vue'
import EditDebtNotice from './components/debt/Edit.vue'
import Notice from './components/debt/Notice.vue'
import DebtImport from './components/debt/DebtImport.vue'
import LoginMain from './components/LoginMain.vue'

const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'ru',
    messages: {
        'ru': require('./common/locales/ru.json'),
        'en': require('./common/locales/en.json'),
        'kk': require('./common/locales/kk.json')
    }
})

const routes = [
    { path: '/', component: Home },
    { path: '/:lang', component: Home, meta: { breadcrumb: false } },
    // { path: '/ru', component: Home, meta: { breadcrumb: false } },
    // { path: '/kk', component: Home, meta: { breadcrumb: false } },
    // { path: '/en', component: Home, meta: { breadcrumb: false } },
    { path: '/login', component: Login },
    { path: '/login/main', component: LoginMain },
    { path: '/login/nca', component: LoginNca },
    { path: '/login/edocument', component: LoginEdocument },
    // { path: '/registration', component: Registration },
    { path: '/verify/:hash', component: Verify },
    { path: '/change-email/:hash', component: ChangeEmail },
    { path: '/reset-password', component: ResetPassword },
    { path: '/reset-password/:hash', component: ResetPassword },
    {
        path: '/:lang/about',
        name: 'AboutProject',
        component: AboutProject,
        meta: {
            breadcrumb: `О проекте`
        }
    },
    {
        path: '/:lang/articles',
        name: 'Articles',
        component: Articles,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('articles.title', language)

                return `${title}`
            }
        }
    },
    {
        path: '/:lang/articles/:slug',
        component: Article,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('articles.title', language)

                return `${title}`
            }
        }
    },
    {
        path: '/:lang/rate-plans',
        name: 'RatePlans',
        component: RatePlans,
        meta: {
            breadcrumb: `Тарифные планы`
        }
    },
    {
        path: '/:lang/faq',
        name: 'Faq',
        component: Faq,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('faq.title', language)

                return `${title}`
            }
        }
    },
    {
        path: '/:lang/faq/:slug',
        component: Question,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('faq.title', language)

                return `${title}`
            }
        }
    },
    {
        path: '/:lang/instruction',
        name: 'Instruction',
        component: Instruction,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('instruction.title', language)
                
                return `${title}`
            }
        }
    },
    {
        path: '/:lang/instruction/:slug',
        component: InstructionPage,
        meta: {
            breadcrumb(route, app) {
                let language = route.path.substring(1, 3)
                let title = i18n.global.t('instruction.title', language)
                
                return `${title}`
            }
        }
    },
    {
        path: '/r/:slug',
        component: Receipt
    },
    {
        path: '/cabinet',
        redirect: '/dashboard',
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Dashboard'
        }
    },
    {
        path: '/c/:number',
        component: ClaimShort
    },
    {
        path: '/claims',
        component: ClaimsInbox,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Претензии'
        },
        redirect: '/claims/inbox'
    },
    {
        path: '/claims/new',
        component: ClaimsNew,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Новая'
        }
    },
    {
        path: '/debt-notice',
        component: DebtNoticeHome,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Уведомления о задолженности'
        }
    },
    {
        path: '/debt-notice/outbox',
        component: OutboxDebtNotice,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Исходящие'
        }
    },
    {
        path: '/debt-notice/inbox',
        component: InboxDebtNotice,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Входящие'
        }
    },
    {
        path: '/debt-notice/new',
        component: NewDebtNotice,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Новое уведомление'
        }
    },
    {
        path: '/debt-notice/import',
        component: DebtImport,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Импорт уведомлений'
        }
    },
    {
        path: '/debt-notice/:slug',
        component: Notice,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return `Уведомление о задолженности #${route.params.slug}`
            }
        }
    },
    {
        path: '/debt-notice/:slug/edit',
        component: EditDebtNotice,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return 'Редактировать'
            }
        }
    },
    {
        path: '/claims/import',
        component: ClaimsImport,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Импорт из Excel'
        }
    },
    {
        path: '/claims/:slug',
        component: Claim,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return `Претензия #${route.params.slug}`
            }
        }
    },
    {
        path: '/claims/:slug/edit',
        component: Edit,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return `Редактировать`
            }
        }
    },
    {
        path: '/claims/:slug/template-editor',
        component: TemplateEditor,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/claims/:slug/pretrial-agreement',
        component: PretrialAgreement,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return `Сообщение`
            }
        }
    },
    {
        path: '/claims/inbox',
        component: ClaimsInbox,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Входящие'
        }
    },
    {
        path: '/claims/outbox',
        component: ClaimsOutbox,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb: 'Исходящие'
        }
    },
    {
        path: '/p/:p',
        component: PartnerLink
    },
    {
        path: '/payment-schedules',
        component: PaymentSchedules,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/recipients',
        component: Recipients,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/recipients/add',
        component: RecipientsNew,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/recipients/:id',
        component: Recipient,
        meta: {
            requiresAuth: true,
            isCabinet: true,
            breadcrumb(route, app) {
                return `Получатель #${route.params.id}`
            }
        }
    },
    {
        path: '/users',
        component: Users,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/users/:id',
        component: User,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/settings/profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/settings/rates',
        component: Rates,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/template-editor',
        component: TemplateEditor,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/saved-import',
        component: SavedImport,
        meta: {
            requiresAuth: true,
            isCabinet: true
        }
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
    },
    { 
        path: '/:lang/:slug',
        name: 'Page',
        component: Page,
        props: true,
        meta: {
            breadcrumb(route, app){
                return 'Страница'
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router