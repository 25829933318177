<template>
    <div id="content" class="col p-3">
        <div class="row">
            <div class="col-md-3">
                <router-link to="/login" class="nav-link" aria-current="page">Логин</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let hash = this.$route.params.hash.split('@')
        let user_id = hash[0] ?? ''
        let secret = hash[1] ?? ''

        if(user_id && secret) {
            this.verify(user_id, secret)
        } else {
            this.$router.push('/login')
        }
    },
    methods: {
        verify(user_id, secret) {
            let url = process.env.VUE_APP_API_URL + '/verify/?h=' + user_id + '@' + secret

            fetch(url, {
                method: 'GET',
                // body: { user_id: user_id, secret: secret },
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response).then(async res => {
                let r = await res.json()

                if(r.result === 'SUCCESS') {
                    this.$router.push('/login')
                    this.$notify({
                        title: 'Успех',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                } else {
                    this.$router.push('/login')
                    this.$notify({
                        title: 'Ошибка',
                        text: r.message,
                        type: r.result.toLowerCase()
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
    #app {
        display: flex;
        height: 100vh;
    }
    #content {
        overflow-y: auto;
    }
</style>