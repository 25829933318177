<template>
    <Header :inner-page="true" />
    <div class="container-fluid">
        <div class="container">
            <section class="instruction-section">
                <AmBreadcrumbs :showCurrentCrumb="true" />
                <h1>{{ title }}</h1>
                <div class="content mt-5" v-html="instruction"></div>
            </section>
        </div>
    </div>
    <Footer />
</template>

<script>

import Header from './Header.vue'
import Footer from './Footer.vue'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";


export default {
    name: 'InstructionPage',
    data: () => ({
        loading: false,
        slug: '',
        instruction: '',
        title: ''
    }),
    components: {
        Header,
        Footer,
        Fancybox
    },
    methods: {
        async get() {
            if (this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/instruction/' + this.slug

            await axios.get(url, {
                params: { 
                    language: this.$i18n.locale
                }
            }).then((response) => {
                let r = response.data

                if (r.result === 'SUCCESS') {
                    this.title = r.data.title
                    this.instruction = r.data.instruction.replaceAll('http://localhost:8055/assets/', `${process.env.VUE_APP_API_URL}/instruction/images/`)
                    document.title = this.title + ''

                    this.createMeta(r.data.title)

                    this.setBreadcrumb()
                } else {
                    this.$router.push(`/${this.$i18n.locale}/instruction`)
                }
            })
            this.loading = false
        },
        createMeta(content) {
            this.$emit('createMeta', content)
        },
        setBreadcrumb() {
            this.$breadcrumbs.value[1].label = this.title
        }
    },
    created() {
        this.slug = this.$route.params.slug
    },
    mounted() {
        this.get()
    }
}

</script>

<style scoped>
.container-fluid {
    background-color: #ffffff;
}

section {
    padding: 2em 1em;
}

.instruction-section {
    padding-top: 140px;
    margin-bottom: 3em;
    min-height: 70vh;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    font-weight: 600;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}
</style>

<style>
.flex{
    display: flex;
}

.flex-1{
    flex: 1;
}

.instruction-section .width-100 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.instruction-section .width-75 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.instruction-section .width-50 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.numbered-list {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
}

.numbered-list>li {
    counter-increment: item;
    margin-bottom: 10px;
}

.numbered-list>li::before {
    content: counter(item) ". ";
    font-weight: bold;
}
</style>