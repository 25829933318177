const { convert } = require('number-to-words-ru');
const { translate } = require('./locale');

const singleDigitNumbersInKazakh = {
    1: 'бір',
    2: 'екі',
    3: 'үш',
    4: 'төрт',
    5: 'бес',
    6: 'алты',
    7: 'жеті',
    8: 'сегіз',
    9: 'тоғыз'
};

const doubleDigitNumbersInKazakh = {
    1: 'он',
    2: 'жиырма',
    3: 'отыз',
    4: 'қырық',
    5: 'елу',
    6: 'алпыс',
    7: 'жетпіс',
    8: 'сексен',
    9: 'тоқсан'
};

const tripleDigitNumberInKazakh = 'жүз'

const specialNumbersInKazakh = {
    1: 'мың',
    2: 'миллион',
    3: 'миллиард'
}

const months = [
    translate('months.jan', 'kk'),
    translate('months.feb', 'kk'),
    translate('months.mar', 'kk'),
    translate('months.apr', 'kk'),
    translate('months.may', 'kk'),
    translate('months.jun', 'kk'),
    translate('months.jul', 'kk'),
    translate('months.aug', 'kk'),
    translate('months.sep', 'kk'),
    translate('months.oct', 'kk'),
    translate('months.nov', 'kk'),
    translate('months.dec', 'kk')
]

/**
 * Returns cutted string by length, with text at the end
 * 
 * @param {String} str
 * @param {Number} length
 * @param {String} end
 * @returns {String}
 */
function cutStr(str, length = 25, end = '') {
    if(str.length > length) str = str.substring(0, length) + end

    return str
}

function extractContentFromHtml(html) {
    // Find start index and end index
    const startIndex = html.indexOf("<!-- content -->");
    const endIndex = html.indexOf("<!-- /content -->");

    // If found return text, else emptry str
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        return html.substring(startIndex + 16, endIndex).trim()
    } else {
        return ""
    }
}

function numberWithSpaces(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

function hideMiddleChars(str, start = 0, end = 0, exclude = []) {
    if(end === 0) end = str.length

    if(typeof str !== 'string') return ''

    let arr = str.split('')
    let middle = arr.slice(start, end)

    if(!Array.isArray(exclude)) exclude = [exclude]
    
    return arr.slice(0, start).join('') + middle.map(l => exclude.includes(l) ? l : '*').join('') + arr.slice(end, str.length).join('')
}

var transliterate = function(text) {
    text = text
    .replace(/\u0410/g, 'A')
    .replace(/\u0430/g, 'a')
    .replace(/\u0411/g, 'B')
    .replace(/\u0431/g, 'b')
    .replace(/\u0412/g, 'V')
    .replace(/\u0432/g, 'v')
    .replace(/\u0413/g, 'G')
    .replace(/\u0433/g, 'g')
    .replace(/\u0414/g, 'D')
    .replace(/\u0434/g, 'd')
    .replace(/\u0415/g, 'E')
    .replace(/\u0435/g, 'e')
    .replace(/\u0401/g, 'YO')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0416/g, 'ZH')
    .replace(/\u0436/g, 'zh')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0437/g, 'z')
    .replace(/\u0418/g, 'I')
    .replace(/\u0438/g, 'i')
    .replace(/\u0419/g, 'I')
    .replace(/\u0439/g, 'i')
    .replace(/\u041A/g, 'K')
    .replace(/\u043A/g, 'k')
    .replace(/\u041B/g, 'L')
    .replace(/\u043B/g, 'l')
    .replace(/\u041C/g, 'M')
    .replace(/\u043C/g, 'm')
    .replace(/\u041D/g, 'N')
    .replace(/\u043D/g, 'n')
    .replace(/\u041E/g, 'O')
    .replace(/\u043E/g, 'o')
    .replace(/\u041F/g, 'P')
    .replace(/\u043F/g, 'p')
    .replace(/\u0420/g, 'R')
    .replace(/\u0440/g, 'r')
    .replace(/\u0421/g, 'S')
    .replace(/\u0441/g, 's')
    .replace(/\u0422/g, 'T')
    .replace(/\u0442/g, 't')
    .replace(/\u0423/g, 'U')
    .replace(/\u0443/g, 'u')
    .replace(/\u0424/g, 'F')
    .replace(/\u0444/g, 'f')
    .replace(/\u0425/g, 'H')
    .replace(/\u0445/g, 'h')
    .replace(/\u0426/g, 'TS')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0427/g, 'CH')
    .replace(/\u0447/g, 'ch')
    .replace(/\u0428/g, 'SH')
    .replace(/\u0448/g, 'sh')
    .replace(/\u0429/g, 'SCH')
    .replace(/\u0449/g, 'sch')
    .replace(/\u042A/g, '')
    .replace(/\u044A/g, '')
    .replace(/\u042B/g, 'Y')
    .replace(/\u044B/g, 'y')
    .replace(/\u042C/g, '')
    .replace(/\u044C/g, '')
    .replace(/\u042D/g, 'E')
    .replace(/\u044D/g, 'e')
    .replace(/\u042E/g, 'YU')
    .replace(/\u044E/g, 'yu')
    .replace(/\u042F/g, 'Ya')
    .replace(/\u044F/g, 'ya')
    // kazakh language
    .replace(/\u04D8/g, 'A')
    .replace(/\u04D9/g, 'a')
    .replace(/\u0492/g, 'G')
    .replace(/\u0493/g, 'g')
    .replace(/\u049A/g, 'Q')
    .replace(/\u049B/g, 'q')
    .replace(/\u04A2/g, 'N')
    .replace(/\u04A3/g, 'n')
    .replace(/\u04E8/g, 'O')
    .replace(/\u04E9/g, 'o')
    .replace(/\u04B0/g, 'U')
    .replace(/\u04B1/g, 'u')
    .replace(/\u04AE/g, 'Y')
    .replace(/\u04AF/g, 'y')
    .replace(/\u04BA/g, 'H')
    .replace(/\u04BB/g, 'h')
    .replace(/\u0406/g, 'I')
    .replace(/\u0456/g, 'i')

    return text
}

function getAmountWords(amount) {
    let cfg = {
        currency: 'number',
        showNumberParts: {
            integer: true,
            fractional: false
        },
        showCurrency: {
            integer: false,
            fractional: false,
        }
    }

    return convert(amount, cfg).toLowerCase()
}

function getDueDateWords(number) {
    let cfg = {
        declension: 'genitive',
        currency: 'number',
        showNumberParts: {
            fractional: false
        },
        showCurrency: {
            integer: false,
            fractional: false,
        }
    }

    let toWords = convert(number, cfg).toLowerCase()

    return number + ' (' + toWords + ')'
}

function getSlug(str) {
    return transliterate(str
        .replaceAll(' ', '-')
        .replaceAll('?', '')
        .replaceAll(',', ''))
        .toLowerCase()
        .trim()
}

// useful when adding a zero to months and days
function addZeros(num, size = 0) {
    num = num.toString()

    while(num.length < size) num = '0' + num

    return num
}

function hideAddress(str) {
    return str.split(', ').map(i => {
        let s = Math.round(i.length / 3)
        return hideMiddleChars(i, 0, 0, ['/', '.', '1', '2', '3', '4', '5', '6', '7', '8', '9'])
    }).join(', ')
}

function hideEmail(str) {
    return hideMiddleChars(str, 2, -3)
}

function hidePhone(phone) {
    return hideMiddleChars(phone, 2, -2, ['(', ')', '-', ' '])
}

function shortenCompanyName(str) {
    str = str.toString()
        .replace(/товарищество с ограниченной ответственностью/ig, 'ТОО')
        .replace(/объединение собственников имущества/ig, 'ОСИ')
        .replace(/жауапкершілігі шектеулі серіктестігі/ig, 'ЖШС')
        .replace(/индивидуальный предприниматель/ig, 'ИП')

    return str
}

function numberToKazakhText(number) {
    number = parseInt(number)
    let text = ''
    let divisionIndicator = Math.floor(Math.log10(number)) % 3 + 1
    let numberString = String(number)
    let leftmostDigit
    let numThousandsDivisions = Math.floor(Math.log10(number) / 3)

    if(numThousandsDivisions>3)
        return ''

    for(numThousandsDivisions; numThousandsDivisions>=0; numThousandsDivisions--) {
        for(divisionIndicator; divisionIndicator>0; divisionIndicator--) {
            leftmostDigit = parseInt(numberString[0])
            numberString = numberString.substring(1)
            
            if(leftmostDigit!=0) {
                if(divisionIndicator == 3) {
                    let temporaryDigit = (leftmostDigit === 1) ? '' : (singleDigitNumbersInKazakh[leftmostDigit] + ' ')
                    text += temporaryDigit + tripleDigitNumberInKazakh + ' '
                }
                else if(divisionIndicator == 2) {
                    text += doubleDigitNumbersInKazakh[leftmostDigit] + ' '
                }
                else {
                    text += singleDigitNumbersInKazakh[leftmostDigit]
                }
            }
        }

        if(numThousandsDivisions > 0) {
            text += ' ' + specialNumbersInKazakh[numThousandsDivisions] + ' '
        }
        divisionIndicator = 3
    }

    while(text[text.length - 1] === ' ') {
        text = text.slice(0, -1);
    }

    return text
}

function dateToKazakhText(date) {
    let d = new Date(date)

    return `${d.getFullYear()} жылғы ${d.getDate()} ${months[d.getMonth()]}`
}


module.exports = {
    addZeros,
    cutStr,
    extractContentFromHtml,
    getDueDateWords,
    getAmountWords,
    getSlug,
    transliterate,
    hideAddress,
    hideEmail,
    hidePhone,
    shortenCompanyName,
    numberToKazakhText,
    dateToKazakhText
}