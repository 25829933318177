<template>
    <button
        type="button"
        class="btn btn-primary"
        :class="{ disabled:loading }"
        @click="openModal"
    >
        <span class="loading d-flex" v-if="loading"></span>
        {{ !loading ? btnText : '' }}
    </button>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="mb-0">{{ $t('settings.promocodes.modal-title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeBtn" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <Form class="p-3" @submit="onSubmit">
                        <Input
                            v-model="code"
                            name="code"
                            :label="'Промокод'"
                            :placeholder="'ABC123'"
                            :validations="['required', 'minLength(1)', 'maxLength(100)']"
                        />
                        <button type="submit" class="d-none" ref="submitBtn"></button>
                    </Form>
                    <span class="text-danger" v-show="errorMsg">{{ errorMsg }}</span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mt-3 ms-3" @click="submitForm">{{ $t('settings.promocodes.activate') }}</button>
                    <button class="btn btn-secondary mt-3 ms-3" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from 'vee-validate'
import Input from '../form/Input.vue'

export default {
    components: { Form, Input },
    data: () => ({
        id: 'promocodeModal',
        loading: false,
        code: '',
        tip: '',
        tipType: 'regular'
    }),
    props: {
        btnText: {
            type: String,
            default: 'Кнопка'
        }
    },
    computed: {
        notifyMessages() {
            return {
                not_found: this.$t('settings.promocodes.notify.not-found'),
                success: this.$t('settings.promocodes.notify.success'),
                not_active: this.$t('settings.promocodes.notify.not-active'),
                expired: this.$t('settings.promocodes.notify.expired'),
                topup_error: this.$t('settings.promocodes.notify.topup-error'),
                redeemed: this.$t('settings.promocodes.notify.redeemed'),
                other: this.$t('settings.promocodes.notify.other')
            }
        }
    },
    methods: {
        submitForm() {
            this.$refs.submitBtn.click()
        },
        async onSubmit() {
            if(this.loading) return

            if(!this.code) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('settings.promocodes.notify.expired'),
                    type: 'warning'
                })

                return
            }

            await this.submit()
        },
        async submit() {
            this.loading = true

            let r = await this.$api.redeemPromocode(this.code)

            this.loading = false

            if(process.env.VUE_APP_DEBUG) console.log('[PromocodeModal] submit', r)

            if(r.result == 'SUCCESS') {
                this.code = ''
                this.$emit('submitted')
                this.close()

                this.$notify({
                    title: this.$t('notify.types.success'),
                    text: this.notifyMessages[r.message] ?? this.notifyMessages['other'],
                    type: 'success'
                })
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.notifyMessages[r.message] ?? this.notifyMessages['other'],
                    type: 'error'
                })
            }
        },
        openModal() {
            this.$store.commit('modalShow', { id: this.id })
        },
        close() {
            this.$refs.closeBtn.click()
        }
    }
}
</script>