<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="my-3">
            <DataList
                ref="DataList"
                :columns="columns"
                :filter="filter"
                :data="list"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by="sortBy"
                :sort-type="sortType"
                :total="totalItems"
                @button-action="handleButtonAction"
                @row-clicked="handleRowClicked"
                @updated="update"
                @search="handleSearch"
                @filtered="handleFiltered"
            />
        </div>
    </div>
</template>

<script>
    import DataList from '../cabinet/DataList.vue'

    export default {
        name: 'Outbox',
        data: () => ({
            list: [],
            loading: false,
            currentPage: 1,
            perPage: 5,
            sortBy: 'created',
            sortType: 'desc',
            totalItems: 0,
            search: '',
            searchFields: ['title', 'amount_owed'],
            filter: {},
            filtered: {}
        }),
        computed: {
            columns: {
                get() {
                    return [
                        { value: "slug", text: this.$t('outbox.columns.slug') },
                        { value: "type", text: this.$t('outbox.columns.type') },
                        { value: "recipient", text: this.$t('outbox.columns.recipient'), sortable: true },
                        { value: "occurrence_date", text: this.$t('outbox.columns.occurrence_date'), sortable: true },
                        { value: "amount_owed", text: this.$t('outbox.columns.amount_owed'), sortable: true },
                        { value: "created", text: this.$t('outbox.columns.created'), sortable: true },
                        { value: "status", text: this.$t('outbox.columns.status'), sortable: true },
                        { value: "buttons", text: this.$t('outbox.columns.buttons') }
                    ]
                }
            },
            buttons: {
                get() {
                    return {
                        remove: this.$t('data-list.buttons.remove'),
                        mark: this.$t('data-list.buttons.mark'),
                        unmark: this.$t('data-list.buttons.unmark')
                    }
                }
            },
            statuses: {
                get() {
                    return {
                        'all': this.$t('claim.statuses.all'),
                        'draft': this.$t('claim.statuses.draft'),
                        'unread': this.$t('claim.statuses.unread'),
                        'read': this.$t('claim.statuses.read'),
                        'disputed': this.$t('claim.statuses.disputed'),
                        'declined': this.$t('claim.statuses.declined'),
                        'accepted': this.$t('claim.statuses.accepted'),
                        'withdrawn': this.$t('claim.statuses.withdrawn'),
                        'paid': this.$t('claim.statuses.paid'),
                        'scheduled': this.$t('claim.statuses.scheduled'),
                        'expired': this.$t('claim.statuses.expired'),
                        'unknown': this.$t('claim.statuses.unknown'),
                        'badge_draft': this.$t('claim.statuses.badge_draft'),
                        'badge_unread': this.$t('claim.statuses.badge_unread'),
                        'badge_read': this.$t('claim.statuses.badge_read'),
                        'badge_disputed': this.$t('claim.statuses.badge_disputed'),
                        'badge_declined': this.$t('claim.statuses.badge_declined'),
                        'badge_accepted': this.$t('claim.statuses.badge_accepted'),
                        'badge_withdrawn': this.$t('claim.statuses.badge_withdrawn'),
                        'badge_paid': this.$t('claim.statuses.badge_paid'),
                        'badge_scheduled': this.$t('claim.statuses.badge_scheduled'),
                        'badge_expired': this.$t('claim.statuses.badge_expired'),
                        'badge_unknown': this.$t('claim.statuses.badge_unknown')
                    }
                }
            },
            paid_deliveries: {
                get() {
                    return {
                        'all': this.$t('inbox.paid-delivery.all'),
                        'yes': this.$t('inbox.paid-delivery.yes'),
                        'no': this.$t('inbox.paid-delivery.no')
                        
                    }
                }
            }
        },
        components: {
            DataList
        },
        watch: {
            loading: {
                handler(val) {
                    this.$refs.DataList.loading = val
                }
            }
        },
        created() {
            this.perPage = this.$libs.pagination.itemsPerPage
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('outbox.title'))

            this.$emit('updateBalance')

            // set default filtered
            this.$refs.DataList.filtered.status = 'all'
            this.$refs.DataList.filtered.paid_delivery = 'all'
            this.getAll()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('outbox.title'))
            },
            update(options) {
                let { page = 0, rowsPerPage = 0, sortBy = 'created', sortType = 'desc' } = options

                if(page) this.currentPage = page
                if(rowsPerPage) this.perPage = rowsPerPage
                if(sortBy) this.sortBy = sortBy
                if(sortType) this.sortType = sortType

                this.getAll()
            },
            handleSearch(val) {
                this.search = val

                this.getAll()
            },
            async getAll() {
                if(this.loading) return
                this.loading = true

                let url = process.env.VUE_APP_API_URL + '/claims/outbox'

                let search = {}
                if(this.search) {
                    for(let i in this.searchFields) {
                        search[this.searchFields[i]] = this.search
                    }
                }

                let filtered = {}

                for(let i in this.filtered) {
                    if(!(i === 'status' && this.filtered[i] === 'all')) filtered[i] = this.filtered[i]
                }

                await axios.get(url, {
                    params: {
                        page: this.currentPage,
                        limit: this.perPage,
                        sortBy: this.sortBy,
                        sortType: this.sortType,
                        search: search,
                        filtered: filtered
                    },
                    withCredentials: true
                }).then((res) => {
                    this.list = []

                    let r = res.data
                    
                    if(r.result === 'SUCCESS') {
                        this.list = r.data
                        this.totalItems = r.total

                        this.filter = r.filter ?? {}

                        if(Object.keys(this.filter).length) {
                            this.filter = this.prepareFilter(this.filter)
                        }

                        for(let i in this.list) {
                            this.list[i].type = this.$t(`outbox.type-${this.list[i].type}`) || this.$t(`outbox.default-type`)
                            this.list[i].amount_owed = this.list[i].amount_owed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                            for(let b in this.list[i].buttons ?? []) {
                                // translate buttons
                                if(this.list[i].buttons[b].text in this.buttons) {
                                    this.list[i].buttons[b].text = this.buttons[this.list[i].buttons[b].text]
                                }
                            }
                            
                            // translate status
                            this.list[i].status = this.list[i].t_statusText in this.statuses ? this.statuses[this.list[i].t_statusText] : this.statuses.badge_unknown

                            this.list[i].class = 'row-regular'
                        }
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('outbox.notify.error'),
                            type: 'error'
                        })
                    }
                })

                this.loading = false
            },
            handleFiltered(filtered) {
                this.filtered = filtered

                this.getAll()
            },
            handleRowClicked(item) {
                if(this.loading) return

                if(item._link ?? false) {
                    this.$router.push(item._link)
                }
            },
            handleButtonAction(action, link = false) {
                if(action == 'mark' || action == 'unmark') {
                    this.markClaim(link)
                }
            },
            async markClaim(link) {
                this.loading = true

                let url = process.env.VUE_APP_API_URL + link

                await axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                })

                this.loading = false

                await this.getAll()
            },
            prepareFilter(arr) {
                for(let k in arr) {
                    for(let i in arr[k]) {
                        let localeKey = arr[k][i].locale_key ?? false

                        if(this[localeKey] ?? false) {
                            if(localeKey) arr[k][i].text = this[localeKey][arr[k][i].text] ?? arr[k][i].text
                        }
                    }
                }

                return arr
            }
        }
    }
</script>