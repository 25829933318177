<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeChangeEmailBtn"></button>
                </div>
                <div class="modal-body">
                    <Form class="p-3" @submit="onSubmit">
                        <Input
                            v-model="email"
                            name="email"
                            :label="$t('user.email')"
                            :placeholder="$t('user.email-placeholder')"
                            :validations="['required', 'minLength(3)', 'maxLength(100)']"
                            :tip="emailTip"
                            :tip-type="emailTipType"
                        />
                        <button type="submit" class="d-none" ref="submitBtn"></button>
                    </Form>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="submitForm">{{ $t('settings.profile.save') }}</button>
                    <button class="btn btn-outline-secondary ms-3" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Form } from 'vee-validate'
import Input from '../form/Input.vue'
import { isEmail } from '../../../libs/user'

export default {
    name: 'changeEmailModal',
    data: () => ({
        id: 'changeEmailModal',
        email: '',
        emailTip: '',
        emailTipType: 'regular'
    }),
    props: {
        currentEmail: {
            type: String,
            default: ''
        }
    },
    components: {
        Form,
        Input
    },
    watch: {
        email(val) {
            if(this.email && val && val === this.currentEmail) {
                this.emailTip = 'Выберите новый эл. адрес'
                this.emailTipType = 'danger'
            } else if(!isEmail(val)) {
                this.emailTip = 'Введите правильный эл. адрес'
                this.emailTipType = 'danger'
            } else {
                this.emailTip = ''
                this.emailTipType = 'regular'
            }
        }
    },
    methods: {
        submitForm() {
            this.$refs.submitBtn.click()
        },
        async onSubmit() {
            if(this.email === this.currentEmail) return
            if(!isEmail(this.email)) return

            let url = process.env.VUE_APP_API_URL + '/users/change-email'

            let body = {
                email: this.email
            }

            await axios.post(url, body, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    if(this.$store.state.user.settings.email == '') {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.update-user-success'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('settings.profile.notify.change-email-success'),
                            type: 'success'
                        })
                    }
    
                    this.$emit('changed')
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('settings.profile.notify.change-email-error') + ' ' + r.message,
                        type: 'error'
                    })
                }
            })
        },
        clear() {
            this.email = ''
            this.emailTip = ''
            this.emailTipType = 'regular'
        },
        close() {
            this.$refs.closeChangeEmailBtn.click()
        }
    }
}

</script>

<style scoped>

.modal-footer, .modal-header {
    border: none;
}

</style>