<template>
    <div id="content" class="col">
        <div class="shadow-sm p-3 mb-5 rounded" id="info">
            <div class="row align-items-center my-3">
                <div class="col-auto">
                    <p>{{ $t('rates.current-balance') }} <b>{{ currentBalance.toLocaleString('kk-KZ') }} ₸</b></p>
                </div>
                <div class="col-auto">
                    <button
                        class="btn btn-outline-primary me-2"
                        :class="{ disabled:loading.balance }"
                        @click="getCurrentBalance"
                    >
                        <i class="bi bi-arrow-clockwise mx-2" v-show="!loading.balance"></i>
                        <span class="loading" v-show="loading.balance"></span>
                    </button>
                    <PayButton btn-text="Пополнить через карту" />
                    <RatesBillModal v-if="this.bin!=''"/>
                    <PromocodeModal :btn-text="$t('settings.promocodes.btn-text')" @submitted="refreshBalance" />
                </div>
            </div>
            <p>{{ $t('rates.current-plan') }} <b>{{ currentPlan }}</b></p>
            <div class="alert alert-secondary mt-4 d-flex">
                <i class="bi bi-info-circle text-start mx-3"></i>
                <div>
                    <p>{{ $t('rates.request.info-alert') }}</p>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 justify-content-center mb-3 text-center">
            <div class="col card-group" v-for="item in preparedItems">
                <div class="card mb-4 rounded-3 shadow-sm" :class="{'border':item.active}">
                    <div class="card-header py-3 btn-secondary">
                        <h4 class="my-0 fw-normal">{{ item.name }}</h4>
                    </div>
                    <div class="card-body d-flex flex-column">
                        <ul class="list-unstyled mt-auto mb-4">
                            <li v-html="item.desc"></li>
                        </ul>
                        <button type="button" class="w-100 btn btn-lg btn-primary disabled mt-auto" v-if="item.active">{{ $t('rates.btn-activated') }}</button>
                        <RatesEnterpriseModal :btn-text="$t('rates.enterprise.button')" v-else-if="item.id==2" />
                        <button type="button" class="w-100 btn btn-lg btn-primary disabled mt-auto" v-else-if="item.disabled">{{ $t('rates.btn-disabled') }}</button>
                        <button type="button" class="w-100 btn btn-lg btn-primary mt-auto" v-else @click="changePlan(item.id)">{{ $t('rates.btn-active') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn .loading::after {
    font-size: 1em;
}

.visa {
    width: 150px;
}
</style>
<style>
    #info {
        background-color: #f3f3f3;
    }
    #info p {
       margin: 0;
    }
</style>

<script>
import PayButton from '../PayButton.vue'
import PromocodeModal from './PromocodeModal.vue'
import RatesBillModal from '../RatesBillModal.vue';
import RatesEnterpriseModal from '../RatesEnterpriseModal.vue'

export default {
    name: 'Rates',
    components: { PayButton, PromocodeModal, RatesBillModal, RatesEnterpriseModal },
    data: () => ({
        loading: {
            balance: false,
            rates: false
        },
        items: [],
        currentBalance: 0,
        currentPlan: '',
        bin: "",
    }),
    computed: {
        standardTitle() {
            return this.$t('rates.standard.title')
        },
        standardDesc() {
            return this.$t('rates.standard.desc')
        },
        enterpriseTitle() {
            return this.$t('rates.enterprise.title')
        },
        enterpriseDesc() {
            return this.$t('rates.enterprise.desc')
        },
        preparedItems() {
            let arr = this.items

            for(let i in arr) {
                let name = arr[i].name.toLowerCase()

                if(name == 'standard') {
                    arr[i].name = this.standardTitle
                    arr[i].desc = this.standardDesc
                }
                
                if(name == 'enterprise') {
                    arr[i].name = this.enterpriseTitle
                    arr[i].desc = this.enterpriseDesc
                }
            }

            return arr
        }
    },
    mounted() {
        this.getUserBin()
        this.getRates()
        this.getCurrentPlan()
        this.getCurrentBalance()
        this.$emit('setPageTitle', this.$t('rates.title'))

        let notifyMsg = this.$route.query.notify_msg ?? ''

        if(notifyMsg == 'replenish-account-success') {
            this.$notify({
                title: this.$t('notify.types.success'),
                text: this.$t('rates.notify-msg.replenish-account-success'),
                type: 'success'
            })
        } else if(notifyMsg == 'replenish-account-error') {
            this.$notify({
                title: this.$t('notify.types.error'),
                text: this.$t('rates.notify-msg.replenish-account-error'),
                type: 'error'
            })
        }
    },
    methods: {
        langChange() {
            this.$emit('setPageTitle', this.$t('rates.title'))
        },
        getRates() {
            let url = process.env.VUE_APP_API_URL + '/rates';
            axios.get(url, {
                withCredentials: true,
            })
            .then((response) => {
                this.items = response.data.data;
                for (let i in this.items) {
                    if (this.items[i].price === 0) {
                        this.items[i].price = this.$t('rates.price-free');
                    }
                }
                this.getCurrentPlan();
            });
        },
        getSubPrice(item) {
            if (item.subscription == 0 && item.price != this.$t('rates.price-free')) {
                return this.$t('rates.one-pay');
            }
            else if (item.subscription == 1) {
                return this.$t('rates.sub');
            }
            else {
                return '';
            }
        },
        refreshBalance() {
            this.$emit('updateBalance')
            this.getCurrentBalance()
        },
        getCurrentBalance() {
            if(this.loading.balance) return
            this.loading.balance = true

            let url = process.env.VUE_APP_API_URL + '/accounts/my'
            axios.get(url, {
                withCredentials: true,
            })
            .then((response) => {
                let r = response.data;
                if(r.result === 'SUCCESS') {
                    let account = r.data;
                    this.currentBalance = account.balance !== null ? account.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0
                }

                this.loading.balance = false
            })
        },
        getCurrentPlan() {
            for (let i in this.items) {
                if (this.items[i].active) {
                    this.currentPlan = this.items[i].name
                }
            }
        },
        changePlan(id) {
            if(this.loading.rates) return
            this.loading.rates = true

            let url = process.env.VUE_APP_API_URL + '/rates/set-current'
            axios.post(url, { rateId: id }, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data
                this.getRates()

                this.loading.rates = false
            })
        },
        getUserBin() {
            try{
                this.bin = this.$store.state.user.settings.bin
            }
            catch(e) {
                this.bin = ''
            }
        }
    }
}  
</script>