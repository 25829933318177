<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="my-3">
            <DataList
                ref="DataList"
                :columns="columns"
                :filter="filter"
                :data="list"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by="sortBy"
                :sort-type="sortType"
                :total="totalItems"
                @button-action="handleButtonAction"
                @row-clicked="handleRowClicked"
                @updated="update"
                @search="handleSearch"
                @filtered="handleFiltered"
            />
        </div>
    </div>
</template>

<script>
    import DataList from '../cabinet/DataList.vue'

    export default {
        name: 'Inbox',
        data: () => ({
            list: [],
            loading: false,
            currentPage: 1,
            perPage: 5,
            sortBy: 'created',
            sortType: 'desc',
            totalItems: 0,
            search: '',
            searchFields: ['title', 'amount_owed'],
            filter: {},
            filtered: {},
            columnRecipientLocale: '-'
        }),
        computed: {
            columns: {
                get() {
                    return [
                        { value: "recipient", text: this.columnRecipientLocale },
                        { value: "status", text: this.$t('payment-schedules.columns.status') },
                        { value: "months", text: this.$t('payment-schedules.columns.months') },
                        { value: "amount", text: this.$t('payment-schedules.columns.amount'), sortable: true },
                        { value: "payment_date", text: this.$t('payment-schedules.columns.payment-date'), sortable: true }
                    ]
                }
            },
            noPaymentDate: {
                get() {
                    return this.$t('payment-schedules.no-payment-date')
                }
            },
            statuses: {
                get() {
                    return {
                        need_pay: this.$t('payment-schedules.statuses.need-pay'),
                        paid: this.$t('payment-schedules.statuses.paid')
                    }
                }
            },
            localeFilter: {
                get() {
                    return {
                        'im-sender': this.$t('payment-schedules.filter.im-sender'),
                        'im-recipient': this.$t('payment-schedules.filter.im-recipient'),
                        'status-all': this.$t('payment-schedules.filter.status-all'),
                        'status-need-pay': this.$t('payment-schedules.filter.status-need-pay'),
                        'status-paid': this.$t('payment-schedules.filter.status-paid')
                    }
                }
            }
        },
        components: {
            DataList
        },
        watch: {
            loading: {
                handler(val) {
                    this.$refs.DataList.loading = val
                }
            }
        },
        created() {
            this.perPage = this.$libs.pagination.itemsPerPage
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('payment-schedules.title'))

            this.$emit('updateBalance')

            this.columnRecipientLocale = this.$t('payment-schedules.columns.sender')

            // set default filtered
            this.$refs.DataList.filtered.im = 'sender'
            this.$refs.DataList.filtered.status = 'all'
            this.getAll()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('payment-schedules.title'))
            },
            update(options) {
                let { page = 0, rowsPerPage = 0, sortBy = 'created', sortType = 'desc' } = options

                if(page) this.currentPage = page
                if(rowsPerPage) this.perPage = rowsPerPage
                if(sortBy) this.sortBy = sortBy
                if(sortType) this.sortType = sortType

                this.getAll()
            },
            handleSearch(val) {
                this.search = val

                this.getAll()
            },
            async getAll() {
                if(this.loading) return
                this.loading = true

                let search = {}
                if(this.search) {
                    for(let i in this.searchFields) {
                        search[this.searchFields[i]] = this.search
                    }
                }

                let filtered = {}

                for(let i in this.filtered) {
                    if(this.filtered[i] !== 'all') filtered[i] = this.filtered[i]
                }

                const r = await this.$api.getPaymentSchedules({
                    page: this.currentPage,
                    limit: this.perPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType,
                    search: search,
                    filtered: filtered
                })

                this.setLocaleForRecipient()

                if(r.result === 'SUCCESS') {
                    this.list = r.data
                    this.totalItems = r.total

                    this.filter = r.filter ?? {}

                    if(Object.keys(this.filter).length) {
                        this.filter = this.prepareFilter(this.filter)
                    }

                    for(let i in this.list) {
                        this.list[i].class = 'row-regular'
                        if(!this.list[i].payment_date) this.list[i].payment_date = this.noPaymentDate
                        this.list[i].status = this.statuses[this.list[i].status]
                    }

                    console.log('payment-schedules', r)
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('inbox.notify.error'),
                        type: 'error'
                    })
                }

                this.loading = false
            },
            getIm() {
                return this.filtered['im'] || 'sender'
            },
            setLocaleForRecipient() {
                let im = this.getIm()

                if(im == 'sender') {
                    this.columnRecipientLocale = this.$t('payment-schedules.columns.recipient')
                } else {
                    this.columnRecipientLocale = this.$t('payment-schedules.columns.sender')
                }
            },
            handleFiltered(filtered) {
                this.filtered = filtered

                this.getAll()
            },
            handleRowClicked(item) {
                if(this.loading) return

                if(item._link ?? false) {
                    this.$router.push(item._link)
                }
            },
            handleButtonAction(action, link = false) {
                if(process.env.VUE_APP_DEBUG) console.log('buttonAction', { action, link })
            },
            prepareFilter(arr) {
                for(let k in arr) {
                    for(let i in arr[k]) {
                        let locale = arr[k][i]['locale'] ?? ''

                        if(locale && (this.localeFilter[locale] ?? false)) {
                            arr[k][i].text = this.localeFilter[locale]
                        }
                    }
                }

                return arr
            }
        }
    }
</script>