<template>
    <iframe
        :src="src"
        width="1920px"
        height="1080px"
        allow="autoplay; fullscreen"
        allowFullScreen
        id="myiframe"
    />
</template>

<script>

    export default {
        props: {
            src: {
                type: String,
                default: ''
            }
        }
    }

</script>

<style scoped>

    #myiframe{
        border: none;
        object-fit: scale-down;
    }

</style>