<template>
    <div class="modal fade" ref="confSendModal" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" @click="handleClick">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="p-5 mb-2">
                        <p>Вам доступен платный способ отправки, на счету достаточно средств. Если сейчас разместить претензию бесплатно, то никаких уведомлений по ней отправлено не будет. Чтобы были отправлены уведомления, вы доложны выбрать один из платных вариантов размещения.</p>
                        <p>
                            <b>Вы уверены что хотите отправить претензию бесплатным способом?</b>
                        </p>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary mb-2" @click="confirm">Да, отправить бесплатно</button>
                        <button class="btn btn-danger mb-2" @click="close">Нет, изменить способ отправки</button>
                    </div>
                    <button type="button" class="d-none" ref="closeModal" data-bs-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        id: 'ConfirmFreeSendModal'
    }),
    methods: {
        confirm() {
            this.$emit('confirmed')
            this.close()
        },
        close() {
            this.$refs.closeModal.click()
            this.stopLoader()
        },
        stopLoader() {
            this.$emit('stopLoader')
        },
        handleClick(e) {
            if(e.target === e.currentTarget) {
                this.stopLoader()
            }
        }
    }
}
</script>

<style scoped>
.modal-footer, .modal-header {
    border: none;
}
</style>