<script>
export default {
    created() {
        let partner = this.$route.params.p ?? ''
        if(partner) {
            document.cookie = 'psig=' + partner + '; path=/; max-age=846000'
            this.$router.push('/')
        }   
    }
}
</script>