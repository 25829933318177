const statuses = {
    draft: -1,
    unread: 0,
    read: 1,
    expired: 2,
    paid: 3
}

module.exports = {
    statuses
}