<template>
    <div id="content" class="col">
        <Form class="p-3 mb-5 rounded mx-2" @submit="onSubmit">
            <div class="row my-3">
                <div class="col">
                    <Input
                        v-model="lastName"
                        name="lastName"
                        maska="A"
                        maska-tokens="A:[а-яА-Я]:multiple"
                        :label="$t('recipients.form.lastName')"
                        :placeholder="$t('recipients.form.lastName-placeholder')"
                        :validations="['required', 'minLength(3)', 'maxLength(50)']"
                    />
                    <Input
                        v-model="firstName"
                        name="firstName"
                        maska="A"
                        maska-tokens="A:[а-яА-Я]:multiple"
                        :label="$t('recipients.form.firstName')"
                        :placeholder="$t('recipients.form.firstName-placeholder')"
                        :validations="['required', 'minLength(3)', 'maxLength(50)']"
                    />
                    <Input
                        v-model="patronymic"
                        name="patronymic"
                        maska="A"
                        maska-tokens="A:[а-яА-Я]:multiple"
                        :label="$t('recipients.form.patronymic')"
                        :placeholder="$t('recipients.form.patronymic-placeholder')"
                        :validations="['required', 'minLength(3)', 'maxLength(50)']"
                    />
                    <Input
                        v-model="id_no"
                        name="id_no"
                        maska="############"
                        :label="$t('recipients.form.id_no')"
                        :placeholder="$t('recipients.form.id_no-placeholder')"
                        :validations="['required', 'minLength(12)', 'maxLength(12)']"
                        :disabled="true"
                    />
                </div>
                <div class="vr px-0 mx-2 opacity-50"></div>
                <div class="col">
                    <Input
                        v-model="email"
                        name="email"
                        maska="A"
                        maska-tokens="A:[a-zA-Z0-9@_.-]:multiple"
                        :label="$t('recipients.form.email')"
                        :placeholder="$t('recipients.form.email-placeholder')"
                        :validations="['required', 'email']"
                    />
                    <Input
                        v-model="phone"
                        name="phone"
                        maska="+7 (###) ###-##-##"
                        :label="$t('recipients.form.phone')"
                        :placeholder="$t('recipients.form.phone-placeholder')"
                        :validations="['minLength(12)', 'maxLength(20)']"
                    />
                    <Input
                        v-model="address"
                        name="address"
                        :label="$t('recipients.form.address')"
                        :placeholder="$t('recipients.form.address-placeholder')"
                        :validations="['required', 'minLength(3)', 'maxLength(100)']"
                    />
                </div>
            </div>
            <button type="submit" class="btn btn-primary">{{ $t('recipient.btn-update') }}</button>
            <button type="button" class="btn btn-danger" @click="showDeleteModal">{{ $t('recipient.btn-delete') }}</button>
        </Form>
        <ConfirmDeleteModal :body="modalBody" @deleteObj="deleteRecipient()" />
    </div>
</template>

<script>
    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'
    import ConfirmDeleteModal from './ConfirmDeleteModal.vue'

    export default {
        data: () => ({
            id: 0,
            firstName: '',
            lastName: '',
            patronymic: '',
            id_no: '',
            email: '',
            phone: '',
            address: '',
            modalBody: 'recipient'
        }),
        components: {
            Form,
            Input,
            ConfirmDeleteModal
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('recipient.title'))

            this.id = this.$route.params.id
            this.getRecipient()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('recipient.title'))
            },
            onSubmit() {
                this.update()
            },
            update() {
                let recipient = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    patronymic: this.patronymic,
                    email: this.email,
                    phone: this.phone,
                    address: this.address,
                    creator: this.$store.state.user.id
                }
                let url = process.env.VUE_APP_API_URL + '/recipients/' + this.id

                axios.post(url, recipient).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.$router.push('/recipients')

                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipient.notify.success'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            getRecipient() {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + this.id

                axios.get(url).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        let recipient = r.data

                        this.firstName = recipient.firstName
                        this.lastName = recipient.lastName
                        this.patronymic = recipient.patronymic
                        this.id_no = recipient.id_no
                        this.email = recipient.email
                        this.phone = recipient.phone
                        this.address = recipient.address
                        
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipient.notify.success'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error'),
                            type: 'error'
                        })
                    }
                })
            },
            deleteRecipient() {
                let url = process.env.VUE_APP_API_URL + '/recipients/' + this.id + '/delete'

                axios.get(url, {
                    params: { currentUserId: this.$store.state.user.id },
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$router.push('/recipients')
                        
                        this.$notify({
                            title: this.$t('notify.types.success'),
                            text: this.$t('recipient.notify.success-deleted'),
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipient.notify.error-deleted'),
                            type: 'error'
                        })
                    }
                })
            },
            clear() {
                this.firstName = ''
                this.lastName = ''
                this.patronymic = ''
                this.id_no = ''
                this.email = ''
                this.phone = ''
                this.address = ''
            },
            showDeleteModal() {
                this.$store.commit('modalShow', { id: 'confirmDeleteModal' })
            }
        }
    }

</script>