<template>
    <div id="content" class="col">
        <div class="row">
            <div class="col-md-6">
                <h3>{{ importName }}</h3>
                <div class="progress my-3 rounded-3" role="progressbar" aria-label="20px high" aria-valuemin="0" aria-valuemax="100" style="height: 20px">
                    <div class="progress-bar text-bg-primary" :style="{ width: percent }"></div>
                </div>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>{{ $t('claims.import.saved-import.total-claims') }}</td>
                            <td>{{ total }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('claims.import.saved-import.signed') }}</td>
                            <td>{{ signed }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('claims.import.saved-import.not-signed') }}</td>
                            <td>{{ total - signed }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="card" v-if="total>0&&total==signed">
                    <div class="card-body d-flex justify-content-between align-items-center">
                        <span>{{ $t('claims.import.saved-import.all-signed') }}</span>
                        <i class="bi bi-check-circle text-success fs-4"></i>
                    </div>
                </div>
                <button type="button" class="btn btn-primary my-3" :class="{ loading: loading }" :disabled="loading" v-if="!this.import.user_key&&signed===0" @click="onSubmit">{{ $t('claims.import.saved-import.sign-and-send') }}</button>
                <button type="button" class="btn btn-primary my-3" :class="{ loading: loading }" :disabled="loading" v-if="this.import.user_key&&signed===0" @click="onSubmit">{{ $t('claims.import.saved-import.send') }}</button>
                <button type="button" class="btn btn-primary my-3" :class="{ loading: loading }" :disabled="loading" v-if="!this.import.user_key&&signed>0&&signed<total" @click="onSubmit">{{ $t('claims.import.saved-import.sign-and-continue') }}</button>
                <button type="button" class="btn btn-primary my-3" :class="{ loading: loading }" :disabled="loading" v-if="this.import.user_key&&signed>0&&signed<total" @click="onSubmit">{{ $t('claims.import.saved-import.continue') }}</button>
                <button type="button" class="btn btn-danger ms-md-3 my-3" :class="{ loading: loading }" :disabled="loading||signed>0" @click="showDeleteModal">{{ $t('claims.import.saved-import.delete')}}</button>
            </div>
            <div class="col-md-6">
                <div class="card" v-if="!enoughBalance">
                    <div class="card-body d-flex justify-content-between align-items-center">
                        <div class="col-3 d-flex justify-content-between align-items-center">
                            <i class="bi bi-exclamation-triangle text-warning fs-1"></i>
                        </div>
                        <div class="col-9">
                            <p>У Вас недостаточно средств на отправку всех претензий.</p>
                            <p>Пожалуйста, пополните счёт для непрерывного подписания.</p>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="inProcess">
                    <div class="card-body d-flex justify-content-between align-items-center">
                        <div class="col-3 d-flex justify-content-between align-items-center">
                            <i class="bi bi-exclamation-triangle text-danger fs-1"></i>
                        </div>
                        <div class="col-9">
                            <p>{{ $t('claims.import.saved-import.dont-leave-page') }}</p>
                            <p>{{ $t('claims.import.saved-import.leave-warning') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmSendModal ref="importSignModal" @confirmed="confirmed" />
    <ConfirmDeleteModal :header="deleteModalHeader" :body="deleteModalBody" ref="deleteImportModal" @deleteObj="deleteImport" />
</template>

<script>
import axios from 'axios'
import ConfirmSendModal from './ConfirmSendModal.vue'
import ConfirmDeleteModal from '../recipients/ConfirmDeleteModal.vue'

export default {
    data: () => ({
        importId: '',
        importName: '',
        import: {},
        claims: [],
        total: 0,
        signed: 0,
        next: '',
        loading: false,
        inProcess: false,
        currentRate: '',
        claimCost: null,
        balance: 0,
        enoughBalance: true
    }),
    computed: {
        percent() {
            return (this.signed / (this.total / 100)) + '%'
        },
        deleteModalHeader() {
            return `<h3>${ this.$t('claims.import.saved-import.delete-modal-heading') }</h3>`
        },
        deleteModalBody() {
            return `<p>${ this.$t('claims.import.saved-import.delete-modal-body') }</p>`
        }
    },
    components: {
        ConfirmSendModal,
        ConfirmDeleteModal
    },
    mounted() {
        this.getCurrentRate()
        this.importId = this.$route.query.id || 0
        this.getImportClaims()
    },
    watch: {
        signed: {
            handler(val) {
                if(this.total !== 0 && val < this.total && this.loading) this.inProcess = true
                if(this.total !== 0 && val === this.total) this.inProcess = false
                if(this.total === 0) this.inProcess = false
            }
        }
    },
    methods: {
        async getImportClaims() {
            let url = process.env.VUE_APP_API_URL + '/claims/import-claims'

            await this.getBalance()

            await axios.post(url, {
                importId: this.importId
            },{
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.importName = r.import.title
                    this.import = r.import
                    this.claims = r.claims
                    this.total = r.claims.length

                    if(r.totalCost > this.balance) this.enoughBalance = false

                    let signedClaims = r.claims.filter(claim => (claim.status >= 0 && claim.sign_id !== null))
                    this.signed = signedClaims.length
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })

                    this.$router.push('/claims/import')
                }
            })
        },
        onSubmit() {
            if(!this.import.user_key && !this.import.user_password && this.signed < this.total) {
                this.$store.commit('modalShow', { id: 'confirmSendModal' })
            } else if(this.import.user_key && this.import.user_password && this.signed < this.total) {
                this.signClaim()
            }
        },
        async confirmed(data) {
            this.loading = true
            await this.saveSignature(data)
        },
        async saveSignature(data) {
            let body = {
                user_id: this.$store.state.user.id,
                import: JSON.stringify(this.import)
            }

            let url = process.env.VUE_APP_API_URL + '/claims/save-signature'

            let formData = new FormData()

            for(let i in body) {
                formData.append(i, body[i])
            }

            for(let i in data) {
                if(i != 'sign') {
                    formData.append(i, data[i])
                } else {
                    formData.append('file', data[i], data[i].name)
                }
            }

            await axios.post(url, formData, {
                withCredentials: true
            }).then(response => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.signClaim()
                } else {
                    alert('signature error')
                    this.loading = false
                }
            })
        },
        async signClaim() {
            if(this.total === this.signed) return

            let res,
                msg

            let unsigned = this.claims.filter(claim => claim.sign_id === null)
            console.log(unsigned)
            if(unsigned.length === 0) return

            this.loading = true
            this.inProcess = true
            // return

            let url = process.env.VUE_APP_API_URL + '/claims/sign-import'

            let body = {
                claim_slug: unsigned[0].slug,
                import_id: this.import.id
            }

            await axios.post(url, body, {
                withCredentials: true
            }).then(async (response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    res = await r.result
                } else {
                    msg = r.message
                }
                this.$emit('updateBalance')
                await this.getImportClaims()
            })

            this.loading = false

            if(res === 'SUCCESS') {
                await this.signClaim()
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: msg || this.$t('claims.import.saved-import.sign-error'),
                    type: 'error'
                })
            }
        },
        showDeleteModal() {
            this.$store.commit('modalShow', { id: 'confirmDeleteModal'})
        },
        async deleteImport() {
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/import/delete-import'

            await axios.post(url, {
                id: this.importId
            }, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.$notify({
                        type: 'success',
                        title: this.$t('notify.types.success'),
                        text: r.message
                    })

                    this.loading = false

                    this.$router.push('/claims/import')
                } else {
                    this.$notify({
                        type: 'error',
                        title: this.$t('notify.types.error'),
                        text: r.message
                    })

                    this.loading = false
                }
            })
        },
        async getCurrentRate() {
            let url = process.env.VUE_APP_API_URL + '/rates/get-current'

            await axios.get(url, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    let rate = r.data.rate
                    this.currentRate = rate

                    this.claimCost = rate.claim_cost ?? 0
                }
            })
        },
        async getBalance() {
            let url = process.env.VUE_APP_API_URL + '/accounts/my'

            await axios.get(url, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.balance = Number(r.data.balance) || 0
                }
            })
        }
    }
}

</script>