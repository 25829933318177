<template>
    <h3>{{ $t('claims.import.templates.header') }}</h3>
    <ul class="list-group my-3">
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <button class="btn btn-link" @click="downloadTemplate('osi_debt')" :class="{ 'd-none': loading }">{{ $t(`claims.type-modal.header.osi_debt`) }}</button>
            <span v-if="loading" class="loading"></span>
        </li>
    </ul>
</template>

<script>

export default {
    data:() => ({
        loading: false
    }),
    methods: {
        async downloadTemplate(template) {
            this.loading = true
            let url = process.env.VUE_APP_API_URL + '/debt-notice/download-template'

            await axios.get(url, {
                params: {
                    template: template,
                },
                withCredentials: true,
                responseType: 'blob'
            }).then((response) => {
                if(response.data) this.loading = false
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')

                link.href = url

                let filename = template + '.xlsx'

                link.setAttribute('download', filename) // Set the filename here

                document.body.appendChild(link)

                link.click()
                link.parentNode.removeChild(link)
            })
        }
    }
}

</script>

<style scoped>

.btn-link {
    display: flex;
    align-items: center;
    padding: .5rem .5rem;
    color: #8f82d8;
    background: none !important;
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    outline: none !important;
}

.btn-link:hover {
    color: #5a4ac2;
    outline: none !important;
}
.btn-link:focus {
    /* outline: 2px solid #a6a0fb !important; */
    color: #5a4ac2;
}

.btn-link:active {
    color: #5a4ac2 !important;
    background: none !important;
    transform: translate(2px, 2px);
    outline: none !important;
}

</style>