<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-body">
                    <h5 class="text-center mb-3">{{ $t('claim.remove-modal.title') }}</h5>
                    <p class="text-center">{{ $t('claim.remove-modal.text') }}</p>
                    <div class="text-center">
                        <button class="btn btn-danger mt-3" data-bs-dismiss="modal" @click="remove()">{{ $t('claim.btn-remove') }}</button>
                        <button class="btn btn-secondary ms-3" data-bs-dismiss="modal">{{ $t('claims.add.confirm-modal.cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'confirmRemoveModal'
    }),
    methods: {
        remove() {
            this.$emit('removeClaim')
        }
    }
}

</script>