<template>
    <Form class="p-3 mb-3 rounded mx-2" @submit="onSubmit">
        <div class="email-form" v-if="type==='email'">
            <Input
                v-model="email"
                name="email"
                maska="A"
                maska-tokens="A:[a-zA-Z0-9@_.-]:multiple"
                ref="contactEmail"
                :label="$t('recipients.form.email')"
                :placeholder="$t('recipients.form.email-placeholder')"
                :validations="['required', 'email']"
            />
        </div>
        <div class="phone-form" v-if="type==='phone'">
            <Input
                v-model="phone"
                name="phone"
                maska="+7 (###) ###-##-##"
                ref="contactPhone"
                :label="$t('recipients.form.phone')"
                :placeholder="$t('recipients.form.phone-placeholder')"
                :validations="isFound ? ['maxLength(20)'] : ['required', 'minLength(12)', 'maxLength(20)']"
            />
        </div>
        <div class="address-form" v-if="type==='address'">
            <Location parent-element="#addRecipientContactsModal" @changed="addressOnChange" />
        </div>
        <button type="submit" class="btn btn-primary mt-3">
            <i class="bi bi-plus-lg"></i> Добавить
        </button>
    </Form>
</template>

<script>

    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'
    import Location from '../cabinet/Location.vue'

    export default {
        name: 'FormContacts',
        data: () => ({
            email: '',
            phone: '',
            address: {},
            showEmailInput: false,
            showPhoneInput: false,
            showAddressInput: false,
            type: ''
        }),
        props: {
            id: {
                type: Number,
                default: null
            }
        },
        components: {
            Form,
            Input,
            Location
        },
        methods: {
            addressOnChange(address) {
                this.address = address
            },
            async onSubmit() {
                if(!this.id) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Не удалось получить ID получателя',
                        type: 'error'
                    })

                    return
                }

                switch(this.type) {
                    case 'email':
                        this.addNewEmail()
                        break
                    case 'phone':
                        this.addNewPhone()
                        break
                    case 'address':
                        this.addNewAddress()
                        break
                }
            },
            async addNewEmail() {
                if(!this.email) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Необходимо заполнить поле Email',
                        type: 'error'
                    })

                    this.$refs.contactEmail.focus()

                    return
                }

                let r = await this.addNewContacts(this.id, 'email', this.email)

                if(r) {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('recipients.form.notify.contact-add-email-success'),
                        type: 'success'
                    })

                    this.$emit('recipientContactsAdded', {
                        type: this.type,
                        data: this.email,
                        recipientId: this.id
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.notify.contact-add-email-error'),
                        type: 'error'
                    })
                }
            },
            async addNewPhone() {
                if(!this.phone) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Необходимо заполнить поле Телефон',
                        type: 'error'
                    })

                    this.$refs.contactPhone.focus()

                    return
                }

                let r = await this.addNewContacts(this.id, 'phone', this.phone)

                if(r) {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('recipients.form.notify.contact-add-phone-success'),
                        type: 'success'
                    })

                    this.$emit('recipientContactsAdded', {
                        type: this.type,
                        data: this.phone,
                        recipientId: this.id
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.notify.contact-add-phone-error'),
                        type: 'error'
                    })
                }
            },
            async addNewAddress() {
                if(!this.address.completed) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.need-fill-address'),
                        type: 'error'
                    })

                    return
                }

                let r = await this.addNewContacts(this.id, 'address', this.address)

                if(r) {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: this.$t('recipients.form.notify.contact-add-address-success'),
                        type: 'success'
                    })

                    this.$emit('recipientContactsAdded', {
                        type: this.type,
                        data: this.address,
                        recipientId: this.id
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('recipients.form.notify.contact-add-address-error'),
                        type: 'error'
                    })
                }
            },
            async addNewContacts(recipientId, key, value) {
                let data = {
                    userId: this.$store.state.user.id,
                    recipientId: recipientId,
                    key: key,
                    value: value
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/contact/add'

                let result = await axios.post(url, data, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            },
            setType(type) {
                this.clear()
                
                this.type = type
            },
            clear() {
                // this.id = ''
                this.email = ''
                this.phone = ''
                this.address = ''

                this.showEmailInput = false
                this.showPhoneInput = false
                this.showAddressInput = false
            }
        }
    }

</script>

<style scoped>

    .vr {
        background-color: #8f82d8;
    }

</style>
